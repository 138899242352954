<template>

  
    <div
      id="invoice1"
      style=" min-height: 600px;"
    >
    <vodal
               class="add_address_order"
                 :show="show2"
                 animation="flip"
                 @hide="show2=false"
                
               >
               <section style="text-align:center">
                <div class="success_image" style="
        margin-top:1%;
         margin-left: auto;
         margin-right: auto;
      
           ">
           <h3 style="    font-size: 18px;
    font-weight: 700;
    color: var(--primary-color);">{{ $t('Your order has been received successfully') }}</h3>
            </div>

        <div class="success_image" style="
         margin-left: auto;
         margin-right: auto;
         width:300px ;
           ">
            <img src="/theme8/images/confirm_order.jpeg"/>
            </div>

            


     </section>
               </vodal>
      <div v-if="$store.state.langchange">{{ updatedata() }}</div>
      <div id="invoice" class="card" v-if="!loading">
        <div class="card-body">
          <div class="container mb-5 mt-3">
            <div class="row">
              <div class="col-lg-9 col-6">
                <p class="title" style="color: #7e8d9f; font-size: 20px">
                  {{ $t("invoice") }} >
                  <strong>#{{ DBitems.invoice_id }}</strong>
                </p>
              </div>
              <div class="col-lg-3 col-6">
                <div class="d-flex flex-row justify-content-end">
                  <button
                    class="btn btn-light text-capitalize border-0"
                    data-mdb-ripple-color="dark"
                    @click="printInvoice"
                  >
                    <i class="bi bi-printer text-primary"></i>
                    {{ $t("print") }}
                  </button>
                 
                </div>
              </div>
              
            </div>
            <hr />
            <div class="container">
              <div class="col-md-12">
                <div class="text-center">
                  <p
                    style="display:inline ; color:var(--primary-color);padding-top:0"
                  >{{ DBitems.market_name }}</p>
                  <img class="printLogo" style="display:inline ; max-height:75px" :src="this.$store.state.logo" />
                </div>
              </div>
  
              <div class="row">
                <div class="col-xl-8 col-md-6">
                  <ul class="list-unstyled" style="margin-top:40px">
                    <li class="text-muted">
                      {{ $t("to") }}:
                      <span style="color: #5d9fc5">{{ DBitems.user.name }}</span>
                    </li>
                    <li class="text-muted">
                      <i class="bi bi-geo-alt"></i>
                      {{ DBitems.address.city }},
                      {{ DBitems.address.region }}, {{ DBitems.address.street }}
                    </li>
                    <li class="text-muted">
                      <i class="bi bi-phone"></i>
                      {{ DBitems.user.phone }}
                    </li>
                    <li class="text-muted">
                      <i class="bi bi-envelope"></i>
                      {{ DBitems.user.email }}
                    </li>
                  </ul>
                </div>
                <div class="col-xl-4 col-md-6">
                  <p class="text-muted">{{ $t("invoice") }}</p>
                  <ul class="list-unstyled">
                    <li class="text-muted">
                      <i class="fas fa-circle" style="color: #84b0ca"></i>
                      <span class="fw-bold">{{ $t("id") }} :</span>
                      #{{
                      DBitems.invoice_id 
                      }}
                    </li>
                    <li class="text-muted">
                      <i class="fas fa-circle" style="color: #84b0ca"></i>
                      <span class="fw-bold">{{ $t("creationDate") }} :</span>
                      {{ DBitems.date }}
                    </li>
                    <li class="text-muted" v-if="DBitems.delivered_date != null">
                      <i class="fas fa-circle" style="color: #84b0ca"></i>
                      <span class="fw-bold">{{ $t("endDate") }} :</span>
                      {{ DBitems.delivered_date }}
                    </li>
                    <li class="text-muted" v-else>
                      <i class="fas fa-circle" style="color: #84b0ca"></i>
                      <span class="fw-bold">{{ $t("requiredDate") }} :</span>
                      {{ DBitems.required_date }}
                    </li>
                    <li class="text-muted">
                      <i class="fas fa-circle" style="color: #84b0ca"></i>
                      <span class="me-1 fw-bold">{{ $t("status") }} :</span>
                      <span class="badge bg-warning text-black fw-bold">{{ DBitems.status }}</span>
                    </li>
                  </ul>
                </div>
              </div>
  
              <div class="row my-2 mx-1 justify-content-center pb-2">
                <div class="table-wrapper-scroll-y my-custom-scrollbar">
                  <table class="table table-striped table-borderless">
                    <thead style="background-color: #84b0ca; color:#fff" >
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">{{ $t("images") }}</th>
                        <th scope="col">{{ $t("product") }}</th>
                        <th scope="col">{{ $t("options") }}</th>
                        <th scope="col">{{ $t("extras") }}</th>
                        <th scope="col">{{ $t("notes") }}</th>
                        <th scope="col">{{ $t("unitPrice") }}</th>
                        <th scope="col">{{ $t("amount") }}</th>
                        <th scope="col">{{ $t("totalPrice") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in DBitems.order_items.items" :key="index">
                        <th scope="row">{{ index + 1 }}</th>
                        <td>
                          <div style="width:50px; height: 50px;margin-top: 10px;">
                            <img
                              :src="item.image"
                              style="width:100%; height: 100% ; object-fit: cover; border-radius: 15px; box-shadow: 0 0 2px 2px #c7bbbb;"
                            />
                          </div>
                        </td>
                        <td>{{ item.product }}</td>
                        <td>
                          <p
                            v-for="(item1, i) in item.options"
                            :key="i"
                          >{{ item1.option }} : {{ item1.value }}</p>
                        </td>
                        <td>
                          <p v-for="j in item.order_details" :key="j">{{ j }}</p>
                        </td>
                        <td>{{ item.note }}</td>
                        <td v-if=" $i18n.locale == 'ar'">{{ item.price*$store.state.rate }}
                           {{$store.state.UnitPrice}}</td>
                        <td v-else>{{ item.price*$store.state.rate }} {{$store.state.UnitPrice}}</td>
                        <td>{{ item.quantity }}</td>
                        <td
                          v-if=" $i18n.locale == 'ar'"
                        >{{ item.total_price*$store.state.rate }} {{$store.state.UnitPrice}}</td>
                        <td v-else>{{ item.total_price*$store.state.rate }} {{$store.state.UnitPrice}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8">
                  <ul class="list-unstyled">
                    <li class="text-muted ms-3">
                      <span class="text-black me-4">{{ $t("paymentType") }} :</span>
                      {{ DBitems.payment_type }}
                    </li>
                    <li v-if="DBitems.bank_account" class="text-muted ms-3 mt-2">
                      <span class="text-black me-4">{{$t('account_name')}}: :</span>
                      {{ DBitems.bank_account.account_name }}
                    </li>
  
                    <li v-if="DBitems.bank_account" class="text-muted ms-3 mt-2">
                      <span class="text-black me-4">{{ $t("account_number") }} :</span>
                      {{ DBitems.bank_account.account_number }}
                    </li>
                    <li v-if="DBitems.bank_account" class="text-muted ms-3 mt-2">
                      <span class="text-black me-4">{{ $t("ibn") }} :</span>
                      {{ DBitems.bank_account.iban }}
                    </li>
                    <li v-if="DBitems.bank_account" class="text-muted ms-3 mt-2">
                      <span class="text-black me-4">{{ $t("account_address") }} :</span>
                      {{ DBitems.bank_account.address }}
                    </li>
  
                    
  
                   
  
                    <li class="text-muted ms-3 mt-2">
                      <span class="text-black me-4">{{ $t("paymentStatus") }} :</span>
                      {{ DBitems.payment }}
                    </li>
  
                    <li class="text-muted ms-3 mt-2">
                      <span class="text-black me-4">{{ $t("paymentStatus") }} :</span>
                      {{ DBitems.payment }}
                    </li>
  
                    <li v-if="DBitems.count_month!=null" class="text-muted ms-3 mt-2">
                      <span class="text-black me-4">{{ $t("count_month") }} :</span>
                      {{ DBitems.count_month }}   {{ $t('months') }}
                    </li>
                    <li v-if="DBitems.note!=null" class="text-muted ms-3 mt-2">
                      <span class="text-black me-4">{{ $t("notes") }} :</span>
                      {{ DBitems.note }}
                    </li>
                  </ul>
                </div>
                <div class="col-xl-4">
                  <ul class="list-unstyled">
                    <li v-if=" $i18n.locale == 'ar'" class="text-muted ms-3">
                      <span class="text-black me-4">{{ $t("subTotal") }} :</span>
                      {{ DBitems.total_price*$store.state.rate }} {{$store.state.UnitPrice}}
                    </li>
                    <li v-else class="text-muted ms-3">
                      <span class="text-black me-4">{{ $t("subTotal") }} :</span>
                      {{ DBitems.total_price*$store.state.rate }} {{$store.state.UnitPrice}}
                    </li>
  
                    <li v-if=" $i18n.locale == 'ar'" class="text-muted ms-3 mt-2">
                      <span class="text-black me-4">{{ $t("discount") }} ({{ DBitems.discount }}%) :</span>
                      {{ (DBitems.discount_price*$store.state.rate).toFixed(2) }} {{$store.state.UnitPrice}}
                    </li>
                    <li v-else class="text-muted ms-3 mt-2">
                      <span class="text-black me-4">{{ $t("discount") }} ({{ DBitems.discount }}%) :</span>
                      {{ (DBitems.discount_price*$store.state.rate).toFixed(2) }} {{$store.state.UnitPrice}}
                    </li>
  
                    <li v-if=" $i18n.locale == 'ar'" class="text-muted ms-3 mt-2">
                      <span class="text-black me-4">{{ $t("deliveryPrice") }} :</span>
                      {{ (DBitems.delivery_cost*$store.state.rate) }} {{$store.state.UnitPrice}}
                    </li>
                    <li v-else class="text-muted ms-3 mt-2">
                      <span class="text-black me-4">{{ $t("deliveryPrice") }} :</span>
                      {{ DBitems.delivery_cost*$store.state.rate }} {{$store.state.UnitPrice}}
                    </li>
                  
                    <li class="text-muted ms-3 mt-2" v-if=" DBitems.tax  !=null &&  $i18n.locale == 'ar'">
                      <span class="text-black me-4">{{ $t("tax") }} ( {{ DBitems.tax }}% ):</span>
                      {{ (DBitems.tax_price*$store.state.rate).toFixed(2)}} {{$store.state.UnitPrice}}
                    </li>
                    <li class="text-muted ms-3 mt-2" v-if=" DBitems.tax  !=null &&  $i18n.locale == 'en'">
                      <span class="text-black me-4">{{ $t("tax") }} ( {{ DBitems.tax }}% ):</span>
                      {{ (DBitems.tax_price*$store.state.rate).toFixed(2)}} {{$store.state.unit}}
                    </li>
                    
                  </ul>
                  <p class="text-black text-center">
                    <span class="text-black me-3">{{ $t("totalPrice") }} :</span>
                    <span v-if=" $i18n.locale == 'ar'" style="font-size: 25px">
                      {{
                      DBitems.final_price*$store.state.rate
                      }} {{$store.state.UnitPrice}}
                    </span>
                    <span v-else style="font-size: 25px">
                      {{
                      DBitems.final_price*$store.state.rate
                      }} {{$store.state.UnitPrice}}
                    </span>
                  </p>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-lg-9 col-md-7">
                  <p>{{ $t("invoiceThanxs") }}</p>
                </div>
                <div class="col-lg-1 col-md-2 col-4">
                  <button
                    type="button"
                    v-if="DBitems.link !=null"
                    class="btn btn-primary text-capitalize border-0"
                    style="background-color: #23c077"
                    @click="Payment()"
                  >{{ $t("Payment") }}</button>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <Transition name="modal">
        <div v-if="show" class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container">
              <div class="modal-header">
                <slot name="header">{{$t('confirmCancel')}}</slot>
              </div>
              <div class="modal-footer">
                <slot name="footer">
                  <button class="modal-default-button" @click="cancelOrder">{{$t('confirm')}}</button>
                  <button
                    class="modal-default-button"
                    @click="(show = false), $emit('close')"
                  >{{$t('cancel')}}</button>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </Transition> -->
    </div>
  </template>
  
  
<script>
import Vodal from "vodal";

import "vodal/common.css";
import "vodal/slide-down.css";
import "vodal/flip.css";
export default {
  components: {
    Vodal,
  },
  data: function() {
    return {
      DBitems: null,
      loading: false,
      show: false,
      social: null,
      unit:'QAR',
      Message:"",
      show2:true,
    };
  },
  computed: {
    mail: function () {
      return feather.toSvg('mail')
    },
    phone: function () {
      return feather.toSvg('phone')
    },
    map_pin: function () {
      return feather.toSvg('map-pin')
    },
  },
  created() {
    this.$store.state.topbar='topbar_light';
    this.$store.state.navbar='navbar_dark ';
    this.loading = true;
    this.unit=this.$store.state.unit;
    if(this.$i18n.locale == 'ar')
    {
        this.unit=this.$store.state.unitAR;
    }
    else
      {
        this.unit=this.$store.state.unit;
    }

    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/contact_us",
        {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        }
      )
      .then(response => {
        this.social = response.data.data.social;
       
    
      })


    this.axios
      .get(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/guest/order/" +
          this.$route.params.id,
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        if (response.data.code == "1") {
          this.DBitems = response.data.data.order;
        } else {
          console.log("errorrr");
        }
      })
      .then(() => (this.loading = false))
      .catch(error => {
        if (error.response.status == "401") {
         // this.$localStorage.remove(this.$store.storeName);
          //window.location.href = "/";
        }
      });
      if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
  },
  methods: {
    updatedata() {
      this.unit=this.$store.state.unit;
    if(this.$i18n.locale == 'ar')
    {
        this.unit=this.$store.state.unitAR;
    }
    else
      {
        this.unit=this.$store.state.unit;
    }
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/guest/order/" +
            this.$route.params.id,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.code == "1") {
            this.DBitems = response.data.data.order;
          } else {
            console.log("errorrr");
          }
        })
        .then(() => (this.loading = false))
        .catch(error => {
          if (error.response.status == "401") {
            // this.$localStorage.remove(this.$store.storeName);
            // window.location.href="/";
          }
        });

      //this.$refs.childref.update(this.categoryID)
      this.$store.state.langchange = false;
    },

    
    printInvoice() {
      let head = `
      <head>
<link href="https://etrolley.net/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
    <link href="https://etrolley.net/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
    <link href="https://etrolley.net/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
    <link href="https://etrolley.net/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
    <link href="https://etrolley.net/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
    <link href="https://etrolley.net/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
    <link href="https://etrolley.net/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">
    <link href="https://etrolley.net/assets/css/style.css" rel="stylesheet">
    <title>${this.$store.storeName}</title>
    <style>
    .btn {
    display: none !important;
  }
  .col-md-6 {
    width: 50% !important;
  } 
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
}
.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
    
    padding-right:20px
    padding-left: 20px
   
}
  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
   
    }
    .list-unstyled {
    padding-left: 0;
    list-style: none;
}
/*.table-wrapper-scroll-y{
    display: block;
}
.my-custom-scrollbar{
    position: relative;
    max-height: 700px;
    overflow-x: auto;
    overflow-y: auto;
}*/
.table {
    --bs-table-color: var(--bs-body-color);
    --bs-table-bg: transparent;
    --bs-table-border-color: var(--bs-border-color);
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: var(--bs-body-color);
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: var(--bs-body-color);
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: var(--bs-body-color);
    --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    width: 100%;
    margin-bottom: 1rem;
    color: var(--bs-table-color);
    vertical-align: top;
    border-color: var(--bs-table-border-color);
    caption-side: bottom;
    border-collapse: collapse;
}
.table > thead {
    vertical-align: bottom;
}
thead, tbody, tfoot, tr, td, th {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}
thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}
.table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
  .text-center {
    text-align: center !important;
}
.printLogo{
position: absolute;
    top: 0;
    left: 10px;
}
  </style>
  </head>
      `

      let body=``
      let invoice = document.getElementById("invoice").outerHTML
      if (this.$i18n.locale == "ar") {
        body=`<body dir='rtl'>
        ${ invoice }</body></html>`;
      } else {
         body=`<body dir='rtl'>
        ${ invoice }</body></html>`;
      }



      var mywindow = window.open("", "PRINT");

      mywindow.document.write("<html>");

      mywindow.document.
      //getElementsByTagName('head').
      write(head) ;
      mywindow.document.write(body);
      
      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();
      mywindow.close();
    },

    Payment() {
      window.location.href = this.DBitems.link;
    }
  }
};
</script>

  <style scoped>
  #invoice1 {
    margin-top: 130px;
    margin-bottom: 50px;
    width: 84%;
    margin-left: 8%;
    margin-right: 8%;
  }
  .my-custom-scrollbar {
    position: relative;
    max-height: 700px;
    overflow-x: auto;
    overflow-y: auto;
  }
  .table-wrapper-scroll-y {
    display: block;
  }
  
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }
  
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  
  .modal-container {
    width: 300px;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
  }
  
  .modal-header {
    margin-top: 0;
    color: var(--secondary-color) !important;
    font-family: "Araboto-Medium";
    margin-bottom: 20px;
  }
  
  .modal-body {
    margin: 20px 0;
    font-family: "Araboto-Medium";
  }
  
  .modal-default-button {
    float: right;
    font-family: "Araboto-Medium";
    margin-left: 5px;
    margin-right: 5px;
    font-size: 15px;
    border: 2px solid var(--primary-color);
    color: var(--secondary-color);
    border-radius: 5px;
  }
  .modal-default-button:hover {
    background-color: white;
  }
  
  /*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */
  
  .modal-enter-from {
    opacity: 0;
  }
  
  .modal-leave-to {
    opacity: 0;
  }
  
  .modal-enter-from .modal-container,
  .modal-leave-to .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  @media (max-width: 500px) {
    .title {
      font-size: 15px !important;
    }
    .btn {
      font-size: 8px !important;
      padding: 5px;
    }
  }
  
  @media print {
    .btn {
      display: none !important;
    }
    .col-md-6 {
      width: 50% !important;
    }
  }
  </style>
  
  
  