<template>
     <div 
     class="card product_card">
     
                        <img :src=image
                        :alt="name"
                        loading="lazy"
                        class="one_product_image"
                        @click="
                   $router.push({
                    name: 'product',
                     params: {
                       storeName: $store.storeName ,
                       lang:$store.state.routeLang,
                       id: productid ,
                       name: name,
                       }
                   });"
                        >
                        <div v-if="qnt==0" class="sold_out">{{ $t('sold out') }}</div>
                            <div v-if="note" class="note_product">{{ note }}</div>

                        
                        <div class="top-right2" >
                            <a  @click="changeFav" >
                                <i v-if="this.fav" id="faPlus" class=" fa fa-heart font-xs love" style="color:red"></i>
                                <i v-else id="faPlus" class=" fa fa-heart-o font-xs love" ></i>

                              </a>
                               </div>
                               <div class="top-left product_card_msg" >
                              <a  v-if="this.login" 
                              href="javascript:void(0)"
                                 class="login_product_msg">{{ $t('loginMessage1') }} {{ $t('loginMessage2') }}
                                </a>

                                <a  v-if="this.validateMessage&&this.validateMessage!=''" 
                              href="javascript:void(0)"
                                 class="login_product_msg" id="add_cart_msg" 
                                 style="background-color:green !important"
                                 >{{ validateMessage }} 
                                </a>
                                <a  v-if="this.msg_fav"
                                 href="javascript:void(0)"
                                 class="login_product_msg">
                                 {{ this.msg_fav}}
                                </a>
                        </div>
                        <div class="product_meta_content">
                        <h6 class="title_card" 
                        style="cursor:pointer"
                        @click="
                    $router.push({
                    name: 'product',
                     params: {
                       storeName: $store.storeName ,
                       lang:$store.state.routeLang,
                       id: productid ,
                       name: name,
                       }
                   });">{{name}}</h6>
                        <div class="d-flex justify-content-between desc_card">
                          <p v-if="is_option">{{$t('PriceOnRequest')}}</p>
                                <p v-else-if="(new_price>0)&&(!is_option)">
                                  <span class='price_product'>
                                    {{ new_price.toFixed($store.state.digit_price) }}</span> <span class="one_product_unit">{{ $store.state.UnitPrice }}</span>
                                    <del class="text-slate-400"><span>{{ (old_price*$store.state.rate).toFixed($store.state.digit_price) }} </span> {{ $store.state.UnitPrice }}</del></p>
                                
                                <p v-else-if="!is_option"><span class='price_product'>{{ (old_price*$store.state.rate).toFixed($store.state.digit_price) }} </span><span class="one_product_unit">{{ $store.state.UnitPrice }}</span></p>
                           
                            <button v-if="(!is_option)&&qnt!=0&&loading_add==false" 
                            @click="addToCart" class="add_cart_btn ">
                              <i  class="fa fa-plus"></i>

                            </button>

                            <button  v-if="loading_add==true" class="add_cart_btn " 
                            style="border:none !important;background-color:transparent !important;">
                              <img class="loader_product" src="/pet_shop/assets/images/ajax-loader.gif"/>

                            </button>
                       
                        </div>
                        </div>
                    </div>
    </template>
    <script> 
    export default {
      components: {},
      props: {
        id:Number,
        qnt: Number,
        min_qnt: Number,
        max_qnt: Number,
        name: String,
        description: String,
        is_favorit: Boolean,
        is_option: Number,
        image: String,
        old_price: Number,
        new_price: Number,
        productid: Number,
        count_view: Number,
        unit: String,
        rate: Number,
        is_offer: Boolean,
        discount: Number,
        new:Boolean,
        fetuered:Boolean,
        note:String,
        range_price:Object,
      },
      data: function () {
        return {
          login:false,
          fav: false,
          loginMassage: null,
          show: false,
          validateMessage: '',
          finalOptions:0,
          msg_fav:null,
          loading_add:false,
        };
      },
      created(){
      this.fav=this.is_favorit;  
      console.log('favourite',this.fav)
      },
      methods: {
        addToCart() {
console.log('%%%%%');
var checked_true = [];
this.loading_add=true;
this.axios
  .post(
    this.$store.state.base_api_url +
      this.$store.storeName +
      "/check_cart_item",
    {
      product_id: this.productid,
      total_price: this.old_price,
      offer_price: this.new_price,
      quantity: 1,
      old_qnt:0,
      final_option: 0,
      option_check: []
    },
    {
      headers: {
        "Accept-Language": this.$i18n.locale
      }
    }
  )
  .then(response => {
    let product = null;
    this.validateMessage = response.data.message;
    setTimeout(() => {
      // this.updateall();
      this.validateMessage = "";
    }, 3000);

    if (response.data.code == "1") {
      this.loading_add=false;
      // let products = new Map;
      let S_name1 =
        this.$store.storeName +
        "/" +
        this.productid +
        '0' +
        checked_true;
      if (this.$cookies.isKey(S_name1)) {
        /*console.log(
          "this.price " +
            this.price +
            "this.$cookies.get( S_name1 ).total_price" +
            this.$cookies.get(S_name1).total_price
        );*/
        product = {
          product_id: this.productid,
          total_price:
            this.price + this.$cookies.get(S_name1).total_price,
          offer_price:
            this.offer_price + this.$cookies.get(S_name1).offer_price,
          quantity: Number(1) + Number(this.$cookies.get(S_name1).quantity),
          quantityAll: this.$cookies.get(S_name1).quantityAll,
          max_quantity: this.$cookies.get(S_name1).max_quantity,
          min_quantity: this.$cookies.get(S_name1).min_quantity,
          final_option: 0,
          option_check: checked_true,
          discount: this.discount,//this.DBitems.product.discount,
          note:this.$cookies.get(S_name1).note
        };
      } else {
        this.loading_add=false;
        console.log("hlooooooooooo ::::::::; " + this.qnt);
        product = {
          product_id: this.productid,
          total_price: this.old_price,
          offer_price: this.new_price,
          quantity: Number(1),
          quantityAll: this.qnt,
          min_quantity: this.min_qnt,
          max_quantity: this.max_qnt,
          final_option: 0,
          option_check: checked_true,
          discount: this.discount,
          note: ''
        };
      }
      
      let S_name = this.$store.storeName + "/";

      if (
        !this.$cookies.isKey(
          S_name +
            product.product_id +
            product.final_option +
            product.option_check
        )
      ) {
        this.$store.state.cartCounter = this.$store.state.cartCounter + 1;
      }

      this.$cookies.set(
        S_name +
          product.product_id +
          product.final_option +
          product.option_check,
        product
      );
    }
    /*this.$router.push({
      name: "shoppingCart",
      params: {
        storeName: this.$store.storeName,
      },
    });*/

    this.$store.state.update_cart_list=true;

    setTimeout(() => {
      // this.updateall();
      this.validateMessage = "";
    }, 3000);
  });
},
        changeFav() {
          if(this.$localStorage.get(this.$store.storeName)==null)
           {
           this.login=true;
           }
           setTimeout(() => {
          // this.updateall();
          this.login = false;
        }, 3000);
          this.axios
            .get(
              this.$store.state.base_api_url +
                this.$store.storeName +
                "/favourite_product_change/" +
                this.productid,
              {
                headers: {
                  "Accept-Language": this.$i18n.locale,
                  Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
                },
              }
            )
            .then((response) => {
              if (response.data.code == "1") {
                this.fav = !this.fav;
                this.msg_fav=response.data.message
                setTimeout(() => {
          // this.updateall();
          this.msg_fav = null;
        }, 3000);
    
    
              }
              else
              {
                this.msg_fav=response.data.message
                setTimeout(() => {
          // this.updateall();
          this.msg_fav = null;
        }, 3000);
              }
            });
        },
        productRoute() {
          if (this.$route.path.includes("/product")) {
            this.$router.push({
              name: "product",
              params: { id: this.productid,lang:this.$store.state.routeLang, name: this.name },
            });
          } else {
            this.$router.push({
              name: "product",
              params: {
                storeName: this.$store.storeName,
                lang:this.$store.state.routeLang,
                id: this.productid,
                name: this.name,
               
              },
            });
          }
        },
      },
    };
    </script>
    