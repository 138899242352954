<template>

    <section  v-if="this.$localStorage.get(this.$store.storeName) != null">
      <div
        v-if="loading"
        class="d-flex flex-row justify-content-center"
        style="margin-top: 100px; margin-bottom: 200px"
      >
        <!-- LOADER -->
<div class="preloader">
    <div class="lds-ellipsis">
        <span></span>
        <span></span>
        <span></span>
    </div>
</div>
<!-- END LOADER -->

      </div>

           
        <!-- START SECTION BREADCRUMB -->
<div class="breadcrumb_section bg_gray page-title-mini">
    <div class="container"><!-- STRART CONTAINER -->
        <div class="row align-items-center">
        	<div class="col-md-6">
                <div class="page-title">
            		<h1>{{$t('Checkout')}}</h1>
                </div>
            </div>
            <div class="col-md-6">
                <ol class="breadcrumb justify-content-md-end">
                    <li class="breadcrumb-item"><router-link to="/">{{ $t('home') }}</router-link></li>
                    <li class="breadcrumb-item active">{{$t('Checkout')}}</li>
                </ol>
            </div>
        </div>
    </div><!-- END CONTAINER-->
</div>
<!-- END SECTION BREADCRUMB -->

<!-- START SECTION SHOP -->
<div class="section" v-if="items.length != 0">
	<div class="container">
        <div class="row">
            <div class="col-lg-6">
            	<div class="toggle_info">
            		<span><i class="fas fa-tag"></i>{{$t('Have a coupon?')}} 
                        <a href="#coupon" data-toggle="collapse"
                         class="collapsed"
                          aria-expanded="false">{{ $t('Click here to enter your code') }}</a></span>
                </div>
                <div class="panel-collapse collapse coupon_form" 
                id="coupon">
                    <div class="panel-body">
                    	<p>{{$t('If you have a coupon code, please apply it below.')}}</p>
                        <div class="coupon field_form input-group">
                            <input 
                            type="text"
                            class="form-control"
                            aria-label="Small"
                            aria-describedby="inputGroup-sizing-sm"
                            v-model="disCode"
                            :placeholder="$t('Couponcode')"
                            >
                          
                            <div class="input-group-append">
                                <button class="btn btn-fill-out btn-sm" 
                                type="submit" 
                                 @click="Couponcode()">{{$t('Applay coupon')}}</button>
                            </div>
                            
                        </div>
                        <span>
                  
                </span>
                        <span style="color:red">{{ couponMsg }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
            	<div class="medium_divider"></div>
            	<div class="divider center_icon"><i class="linearicons-credit-card"></i></div>
            	<div class="medium_divider"></div>
            </div>
        </div>
        <div class="row">
        	<div class="col-md-6">
            	<div class="heading_s1">
            		<h4>{{$t('Billing Details')}}</h4>
                </div>
                <form method="post">
                    <div class="form-group">
                        <input type="text" required class="form-control" name="name" :value="name" readonly>
                    </div>
                    <div class="form-group">
                        <input type="text" required class="form-control" name="email" :value="email" readonly>
                    </div>
                    <div class="form-group">
                        <input type="text" required class="form-control" style="direaction:ltr !important" name="phone" :value="phone" readonly>
                    </div>
                    
                     <!-- delivery method -->
                     <div class="payment_method">
                        <div class="heading_s1">
                            <h4>{{ $t("DeliveryMethod") }}</h4>
                        </div>
                        <div class="payment_option"  v-for="(item, index) in this.DBitems.delivery_type.types"
                         :key="index">
                            <div class="custome-radio">
                                <input class="form-check-input"  
                                type="radio"
                                 name="delivery_option" 
                                :id="'delivery'+item.id" 
                                :value="item.id" 
                                @click="selectDeliveryTypeId(item.id)"
                                >
                                <label class="form-check-label" :for="'delivery'+item.id">{{item.type}}</label>
                                <p data-method="option3" class="payment-text">{{ item.description }} </p>
                            </div>
                          
                        </div>
                    </div>
                    <!-- end delivery method -->
                    <!-- addresss -->
                    <div v-if="showAddress" class="mt-2">
                        <h4 v-if="addresses.length != 0" 
                        style="margin-bottom: 30px">{{ $t("Address") }}</h4>
                        <div class="form-group"  v-if="addresses.length != 0">
                        <div class="custom_select">
                <select
                  id="addressSelect"
                  class="form-control"
                  :title="$t('Address')"
                  v-if="addresses.length != 0"
                  @change="onChange($event)"
                >
                  <option hidden selected value="SelectOne">
                    {{ $t("SelectOne") }}
                  </option>
                  <option
                    v-for="(item, index) in addresses"
                    :key="index"
                    :value="item.id"
                  >
                    {{ item.title }}
                  </option>
                </select>
              </div>
            </div>
            <br>
             
                <div>
                  <button class="btn btn-fill-out btn-block"
                   @click="open_address">
                    {{ $t("AddNewAddress") }}
                  </button>
                  <br>
                  <vodal
                    :show="this.$store.state.addAddress"
                    animation="flip"
                    @hide="this.$store.state.addAddress = false"
                    :width="700"
                    :height="400"
                  >
                    <div style="height: 350px; overflow: scroll">
                      <add-address></add-address>
                    </div>
                  </vodal>
                </div>
             
             
              </div>
          
        
                    <!-- end address -->

              
                   
                  
                 
                    <div class="form-group mb-0">
                        <textarea rows="5" class="form-control" v-model="allOrderNote" :placeholder="$t('Order notes')"></textarea>
                    </div>
                </form>
            </div>
            <div class="col-md-6">
                <div class="order_review">
                    <div class="heading_s1">
                        <h4>{{$t('Your Orders')}}</h4>
                    </div>
                    <div class="table-responsive order_table">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>{{$t('product')}}</th>
                                    <th>{{$t('total')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <item_cart  v-for="item in this.items"
                                     :key="item"
                                     :itemId="item"
                                     > 
                                </item_cart>
                               
                               
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>{{$t('subTotal')}}</th>
                                    <td class="product-subtotal">
                                       {{ PartialPrice.toFixed(2) }} {{ unit }}</td>
                                </tr>

                                
                                <tr  v-if="this.discountPrice != 0">
                                    <th >{{ $t("discount") }} ({{ discountPrice }} %) :</th>
                                    <td class="product-subtotal"> 
                                        {{ ((this.discountPrice * this.PartialPrice) / 100).toFixed(2) }}

                                         {{ unit }}</td>
                                </tr>


                                <tr  v-if="this.selectDeliveryType == 5">
                                    <th>{{$t('Shipping')}}</th>
                                    <td> {{ deliveryPrice.toFixed(2) }} {{ unit }}</td>
                                </tr>

                                <tr v-if="$store.state.tax>0">
                                    <th >{{$t('tax')}} ( {{ $store.state.tax }}% ):</th>
                                    <td class="product-subtotal"> {{
                (
                  (this.$store.state.tax *
                    (this.PartialPrice -
                      (this.discountPrice * this.PartialPrice) / 100 +
                      this.deliveryPrice)) /
                  100
                ).toFixed(2)
              }} {{ unit }}</td>
                                </tr>
                                <tr>
                                    <th>{{$t('total')}}</th>
                                    <td class="product-subtotal"> {{ totalPrice.toFixed(2) }} {{ unit }}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div class="payment_method">
                        <div class="heading_s1">
                            <h4 style="color:var(--primary-color)">{{$t('Payment')}}</h4>
                        </div>
                        <div class="payment_option"   v-for="(item, index) in this.DBitems.payment_types.types"
                         :key="index">
                            <div class="custome-radio">
                                <input class="form-check-input"  type="radio" name="payment_option" :id="item.id" :value="item.id" 
                                @click="selectPaymentTypeId(item.id)"
                                >
                                <label class="form-check-label" :for="item.id">{{item.type}}</label>
                                <p data-method="option3" class="payment-text">{{ item.description }} </p>
                            </div>
                            <div v-if="showMonthes && item.id==4" class="mt-2">
            <h6 style="margin-bottom: 30px">{{ $t("select_count_month") }}</h6>

            <div class="form-group">
                        <div class="custom_select">
                            <select
              id="monthsSelect"
              class="rm-control"
              :title="$t('months')"
              v-if="DBitems.months.length != 0"
              @change="onChangeMonths($event)"
            >
              <option hidden selected value="SelectOne">
                {{ $t("SelectOne") }}
              </option>
              <option
                v-for="(item, index) in DBitems.months"
                :key="index"
                :value="item"
              >
                {{ item }} {{ $t("months") }}
              </option>
            </select>
                        </div>
                    </div>

            
          </div>
                        </div>
                    </div>

                   
                    <button v-if="!loadingMethods && this.DBitems != null"  
                    href="" class="btn btn-fill-out btn-block"
                    id="confirm_btn"
                     @click="confirmOrder()"
                     >{{$t('Place Order')}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- END SECTION SHOP -->

        </section>
    </template>


    <script>
import AddAddress from "../components/AddAddress.vue";
import item_cart from "../components/item_cart_order.vue";

import Vodal from "vodal";

import "vodal/common.css";
import "vodal/slide-down.css";
import "vodal/flip.css";

export default {
  components: {
    Vodal,
    AddAddress,
    item_cart
  },
  data: function () {
    return {loading:false,
        name:null,
        phone:null,
        email:null,
      items: [],
      products: [],
      products_cost: [],
      loadingMethods: false,
      addresses: [],
      addAddress: false,
      showAddress: false,
      showMonthes: false,
      DBitems: null,
      discount_delivery_free	:0,
      selectPaymentType: null,
      selectDeliveryType: null,
      selectAddress: null,
      count_month: null,
      disCode: null,
      discount_id: null,
      PartialPrice: 0,
      discountPrice: 0,
      deliveryPrice: 0,
      totalPrice: 0,
      couponMsg: "",
      unit: this.$store.state.unit,
      allOrderNote: "",
      langChange:""
    };
  },
  created() {
    if(this.$i18n.locale == 'ar')
    {
        this.unit=this.$store.state.unitAR;
    }
    else
    {
      this.unit=this.$store.state.unit;
    }
    //get profile

    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/profile",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.email = response.data.data.email;
        this.name = response.data.data.name;
        this.phone=response.data.data.phone;
        console.log('phone ' +this.phone);
      })
    //
    let AllCookies = this.$cookies.keys();
    this.items = [];
    this.products = [];
    this.products_cost=[];
    for (let i = 0; i < AllCookies.length; i++) {
      if (AllCookies[i].split("/")[0] == this.$store.storeName) {
        console.log('$$$$$$ ')

        this.items.push(AllCookies[i]);
        this.products.push(this.$cookies.get(AllCookies[i]));
        ////PartialPrice
        if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
          this.PartialPrice += this.$cookies.get(AllCookies[i]).offer_price;

          console.log('$$$$$$ offer price ',i,'  ',this.PartialPrice)
        } else {
          
          this.PartialPrice += this.$cookies.get(AllCookies[i]).total_price;
          console.log('$$$$$$  total_price ',i,' ',this.PartialPrice)
        }
        // console.log('products',pluck(this.products,"product_id"));
      }
    }

    for (let i = 0; i < this.products.length; i++) {
      this.products_cost.push(this.products[i]['product_id']);
    }
    console.log('product_cost: ',this.products_cost);

    ///////////addresses
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/addresses",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName),
          },
        }
      )
      .then((response) => {
        this.addresses = response.data.data.addresses.addresses;
        ////selectAddress
        /*if (this.addresses.length != 0) {
          this.selectAddress = this.addresses[0].id;
        }*/
        // console.log(this.addresses);
      })
      .then((this.loading = false))
      .catch((error) => {
        if (error.response.status == "401") {
          this.$localStorage.remove(this.$store.storeName);
          window.location.href = "/" ;
        }
      });
    ///////////methods
    this.loadingMethods = true;
    this.axios
      .post(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/order/create/v1",
          {products:this.products_cost},
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName),
          },
        }
      )
      .then((response) => {
        this.DBitems = response.data.data;
        // console.log(this.addresses);
      })
      .then((this.loadingMethods = false))
      .catch((error) => {
        if (error.response.status == "401") {
          this.$localStorage.remove(this.$store.storeName);
          window.location.href="/";
        }
      });
  },
  watch: {
    langChange(){
      this.loading=false;
      if(this.$i18n.locale == 'ar')
    {
        this.unit=this.$store.state.unitAR;
    }
    else
    {
      this.unit=this.$store.state.unit;
    }
    //get profile

    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/profile",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.email = response.data.data.email;
        this.name = response.data.data.name;
        this.phone=response.data.data.phone;
        console.log('phone ' +this.phone);
      })
    //
    let AllCookies = this.$cookies.keys();
    this.items = [];
    this.products = [];
    this.products_cost=[];
    this.PartialPrice=0;
    for (let i = 0; i < AllCookies.length; i++) {
      if (AllCookies[i].split("/")[0] == this.$store.storeName) {
        this.items.push(AllCookies[i]);
        this.products.push(this.$cookies.get(AllCookies[i]));
        ////PartialPrice
        if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
          this.PartialPrice += this.$cookies.get(AllCookies[i]).offer_price;
          console.log('fff')
        } else {
          this.PartialPrice += this.$cookies.get(AllCookies[i]).total_price;
          console.log('fff2')
        }
        // console.log('products',pluck(this.products,"product_id"));
      }
    }

    for (let i = 0; i < this.products.length; i++) {
      this.products_cost.push(this.products[i]['product_id']);
    }
    console.log('product_cost: ',this.products_cost);

    ///////////addresses
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/addresses",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName),
          },
        }
      )
      .then((response) => {
        this.addresses = response.data.data.addresses.addresses;
        ////selectAddress
        /*if (this.addresses.length != 0) {
          this.selectAddress = this.addresses[0].id;
        }*/
        // console.log(this.addresses);
      })
      .then((this.loading = false))
      .catch((error) => {
        if (error.response.status == "401") {
          this.$localStorage.remove(this.$store.storeName);
          window.location.href = "/" ;
        }
      });
    ///////////methods
    this.loadingMethods = true;
    this.axios
      .post(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/order/create/v1",
          {products: this.products_cost},
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName),
          },
        }
      )
      .then((response) => {
        this.DBitems = response.data.data;
        // console.log(this.addresses);
      })
      .then((this.loadingMethods = false))
      .catch((error) => {
        if (error.response.status == "401") {
          this.$localStorage.remove(this.$store.storeName);
          window.location.href="/";
        }
      });
    }
   ,
    PartialPrice() {
      //$store.state.tax
      //console.log("this.$store.state.tax "+ this.$store.state.tax + "  " +this.$store.state.tax+  this.PartialPrice)
      let x =
        this.PartialPrice -
        (this.discountPrice * this.PartialPrice) / 100 +
        this.deliveryPrice;
      this.totalPrice = x + (this.$store.state.tax * x) / 100;
      /*  let x= this.PartialPrice -( (this.discountPrice *  this.PartialPrice) / 100 );
            this.totalPrice = x + ( (this.$store.state.tax *  x) / 100 ) +this.deliveryPrice;*/
      /* this.totalPrice =
               this.PartialPrice -( (this.discountPrice *  this.PartialPrice) / 100 )+ this.deliveryPrice;
             console.log("PartialPrice Change" + this.totalPrice);*/
    },
    discountPrice() {
      let x =
        this.PartialPrice -
        (this.discountPrice * this.PartialPrice) / 100 +
        this.deliveryPrice;
      this.totalPrice = x + (this.$store.state.tax * x) / 100;
      /* this.totalPrice =
             this.PartialPrice -( (this.discountPrice *  this.PartialPrice) / 100 )+ this.deliveryPrice;
             console.log("discountPrice Change" + this.totalPrice);*/
    },
    deliveryPrice() {
      let x =
        this.PartialPrice -
        (this.discountPrice * this.PartialPrice) / 100 +
        this.deliveryPrice;
      this.totalPrice = x + (this.$store.state.tax * x) / 100;
      /*  this.totalPrice =
              this.PartialPrice -( (this.discountPrice *  this.PartialPrice) / 100 )+ this.deliveryPrice;
              console.log("deliveryPrice Change" + this.totalPrice);*/
    },
    selectDeliveryType() {
      if (this.selectDeliveryType == 5) {
        this.showAddress = true;
        //console.log("this.showAddress" + this.showAddress);
      } else {
        this.showAddress = false;
      }
    },
    selectPaymentType() {
      if (this.selectPaymentType == 4) {
        this.showMonthes = true;
      } else {
        this.showMonthes = false;
        this.count_month = null;
      }
      //console.log( this.count_month)
    },
    addAddress() {
      this.selectDeliveryTypeId(5);
    },

  
  },
  updated() {
    this.langChange=this.$i18n.locale
  },
  methods: {
    open_address(){
      this.$store.state.addAddress = true;},
    updatAddress() {
      ///////////addresses
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/addresses",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.addresses = response.data.data.addresses.addresses;
          /*if (this.addresses.length != 0) {
            this.selectAddress = this.addresses[0].id;
          }*/
          // console.log(this.addresses);
        })
        .then((this.loading = false))
        .catch((error) => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href =
              "/";
          }
        });
    },
    updatedata2() {
      console.log('jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj')
      this.langChange_check=false;
      this.langChange=false;
      ///////////methods
    this.loadingMethods = true;
    this.axios
      .post(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/order/create/v1",
          {products: this.products_cost},
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName),
          },
        }
      )
      .then((response) => {
        this.DBitems = response.data.data;
        // console.log(this.addresses);
      })
      .then((this.loadingMethods = false))
      .catch((error) => {
        if (error.response.status == "401") {
          this.$localStorage.remove(this.$store.storeName);
          window.location.href="/";
        }
      });

    },
    Couponcode() {
      this.loading = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/use_coupon",
          {
            code: this.disCode,
            total_price: this.PartialPrice,
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          if (response.data.code == "1") {
            this.couponMsg = "";
            this.discountPrice = response.data.data.discount;
            this.discount_id = response.data.data.discount_id;
            this.discount_delivery_free=response.data.data.isdelivery_free;
            if(this.discount_delivery_free==1)
            this.deliveryPrice=0;
            /////////for select
           // this.selectAddress = null;
            // this.deliveryPrice = 0;
            ////////////
          } else {
            this.couponMsg = response.data.message;
            /////////for select
           // this.selectAddress = null;
           // this.deliveryPrice = 0;
            ////////////
          }
          //this.addresses = response.data.data.addresses.addresses;
          // console.log(this.addresses);
        })
        .then((this.loading = false))
        .catch((error) => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href =
              "/" ;
          }
        });
    },
    selectPaymentTypeId(id) {
      this.loadingMethods = true;
      this.selectPaymentType = id;
      //console.log(this.selectPaymentType);

      for (let el = 0; el < this.DBitems.payment_types.types.length; el++) {
        this.DBitems.payment_types.types[el].isActive = false;
        if (this.DBitems.payment_types.types[el].id == id) {
          this.DBitems.payment_types.types[el].isActive = true;
        }
        //console.log(this.DBitems.payment_types.types[el].isActive);
      }

      //setTimeout(() => {
      this.loadingMethods = false;
      //  }, 3000);
    },
    selectDeliveryTypeId(id) {
      this.loadingMethods = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/cost_delivery",
          {
            address_id: this.selectAddress,
            delivery_id: id,
            cost:this.PartialPrice,
            products:this.products_cost,
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          if (response.data.code == "1") {
            if(this.discount_delivery_free==0){
              // console.log(this.discount_delivery_free);
            this.deliveryPrice = response.data.data.cost;
            }
            this.selectDeliveryType = id;
            //console.log(this.selectDeliveryType);
            for (
              let el = 0;
              el < this.DBitems.delivery_type.types.length;
              el++
            ) {
              this.DBitems.delivery_type.types[el].isActive = false;
              if (this.DBitems.delivery_type.types[el].id == id) {
                this.DBitems.delivery_type.types[el].isActive = true;
              }
              //console.log(this.DBitems.delivery_type.types[el].isActive);
            }
            // this.discountPrice = response.data.data.discount;
          } else {
            alert(response.data.message);
            this.selectAddress = null;

            const $select = document.querySelector("#addressSelect");
            $select.value = "SelectOne";
          }
          //this.addresses = response.data.data.addresses.addresses;
          // console.log(this.addresses);
        })
        .then(() => {
          this.loadingMethods = false;
        })
        .catch((error) => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href =
              "/";
          }
        });
    },
    onChange: function (event) {
      this.selectAddress = event.target.value;
      this.selectDeliveryTypeId(5);
    },
    onChangeMonths: function (event) {
      //console.log( event.target.value)
      this.count_month = event.target.value;
    },
    confirmOrder() {
      document.getElementById("confirm_btn").disabled = true;

      if (this.selectPaymentType == null) {

        if (this.$i18n.locale == "ar") {
          alert("يجب تحديد طريقة الدفع");
        } else {
          alert("Please select a payment type");
        }
      } 
      if (this.selectDeliveryType == null) {
        if (this.$i18n.locale == "ar") {
          alert("يجب تحديد طريقة التوصيل");
        } else {
          alert("Please select a delivery type");
        }
      } 
      if (this.selectDeliveryType != null&&this.selectPaymentType != null)
       {
        this.loading=true;
        this.axios
          .post(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/order/store/v1",
            {
              address_id: this.selectAddress,
              delivery_type_id: this.selectDeliveryType,
              payment_type_id: this.selectPaymentType,
              total_price: this.totalPrice,
              discount_id: this.discount_id,
              products: this.products,
              part_price: this.PartialPrice,
              note: this.allOrderNote,
              count_month: this.count_month,
              device:'web',
            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
                Authorization:
                  "Bearer " + this.$localStorage.get(this.$store.storeName),
              },
            }
          )
          .then((response) => {
            if (response.data.code == "1") {
              if(response.data.data.clear_cart==null||response.data.data.link_pay == null)
              {
              for (let i = 0; i < this.items.length; i++) {
                this.$cookies.remove(this.items[i]);
              }
              this.items = [];

              console.log("hiiiiiiii" + this.$cookies.keys());
              this.$store.state.cartCounter = 0;
            }

              //console.log("hiiiiiiii" + this.$cookies.keys());

              if (response.data.data.link_pay == null) {
                this.$store.state.cartCounter = 0;
              this.$router.push({
                name: "myOrders",
                params: {
                  storeName: this.$store.storeName,
                  lang: this.$i18n.locale
                },
              });
              }
             
              if (response.data.data.link_pay != null) {
                window.location.href = response.data.data.link_pay;
              }

              //this.deliveryPrice = response.data.data.cost;
              // this.discountPrice = response.data.data.discount;
            } else {
              alert(response.data.message);
              // this.couponMsg = response.data.message;
            }
            //this.addresses = response.data.data.addresses.addresses;
            // console.log(this.addresses);
          })
          .then((this.loading = false))
          .catch((error) => {
            if (error.response.status == "401") {
              this.$localStorage.remove(this.$store.storeName);
              window.location.href =
                "/" ;
            }
          });
      }
    },
  },
};

</script>