<template>
    <section style="min-height:600px;margin-top:10%" class="sections_page">
      <div v-if="this.loading" class="d-flex flex-row justify-content-center loading_card">
        <!-- LOADER -->
        <div class="loading_card_spinner">
          <hollow-dots-spinner :animation-duration="1000" :dot-size="35" :dots-num="3" color="var(--primary-color)" />
  
        </div>
        <!-- END LOADER -->
  
      </div>
      <div v-else-if="items.length != 0" class="container">
        <h3 style="text-align: center;margin-top: 5%;">{{ $t('Checkout') }}</h3>
        <div class="row confirm_order_method" style="margin-top: 5%;">
          <div class="col-lg-6 col-md-6 col-12 check_out_col-6">
            <div>
              <h4 id="delivery_title" style="color:var(--primary-color)">{{ $t('Receiving method') }}</h4>
              <div v-for="(item, index) in this.DBitems.delivery_type.types" :key="index">
                <input type="radio" name="delivery_option" class="delivery_option " :id="'delivery' + item.id"
                :value="item.id" @click="selectDeliveryTypeId(item.id)">
  
                <img :src="'/theme10/images/delivery' + item.id + '.png'" style="width:6%;margin: 10px;">
                <label :for="'delivery' + item.id" class="method" style="line-height: 3;min-width:150px">
                  {{ item.type }}</label>
          
              </div>
  
  
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12" id="payment_method">
            <div>
              <h4 id="payment_title" style="color:var(--primary-color)">{{ $t('Payment method') }}</h4>
              <div v-for="(item, index) in this.DBitems.payment_types.types" :key="index">
                <input @click="selectPaymentTypeId(item.id)" type="radio" id="" name="radio_payments" :value="item.id"
                  class="payment_method_check">
                <img :src="'/theme10/images/payment' + item.id + '.png'" style="width:8%;margin: 10px;">
                <label class="method method_pay" style="line-height: 3;min-width:90px">
                  {{ item.type }}</label>
                
                <br>
              </div>
  
  
  
            </div>
          </div>
        </div>
        <div v-if="showAddress" class="d-flex justify-content-between" style="margin-top: 4%;
           max-width:90%;">
          <h4 class="select_address">{{ $t('select address') }}</h4>
          <a class="add_new_address" style="
               color: var(--primary-color);
               text-decoration: none;
               cursor:pointer;" @click="open_address">{{ $t("AddNewAddress") }}</a>
  
        </div>
        <div v-if="showAddress" class="custom-select address_select">
          <p style="font-size: 18px;font-weight: 500;line-height: 3; 
              color: var(--primary-color);"></p>
          <select class="form-control address_input" id="addressSelect" :title="$t('Address')" v-if="addresses.length != 0"
            @change="onChange($event)">
            <option hidden selected value="SelectOne">
              {{ $t("SelectOne") }}
            </option>
            <option v-for="(item, index) in addresses" :key="index" :value="item.id">
              {{ item.title }} , {{ item.description }}
            </option>
          </select>
        </div>
        <div class="div_checkout" style="display:none">
          <div class="row">
            <div class="col-lg-8 col-8">
              <div class="custom-select" style="margin-left:5%;margin-right:5%">
                <p style="font-size: 18px;font-weight: 500;line-height: 3; 
              color: var(--primary-color);">{{ $t('select address') }}</p>
                <select class="select_add" id="addressSelect" :title="$t('Address')" v-if="addresses.length != 0"
                  @change="onChange($event)">
                  <option hidden selected value="SelectOne">
                    {{ $t("SelectOne") }}
                  </option>
                  <option v-for="(item, index) in addresses" :key="index" :value="item.id">
                    {{ item.title }} , {{ item.description }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-4 col-4">
              <div v-if="address">
                <img src="/theme10/images/pin.png" style="width: 3%; margin-left: 3%;">
                <span style="font-size: 18px;font-weight: 500;line-height: 3; 
              color: var(--primary-color); padding-top: 5%;"> {{ address.title }}</span>
                <h6 style="padding-left: 3%;">
                  {{ address.city }} ,
                  {{ address.region }},
                  {{ address.street }}</h6>
                <p style="padding-left: 3%;">{{ address.other_details }}</p>
              </div>
            </div>
  
          </div>
        </div>
  
        <div class="d-flex justify-content-between div2_checkout">
          <input type="text" class="code" v-model="disCode" :placeholder="$t('Type Discount code here')">
          <button @click="Couponcode()" class="butt_green apply ">{{ $t('apply') }}</button>
        </div>
        <span style="color:red;text-align: center">{{ couponMsg }}</span>
        <div style="margin-top: 4%; text-align: center;" class="price_table_confirm">
  
          <table class="confirm_table">
            <tr>
              <td class="title_confirm"> <span class="span_checkout"> {{ $t('subTotal') }} : </span></td>
              <td class="value_confirm"> <span class="span_checkout">{{ (PartialPrice * $store.state.rate).toFixed($store.state.price_digit) }} {{ $store.state.UnitPrice
                  }}</span>
              </td>
            </tr>
  
            <tr v-if="this.discountPrice != 0">
              <td class="title_confirm">
                <span class="span_checkout">{{ $t('discount') }} ({{ discountPrice }} %) </span>
              </td>
              <td class="value_confirm"><span class="span_checkout">{{ (PartialPrice * $store.state.rate).toFixed($store.state.price_digit) }}
                  {{ $store.state.UnitPrice }} </span></td>
            </tr>
  
            <tr v-if="this.selectDeliveryType == 5">
              <td class="title_confirm"><span class="span_checkout">{{ $t('Delivery Fee') }}: </span></td>
              <td class="value_confirm"><span class="span_checkout">{{ (deliveryPrice * $store.state.rate).toFixed($store.state.price_digit) }}
                  {{ $store.state.UnitPrice }} </span></td>
            </tr>
  
            <tr v-if="$store.state.tax > 0">
              <td class="title_confirm">
                <span class="span_checkout">{{ $t('tax') }}: ( {{ $store.state.tax }}% ) </span>
              </td>
              <td class="value_confirm">
                <span class="span_checkout" style=" color: var(--primary-color);"> {{
                  ($store.state.rate * (
                    (this.$store.state.tax *
                      (this.PartialPrice -
                        (this.discountPrice * this.PartialPrice) / 100 +
                        this.deliveryPrice)) /
                    100)
                  ).toFixed($store.state.price_digit)
                }} {{ $store.state.UnitPrice }}
                </span>
              </td>
            </tr>
            <tr>
              <td class="title_confirm"> <span class="span_checkout">{{ $t('total') }} </span></td>
              <td class="value_confirm"><span class="span_checkout" style=" color: var(--primary-color);">{{
                (totalPrice * $store.state.rate).toFixed($store.state.price_digit) }} {{ $store.state.UnitPrice }}</span></td>
            </tr>
          </table>
  
        </div>
        <button v-if="!loadingMethods && this.DBitems != null" class="butt_green cart_butt center_align" style="margin-top: 2%;"
          id="confirm_btn" @click="confirmOrder()"> {{ $t('Continue to checkout') }}</button>
  
          <div v-if="loading_checkout||loadingMethods" class="empty text-center center-text">
                   
                   <img src="/pet_shop/assets/images/ajax-loader.gif"/>
                    <br>
  
                    </div>
      </div>
      <br>
      <vodal class="add_address_order" :show="this.$store.state.addAddress && this.$store.state.addAddress" animation="flip"
        @hide="close_address">
        <div class="card_add_address">
          <add-address></add-address>
        </div>
      </vodal>
    </section>
  </template>
  
  
  <script>
  import AddAddress from "../components/AddAddress.vue";
  
  import Vodal from "vodal";
  
  import "vodal/common.css";
  import "vodal/slide-down.css";
  import "vodal/flip.css";
  import { HalfCircleSpinner, HollowDotsSpinner, CirclesToRhombusesSpinner } from "epic-spinners";
  
  
  export default {
    components: {
      Vodal,
      AddAddress,
      HalfCircleSpinner,
      HollowDotsSpinner,
      CirclesToRhombusesSpinner
    },
    data: function () {
      return {
        loading: false,
        name: null,
        phone: null,
        email: null,
        items: [],
        products: [],
        products_cost: [],
        loadingMethods: false,
        addresses: [],
        addAddress: false,
        showAddress: false,
        showMonthes: false,
        DBitems: null,
        discount_delivery_free: 0,
        selectPaymentType: null,
        selectDeliveryType: null,
        selectAddress: null,
        count_month: null,
        disCode: null,
        discount_id: null,
        PartialPrice: 0,
        discountPrice: 0,
        deliveryPrice: 0,
        totalPrice: 0,
        couponMsg: "",
        unit: this.$store.state.unit,
        allOrderNote: "",
        langChange: "",
        address: null,
        loading_checkout:false
      };
    },
    created() {
      if(this.$route.params.lang&&this.$route.params.lang!=this.$i18n.locale){
        this.$i18n.locale=this.$route.params.lang;this.$store.state.UnitPrice=this.$store.state.unitAR
        if(this.$i18n.locale=='en')
          {document.documentElement.setAttribute("dir", "ltr");
          let h = document.querySelector('body');
        }}
  
      this.loading = true;
      this.loadingMethods = true
      if (this.$i18n.locale == 'ar') {
        this.unit = this.$store.state.unitAR;
      }
      else {
        this.unit = this.$store.state.unit;
      }
      //get profile
  
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/profile",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.email = response.data.data.email;
          this.name = response.data.data.name;
          this.phone = response.data.data.phone;
          console.log('phone ' + this.phone);
        })
      //
      let AllCookies = this.$cookies.keys();
      this.items = [];
      this.products = [];
      this.products_cost = [];
      for (let i = 0; i < AllCookies.length; i++) {
        if (AllCookies[i].split("/")[0] == this.$store.storeName) {
          console.log('$$$$$$ ')
  
          this.items.push(AllCookies[i]);
          this.products.push(this.$cookies.get(AllCookies[i]));
          ////PartialPrice
          if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
            this.PartialPrice += this.$cookies.get(AllCookies[i]).offer_price;
  
            console.log('$$$$$$ offer price ', i, '  ', this.PartialPrice)
          } else {
  
            this.PartialPrice += this.$cookies.get(AllCookies[i]).total_price;
            console.log('$$$$$$  total_price ', i, ' ', this.PartialPrice)
          }
          // console.log('products',pluck(this.products,"product_id"));
        }
      }
      for (let i = 0; i < this.products.length; i++) {
        this.products_cost.push(this.products[i]['product_id']);
      }
      console.log('product_cost: ', this.products_cost);
  
      ///////////addresses
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/addresses",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.addresses = response.data.data.addresses.addresses;
          ////selectAddress
          /*if (this.addresses.length != 0) {
            this.selectAddress = this.addresses[0].id;
          }*/
          // console.log(this.addresses);
        })
        .then((this.loading = true))
        .catch((error) => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href = "/";
          }
        });
      ///////////methods
      this.loadingMethods = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/order/create/v1",{
            products:this.products_cost
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data;
          // console.log(this.addresses);
        })
        .then((this.loadingMethods = false))
        .catch((error) => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href = "/";
          }
        });
  
      setTimeout(() => {
        this.loading = false;
      }, 300);
    },
    watch: {
      langChange() {
        this.loading = true;
        if (this.$i18n.locale == 'ar') {
          this.unit = this.$store.state.unitAR;
        }
        else {
          this.unit = this.$store.state.unit;
        }
        //get profile
        //
        let AllCookies = this.$cookies.keys();
        this.items = [];
        this.products = [];
        this.products_cost = [];
        this.PartialPrice = 0;
        for (let i = 0; i < AllCookies.length; i++) {
          if (AllCookies[i].split("/")[0] == this.$store.storeName) {
            this.items.push(AllCookies[i]);
            this.products.push(this.$cookies.get(AllCookies[i]));
            ////PartialPrice
            if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
              this.PartialPrice += this.$cookies.get(AllCookies[i]).offer_price;
            } else {
              this.PartialPrice += this.$cookies.get(AllCookies[i]).total_price;
            }
            // console.log('products',pluck(this.products,"product_id"));
          }
        }
  
        for (let i = 0; i < this.products.length; i++) {
          this.products_cost.push(this.products[i]['product_id']);
        }
        console.log('product_cost: ', this.products_cost);
  
        ///////////addresses
        this.loading = true;
        this.axios
          .get(
            this.$store.state.base_api_url + this.$store.storeName + "/addresses",
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
                Authorization:
                  "Bearer " + this.$localStorage.get(this.$store.storeName),
              },
            }
          )
          .then((response) => {
            this.addresses = response.data.data.addresses.addresses;
  
          })
          .then((this.loading = false))
          .catch((error) => {
            if (error.response.status == "401") {
              this.$localStorage.remove(this.$store.storeName);
              window.location.href = "/";
            }
          });
        ///////////methods
        this.loadingMethods = true;
        this.axios
        .post(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/order/create/v1",{
            products:this.products_cost
          },
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
                Authorization:
                  "Bearer " + this.$localStorage.get(this.$store.storeName),
              },
            }
          )
          .then((response) => {
            this.DBitems = response.data.data;
            this.loading=false
            // console.log(this.addresses);
          })
          .then((this.loadingMethods = false))
          .catch((error) => {
            if (error.response.status == "401") {
              this.$localStorage.remove(this.$store.storeName);
              window.location.href = "/";
            }
          });
      }
      ,
      PartialPrice() {
  
        let x =
          this.PartialPrice -
          (this.discountPrice * this.PartialPrice) / 100 +
          this.deliveryPrice;
        this.totalPrice = x + (this.$store.state.tax * x) / 100;
  
      },
      discountPrice() {
        let x =
          this.PartialPrice -
          (this.discountPrice * this.PartialPrice) / 100 +
          this.deliveryPrice;
        this.totalPrice = x + (this.$store.state.tax * x) / 100;
  
      },
      deliveryPrice() {
        let x =
          this.PartialPrice -
          (this.discountPrice * this.PartialPrice) / 100 +
          this.deliveryPrice;
        this.totalPrice = x + (this.$store.state.tax * x) / 100;
        /*  this.totalPrice =
                this.PartialPrice -( (this.discountPrice *  this.PartialPrice) / 100 )+ this.deliveryPrice;
                console.log("deliveryPrice Change" + this.totalPrice);*/
      },
      selectDeliveryType() {
        if (this.selectDeliveryType == 5) {
          this.showAddress = true;
          //console.log("this.showAddress" + this.showAddress);
        } else {
          this.showAddress = false;
        }
      },
      selectPaymentType() {
        if (this.selectPaymentType == 4) {
          this.showMonthes = true;
        } else {
          this.showMonthes = false;
          this.count_month = null;
        }
        //console.log( this.count_month)
      },
      addAddress() {
        this.selectDeliveryTypeId(5);
      },
  
  
    },
    updated() {
      this.langChange = this.$i18n.locale
    },
    methods: {
      close_address() {
        this.$store.state.addAddress = false;
      },
      open_address() {
        this.$store.state.addAddress = true;
      },
      updatAddress() {
        document.getElementById('addressSelect').style="border-color: #c9c9c9 !important;"
  
        ///////////addresses
        this.loading = true;
        this.axios
          .get(
            this.$store.state.base_api_url + this.$store.storeName + "/addresses",
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
                Authorization:
                  "Bearer " + this.$localStorage.get(this.$store.storeName),
              },
            }
          )
          .then((response) => {
            this.addresses = response.data.data.addresses.addresses;
  
          })
          .then((this.loading = false))
          .catch((error) => {
            if (error.response.status == "401") {
              this.$localStorage.remove(this.$store.storeName);
              window.location.href =
                "/";
            }
          });
      },
      updatedata2() {
        console.log('jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj')
        document.getElementById('addressSelect').style="border-color: #c9c9c9 !important;"
  
        this.langChange_check = false;
        this.langChange = false;
        ///////////methods
        this.loadingMethods = true;
        this.axios
        .post(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/order/create/v1",{
            products:this.products_cost
          },
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
                Authorization:
                  "Bearer " + this.$localStorage.get(this.$store.storeName),
              },
            }
          )
          .then((response) => {
            this.DBitems = response.data.data;
            // console.log(this.addresses);
          })
          .then((this.loadingMethods = false))
          .catch((error) => {
            if (error.response.status == "401") {
              this.$localStorage.remove(this.$store.storeName);
              window.location.href = "/";
            }
          });
  
      },
      Couponcode() {
        this.loading = true;
        this.axios
          .post(
            this.$store.state.base_api_url +
            this.$store.storeName +
            "/use_coupon",
            {
              code: this.disCode,
              total_price: this.PartialPrice,
            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
                Authorization:
                  "Bearer " + this.$localStorage.get(this.$store.storeName),
              },
            }
          )
          .then((response) => {
            if (response.data.code == "1") {
              this.couponMsg = "";
              this.discountPrice = response.data.data.discount;
              this.discount_id = response.data.data.discount_id;
              this.discount_delivery_free = response.data.data.isdelivery_free;
              if (this.discount_delivery_free == 1)
                this.deliveryPrice = 0;
              /////////for select
              // this.selectAddress = null;
              // this.deliveryPrice = 0;
              ////////////
            } else {
              this.couponMsg = response.data.message;
              /////////for select
              // this.selectAddress = null;
              // this.deliveryPrice = 0;
              ////////////
            }
            //this.addresses = response.data.data.addresses.addresses;
            // console.log(this.addresses);
          })
          .then((this.loading = false))
          .catch((error) => {
            if (error.response.status == "401") {
              this.$localStorage.remove(this.$store.storeName);
              window.location.href =
                "/";
            }
          });
      },
      selectPaymentTypeId(id) {
        this.loadingMethods = true;
        this.selectPaymentType = id;
        //console.log(this.selectPaymentType);
  
        for (let el = 0; el < this.DBitems.payment_types.types.length; el++) {
          this.DBitems.payment_types.types[el].isActive = false;
          if (this.DBitems.payment_types.types[el].id == id) {
            this.DBitems.payment_types.types[el].isActive = true;
          }
          //console.log(this.DBitems.payment_types.types[el].isActive);
        }
  
        setTimeout(() => {
          this.loadingMethods = false;
        }, 3000);
      },
      selectDeliveryTypeId(id) {
        this.loadingMethods = true;
        this.axios
          .post(
            this.$store.state.base_api_url +
            this.$store.storeName +
            "/cost_delivery",
            {
              address_id: this.selectAddress,
              delivery_id: id,
              cost: this.PartialPrice,
              products: this.products_cost,
            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
                Authorization:
                  "Bearer " + this.$localStorage.get(this.$store.storeName),
              },
            }
          )
          .then((response) => {
            if (response.data.code == "1") {
              if (this.discount_delivery_free == 0) {
                // console.log(this.discount_delivery_free);
                this.deliveryPrice = response.data.data.cost;
              }
              this.selectDeliveryType = id;
              //console.log(this.selectDeliveryType);
              for (
                let el = 0;
                el < this.DBitems.delivery_type.types.length;
                el++
              ) {
                this.DBitems.delivery_type.types[el].isActive = false;
                if (this.DBitems.delivery_type.types[el].id == id) {
                  this.DBitems.delivery_type.types[el].isActive = true;
                }
                //console.log(this.DBitems.delivery_type.types[el].isActive);
              }
              // this.discountPrice = response.data.data.discount;
            } else {
              alert(response.data.message);
              this.selectAddress = null;
  
              const $select = document.querySelector("#addressSelect");
              $select.value = "SelectOne";
            }
            //this.addresses = response.data.data.addresses.addresses;
            // console.log(this.addresses);
          })
          .then(() => {
            this.loadingMethods = false;
          })
          .catch((error) => {
            if (error.response.status == "401") {
              this.$localStorage.remove(this.$store.storeName);
              window.location.href =
                "/";
            }
          });
      },
      onChange: function (event) {
        document.getElementById('addressSelect').style="border-color: #c9c9c9 !important;"
  
        this.selectAddress = event.target.value;
        this.getAddress(this.selectAddress)
        this.selectDeliveryTypeId(5);
      },
      onChangeMonths: function (event) {
        //console.log( event.target.value)
        this.count_month = event.target.value;
      },
      confirmOrder() {
  
        document.getElementById('payment_title').style="color: var(--primary-color); !important; text-decoration:none"
        document.getElementById('delivery_title').style="color:  var(--primary-color); !important; text-decoration:none"
        document.getElementById('addressSelect').style="border-color: #c9c9c9 !important;"
  
  
        var message_alert='';
        document.getElementById("confirm_btn").disabled = true;
      //  this.loading=true
        this.loading_checkout=true;
         
        if (this.selectPaymentType == null) {
          document.getElementById('payment_title').style="color:red !important; text-decoration:underline"
               
  
          if (this.$i18n.locale == "ar") {
            message_alert=("يجب تحديد طريقة الدفع");
            this.loading_checkout=false;
            
  
          } else {
            message_alert=("Please select a payment type");
            this.loading_checkout=false;
  
          }
        }
        
        if (this.selectDeliveryType == null) {
          document.getElementById('delivery_title').style="color:red !important; text-decoration:underline"
  
          if (this.$i18n.locale == "ar") {
            message_alert+=' '+("يجب تحديد طريقة التوصيل");
            this.loading_checkout=false;
  
          } else {
            message_alert+=' '+("Please select a delivery type");
            this.loading_checkout=false;
  
          }
        }
  
        if(this.selectDeliveryType==5&&this.selectAddress==null)
           {
            document.getElementById('addressSelect').style="border-color: red !important;"
            if (this.$i18n.locale == "ar") {
            message_alert+=' '+"يجب اختيار العنوان";
            this.loading_checkout=false;
  
          } else {
            message_alert+=' '+("Please select a Address");
            this.loading_checkout=false;
  
          }
           }
  
        if (message_alert=='') {
          
          this.loading = true;
          this.axios
            .post(
              this.$store.state.base_api_url +
              this.$store.storeName +
              "/order/store",
              {
                address_id: this.selectAddress,
                delivery_type_id: this.selectDeliveryType,
                payment_type_id: this.selectPaymentType,
                total_price: this.totalPrice,
                discount_id: this.discount_id,
                products: this.products,
                part_price: this.PartialPrice,
                note: this.allOrderNote,
                count_month: this.count_month,
                device: 'web',
              },
              {
                headers: {
                  "Accept-Language": this.$i18n.locale,
                  Authorization:
                    "Bearer " + this.$localStorage.get(this.$store.storeName),
                },
              }
            )
            .then((response) => {
              if (response.data.code == "1") {
                this.loading=false;
                if (response.data.data.clear_cart == null || response.data.data.link_pay == null) {
                  this.loading_checkout=false;
  
                  for (let i = 0; i < this.items.length; i++) {
                    this.$cookies.remove(this.items[i]);
                  }
                  this.items = [];
  
                  console.log("hiiiiiiii" + this.$cookies.keys());
                  this.$store.state.cartCounter = 0;
                }
  
                //console.log("hiiiiiiii" + this.$cookies.keys());
  
                if (response.data.data.link_pay == null) {
                  this.$store.state.cartCounter = 0;
                  this.$router.push({
                    name: "myOrders",
                    params: {
                      storeName: this.$store.storeName,
                      lang: this.$i18n.locale
                    },
                  });
                }
  
                if (response.data.data.link_pay != null) {
                  window.location.href = response.data.data.link_pay;
                  this.loading_checkout=false;
  
                }
  
                //this.deliveryPrice = response.data.data.cost;
                // this.discountPrice = response.data.data.discount;
              } 
              //this.addresses = response.data.data.addresses.addresses;
              // console.log(this.addresses);
            })
            .then((this.loading = false))
            .catch((error) => {
              if (error.response.status == "401") {
                this.$localStorage.remove(this.$store.storeName);
                window.location.href =
                  "/";
              }
            });
        }
        else{
          alert(message_alert);
          this.loading_checkout=false;
          document.getElementById("confirm_btn").disabled = false;
  
        }
      },
      getAddress(id) {
        this.loading = true;
        this.addAddress = false;
        this.address = null;
        this.axios
          .get(
            this.$store.state.base_api_url +
            this.$store.storeName +
            "/addresses/" +
            id,
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
                Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName)
              }
            }
          )
          .then(response => {
            this.address = response.data.data.address;
          })
          .then((this.loading = false))
          .catch(error => {
            if (error.response.status == "401") {
              this.$localStorage.remove(this.$store.storeName);
              window.location.href = "/";
  
            }
          });
      },
    },
  };
  
  </script>