var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(this.$store.state.loading_home)?_c('div',{staticClass:"d-flex flex-row justify-content-center loading_card"},[_c('div',{staticClass:"loading_card_spinner"},[_c('hollow-dots-spinner',{attrs:{"animation-duration":1000,"dot-size":35,"dots-num":3,"color":"var(--primary-color)"}})],1)]):_vm._e(),(this.$store.state.apperance_slider==1)?_c('banners'):_vm._e(),(this.$store.state.apperance_sections==1)?_c('section',[_c('div',{staticClass:"container-fluid relative mt-6 lg:mx-6 mx-3"},[(_vm.sections.length==0)?_c('div',{staticClass:"empty text-center center-text"},[_c('img',{attrs:{"src":"/pet_shop/assets/images/ajax-loader.gif"}}),_c('br')]):_c('div',{staticClass:"section_grid grid xl:grid-cols-6 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-6"},_vm._l((this.sections),function(item,index){return _c('div',{staticClass:"relative overflow-hidden group rounded-md shadow dark:shadow-slate-800"},[_c('router-link',{staticClass:"text-center",staticStyle:{"background-color":"lightgrey"},attrs:{"to":{
            name: 'section',
            params: {
              storeName: _vm.$store.storeName,
              id: item.id,
              name: item.name,
              lang:_vm.$store.state.routeLang
            },
          }}},[_c('img',{staticClass:"group-hover:scale-110 section_list_image",staticStyle:{"background-color":"lightgrey"},attrs:{"src":item.thumb,"alt":item.name}}),_c('span',{staticClass:"section_list_name bg-white dark:bg-slate-900 group-hover:text-orange-500 py-2 px-6 rounded-full shadow dark:shadow-gray-800 absolute bottom-4 mx-4 text-lg font-medium"},[_vm._v(" "+_vm._s(item.name))])])],1)}),0)])]):_vm._e(),(this.$store.state.apperance_latest_products==1)?_c('section',{staticClass:"relative md:py-24 py-16",staticStyle:{"padding-bottom":"0rem !important"}},[(_vm.products.length==0)?_c('div',{staticClass:"empty text-center center-text"},[_c('img',{attrs:{"src":"/pet_shop/assets/images/ajax-loader.gif"}}),_c('br')]):_c('div',{staticClass:"container relative"},[_c('div',{staticClass:"grid items-end md:grid-cols-2 mb-6"},[_c('div',{staticClass:"md:text-start text-center"},[_c('h5',{staticClass:"font-semibold text-3xl leading-normal mb-4"},[_vm._v(" "+_vm._s(_vm.$t('Latest products')))]),_c('p',{staticClass:"text-slate-400 max-w-xl"},[_vm._v(_vm._s(_vm.$t('Shop the latest products from the most popular items')))])]),_c('div',{staticClass:"md:text-end hidden md:block"},[_c('router-link',{staticClass:"text-slate-400 hover:text-orange-500",attrs:{"to":{
            name: 'products',
            params: {
              storeName: _vm.$store.storeName, lang:_vm.$store.state.routeLang
            },
          }}},[_vm._v(" "+_vm._s(_vm.$t('See More Items'))+" "),_c('i',{staticClass:"mdi mdi-arrow-right"})])],1)]),_c('div',{staticClass:"grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-2 pt-6 gap-6"},_vm._l((this.products),function(item,index){return _c('product',{key:index,attrs:{"name":item.name,"description":item.description,"is_favorit":item.is_favorit,"image":item.image,"old_price":item.old_price,"new_price":item.new_price,"productid":item.id,"rate":item.rate,"count_view":item.count_views,"is_option":item.is_option,"is_offer":item.is_offer,"unit":item.unit,"new":item.is_new,"fetuered":item.is_fetured,"discount":item.discount,"qnt":item.qnt,"min_qnt":item.min_qnt,"max_qnt":item.max_qnt,"note":item.note,"range_price":item.range_price}})}),1),_c('div',{staticClass:"md:text-end show_all md:block"},[_c('router-link',{staticClass:"text-slate-400 hover:text-orange-500",attrs:{"to":{
            name: 'products',
            params: {
              storeName: _vm.$store.storeName, lang:_vm.$store.state.routeLang
            },
          }}},[_vm._v(" "+_vm._s(_vm.$t('See More Items'))+" "),_c('i',{staticClass:"mdi mdi-arrow-right"})])],1)])]):_vm._e(),(this.$store.state.apperance_best_saller==1)?_c('products_saller'):_vm._e(),(this.$store.state.apperance_fetured_products==1)?_c('products_feautred'):_vm._e(),(this.$store.state.apperance_sections==1)?_c('sections_home'):_vm._e(),_c('dowenloadApp')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }