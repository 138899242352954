<template>
  <div id="app">

    <!-- Loader Start -->
    <div v-if="(loading == true && !this.DBitems) ||$store.state.loading"
      class="d-flex flex-row justify-content-center loading_card">
      <!-- LOADER -->
      <div style="position: fixed;
    top: 40%;
    left: 40%;">
        <hollow-dots-spinner :animation-duration="1000" :dot-size="35" :dots-num="3" color="var(--primary-color)" />

      </div>
    </div>
    <!-- Loader End -->

    <div>
      <news_ticker v-if="this.$store.state.last_news != 0"></news_ticker>
      <header-component v-if="this.$store.state.theme_id == 1 || this.$store.state.theme_id == 6"></header-component>
      <header-component2 v-else-if="this.$store.state.theme_id == 2"></header-component2>
      <header-component3 v-else-if="this.$store.state.theme_id == 3"></header-component3>
      <header-component5
        v-else-if="this.$store.state.theme_id == 5 || this.$store.state.theme_id == 7"></header-component5>
      <header-component8
        v-else-if="this.$store.state.theme_id == 8 || this.$store.state.theme_id == 9"></header-component8>
        <HeaderComponen10
        v-else-if="this.$store.state.theme_id == 10 || this.$store.state.theme_id == 11"></HeaderComponen10>
   
      <main v-if="this.$store.state.theme_id == 5 || this.$store.state.theme_id == 7">
        <div class="main_content">
          <router-view name="theme5" />
        </div>

      </main>

      <div v-else>
        <!--fade-in-down-->
        <router-view v-if="this.$store.state.theme_id == 1 || this.$store.state.theme_id == 6" />
        <router-view name="theme2" v-if="this.$store.state.theme_id == 2" />
        <router-view class="theme3" name="theme3" v-if="this.$store.state.theme_id == 3" />
        <router-view class="theme8" name="theme8" v-if="this.$store.state.theme_id == 8||this.$store.state.theme_id == 9" />
        <router-view class="theme10" name="theme10" v-if="this.$store.state.theme_id == 10||this.$store.state.theme_id == 11" />
        <!--</vue-page-transition>-->
      </div>



      <!--</vue-page-transition>-->

      <footer-component v-if="this.$store.state.theme_id == 1 || this.$store.state.theme_id == 6"></footer-component>
      <footer-component2 v-else-if="this.$store.state.theme_id == 2"></footer-component2>
      <footer-component3 v-else-if="this.$store.state.theme_id == 3"></footer-component3>
      <footer-component5
        v-else-if="this.$store.state.theme_id == 5 || this.$store.state.theme_id == 7"></footer-component5>
      <footer-component8
        v-else-if="this.$store.state.theme_id == 8 || this.$store.state.theme_id == 9"></footer-component8>
        <footer-component10
        v-else-if="this.$store.state.theme_id == 10 || this.$store.state.theme_id == 11"></footer-component10>

      <div style="text-align:center; margin-top:100px" v-if="NotFound">
        <a href="https://etrolley.net/">
          <img style="max-width:250px" src="https://etrolley.net/websiteImages/logoAR.png" />
        </a>
        <h3 dir="ltr">Sorry, this store is not available !!</h3>
        <h3>عذراً هذا المتجر غير متاح !!</h3>
        <!--{{$t('NotFound')}}-->
      </div>

    </div>
  </div>
</template>

<script>
import { HalfCircleSpinner, HollowDotsSpinner, CirclesToRhombusesSpinner } from "epic-spinners";

import HeaderComponent5 from "./theme5/components/header.vue";
import FooterComponent5 from "./theme5/components/footer.vue";

import HeaderComponent8 from "./theme8/components/header.vue";
import FooterComponent8 from "./theme8/components/footer.vue";

import HeaderComponent from "./components/Header.vue";
import FooterComponent from "./components/Footer.vue";
import HeaderComponent2 from "./Them2/components/Header.vue";
import FooterComponent2 from "./Them2/components/Footer.vue";
import HeaderComponent3 from "./Them3/components/Header.vue";
import FooterComponent3 from "./Them3/components/Footer.vue";
import HeaderComponen10 from "./theme10/components/header.vue";
import FooterComponent10 from "./theme10/components/footer.vue";
import news_ticker from "./components/news_ticker.vue";


export default {
  components: {
    HeaderComponent5,
    FooterComponent5,
    HeaderComponent,
    FooterComponent,
    HeaderComponent2,
    FooterComponent2,
    HeaderComponent3,
    FooterComponent3,
    news_ticker,
    HeaderComponent8,
    FooterComponent8,
    HeaderComponen10,
    FooterComponent10,
    HalfCircleSpinner,
    HollowDotsSpinner,
    CirclesToRhombusesSpinner

  },

  data: function () {
    return {
      logo: "",
      DBitems: null,
      loading: false,
      NotFound: false,

      title: null,
      subject: null,
      userimg: "",
      currentMessage: "",
      notify: false
    };
  },
  mounted() {
    //image with selected
    //this.loading=true;
    try {
      var obj = this.$messaging
        .getToken({
          vapidKey:
            "BDOMKCvpZbjN6yH3n3A3cNhV7_k7P6cucJmbgBJmiQVzSQUTHcaGaJQLfQic_WuY13FQfuANYrRhxlxYDqZcffA"
        })
        .then(currentToken => {
          if (currentToken) {
            this.$store.state.fsm_token = currentToken;
            console.log("client token", currentToken);
            try {
              fetch(
                `https://iid.googleapis.com/iid/v1/${currentToken}/rel/topics/${this.$store.storeName}`,
                {
                  method: "POST",
                  headers: new Headers({
                    Authorization: `key=AAAArMCrdjc:APA91bGmp4VqB3d7cOAl4zEvy5dflbuGgLc0XkBRiDr-phafDn3e0ki9VmJiwY4aklDADxMDNZKseZGtkmfHXfAV3AaxfB8QxEkmFtdrDo8owdKgQjtqdzjuiD_bx8_OZq6vS7KOoSkw`
                  })
                }
              )
                .then(response => {
                  if (response.status < 200 || response.status >= 400) {
                    console.log("oops" + response.status, response);
                  }
                  console.log(`"${topic}" is subscribed`);
                })
                .catch(error => {
                  //console.error(error.result);
                });
            } catch (error) { }
          } else {
            console.log(
              "No registration token available. Request permission to generate one."
            );
          }
        })
        .catch(err => {
          console.log("An error occurred while retrieving token. ", err);
        });
      console.log(obj);
      this.receiveMessage();
    } catch (e) {
      console.log(e);
    }
  },
  created() {
    this.loading = true;
    this.$store.storeName = this.$store.state.storeName;
    console.log(this.$store.storeName);
    this.axios
      .get(this.$store.state.base_api_url + this.$store.storeName + "/apperance_home")
      .then((response) => {
        this.$store.state.apperance_slider = response.data.data.slider
        this.$store.state.apperance_sections = response.data.data.sections
        this.$store.state.apperance_offers = response.data.data.offers
        this.$store.state.apperance_brands = response.data.data.brands
        this.$store.state.apperance_map = response.data.data.map
        this.$store.state.apperance_map_address = response.data.data.map_address
        this.$store.state.apperance_lang = response.data.data.lang
        this.$store.state.apperance_basic_lang = response.data.data.basic_lang
        this.$i18n.locale = response.data.data.basic_lang
        this.$store.state.apperance_latest_products = response.data.data.latest_products
        this.$store.state.apperance_best_saller = response.data.data.best_saller
        this.$store.state.apperance_fetured_products = response.data.data.fetured_products
        this.$store.state.digit_price=response.data.data.digit_price
        this.$store.state.guest_orders=response.data.data.guest_orders
        this.$store.state.gift_orders=response.data.data.gift_orders
      });
   
    //////////////check if store is exist ////////////////
    this.axios
      .get(this.$store.state.base_api_url + this.$store.storeName)
      .then(response => {
        if (response.data.code == "1") {

          this.DBitems = response.data.data;
          this.$store.state.unit = this.DBitems[0].unit_en;
          this.$store.state.unitAR = this.DBitems[0].unit_ar;
          this.$store.state.tax = this.DBitems[0].tax;
          this.$store.state.logo = this.DBitems[0].logo;
          this.$store.state.logo_footer = this.DBitems[0].logo_footer;
          this.$store.state.theme_id =this.DBitems[0].theme;
          this.$store.state.title_en = this.DBitems[0].title_en;
          this.$store.state.title_ar = this.DBitems[0].title_ar;
          this.$store.state.description_en = this.DBitems[0].description_en;
          this.$store.state.description_ar = this.DBitems[0].description_ar;
          this.$store.state.keyword_en = this.DBitems[0].keyword_en;
          this.$store.state.keyword_ar = this.DBitems[0].keyword_ar;

          if (this.$i18n.locale == 'ar') {
            this.$store.state.UnitPrice = this.$store.state.unitAR;
          }
          else {
            this.$store.state.UnitPrice = this.$store.state.unit;
          }
          //change header
          if (this.$store.state.theme_id == 8) {
            let h2 = document.querySelector('body');

            //h2.classList.add("theme1");
            document.getElementById('style').innerHTML = `
            <link href="/theme8/libs/tiny-slider/tiny-slider.css" rel="stylesheet">
         <link href="/theme8/libs/@mdi/font/css/materialdesignicons.min.css" rel="stylesheet" type="text/css">
        <link href="/theme8/css/tailwind4.css" rel="stylesheet" type="text/css">
        <link href="/theme8/libs/tiny-slider/tiny-slider.css"
         rel="stylesheet" type="text/css">`

          }
          else if(this.$store.state.theme_id == 10)
           {
            let h2 = document.querySelector('body');

            //h2.classList.add("theme1");
            document.getElementById('style').innerHTML = `
      <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet"
integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC" crossorigin="anonymous">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<link rel="stylesheet" type="text/css" href="/theme10/style/style.css">
`
          }
          else if (this.$store.state.theme_id == 2||this.$store.state.theme_id==1||this.$store.state.theme_id==3||this.$store.state.theme_id==6) {

            document.getElementById('style').innerHTML = `
            <link id="bootstrap" href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
            <link id="allmin" rel="stylesheet"  href="/assets/css/all.min.css">
           <link id="bootstrapbox" href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
           <link id="bootstrapglight" href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
           <link id="remixicon" href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
           <link id="swiper" href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">
          <link id="style" rel="stylesheet"  href="/assets/css/style.css">
          <link id="bootstrapbox" href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
          <link id="bootstrapglight" href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
          <link id="remixicon" href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
          <link id="swiper" href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">
            `

            let h2 = document.querySelector('body');

            h2.classList.add("theme2");

          }
          else if (this.$store.state.theme_id == 5||this.$store.state.theme_id == 7) {
            let h2 = document.querySelector('body');
            h2.classList.add("theme5");
            document.getElementById('style').innerHTML = `
            <link rel="stylesheet" href="/pet_shop/assets/css/animate.css">
            <link rel="stylesheet" href="/pet_shop/assets/css/ionicons.min.css">
            <link rel="stylesheet" href="/pet_shop/assets/css/themify-icons.css">
            <link rel="stylesheet" href="/pet_shop/assets/css/linearicons.css">
            <link rel="stylesheet" href="/pet_shop/assets/css/flaticon.css">
            <link rel="stylesheet" href="/pet_shop/assets/css/simple-line-icons.css">
            <link rel="stylesheet" href="/pet_shop/assets/owlcarousel/css/owl.carousel.min.css">
            <link rel="stylesheet" href="/pet_shop/assets/owlcarousel/css/owl.theme.css">
            <link rel="stylesheet" href="/pet_shop/assets/owlcarousel/css/owl.theme.default.min.css">
            <link rel="stylesheet" href="/pet_shop/assets/css/magnific-popup.css">
            <link rel="stylesheet" href="/pet_shop/assets/css/slick.css">
            <link rel="stylesheet" href="/pet_shop/assets/css/slick-theme.css">
            <link id="bootstrap" href="/pet_shop/assetsrtl/bootstrap/css/bootstrap.min.css" rel="stylesheet">
            <link id="allmin" rel="stylesheet"  href="/pet_shop/assets/css/all.min.css">
            <link id="bootstrapicon" href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
            <link id="bootstrapbox" href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
            <link id="bootstrapglight" href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
            <link id="remixicon" href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
            <link id="swiper" href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">
            <link id="style" href="/pet_shop/assetsrtl/css/style.css" rel="stylesheet">
<link rel="stylesheet" id="stylertl" href="/pet_shop/assetsrtl/css/rtl-style.css">
            `

          }

          if (this.$store.state.theme_id == 7) {
            let h2 = document.querySelector('body');
            h2.style = 'background-color:black !important;color:var(--primary-color) !important';
            h2.classList.add("dark_mode");
            h2.classList.add("theme5");
            if (document.querySelector("header"))
              document.querySelector("header").style = 'background-color:black !important;color:var(--primary-color) !important';
          }

         


          this.$store.state.country_code = this.DBitems[0].country_code;
          this.$store.state.country_flag = this.DBitems[0].country_flag;
          this.$store.state.country_length = this.DBitems[0].country_length;
          this.$store.state.phone = this.DBitems[0].phone;
          this.$store.state.email = this.DBitems[0].email;
          this.$store.state.auth = this.DBitems[0].auth;
          this.$store.state.master_code = this.DBitems[0].master_code;
          this.$store.state.sms_type = this.DBitems[0].sms_type;
          this.$store.state.last_news = this.DBitems[0].last_news;
          this.loading = false;

          if (this.$store.state.last_news != 0) {
            let h2 = document.querySelector('body');

            h2.classList.add("ticker_news");
          }
          var r = document.querySelector(":root");
          r.style.setProperty("--primary-color", this.DBitems[0].primary_color);

          r.style.setProperty("--secondary-color", this.DBitems[0].secondry_color );
          document.getElementById("Headerimg").href = this.$store.state.logo_footer;
          if (this.$i18n.locale == 'ar') {
            document.title = this.DBitems[0].title_ar;
            document.getElementById("description_meta").content = this.DBitems[0].description_ar;
            document.getElementById("keywords_meta").content = this.DBitems[0].keyword_ar;
          }
          else {
            document.title = this.DBitems[0].title_en;
            document.getElementById("description_meta").content = this.DBitems[0].description_en;
            document.getElementById("keywords_meta").content = this.DBitems[0].keyword_en;
          }


          if (this.$localStorage.get(this.$store.storeName) != null) {
            console.log(
              "testttttttttt session : " +
              this.$store.storeName +
              " : " +
              this.$localStorage.get(this.$store.storeName)
            );

          }

        } else if (response.data.code == "-1") {
          this.NotFound = true;
        }
      });


  



  },
  updated() {
    if (!this.NotFound) {

    }
  },
  methods: {
    receiveMessage() {
      try {
        this.$messaging.onMessage(payload => {
          // debugger
          this.currentMessage = payload;
          let message;
          message =
            payload.notification.title + ":\n\n" + payload.notification.body;
          this.setNotificationBoxForm(
            //payload.data.shipmentWallNumber,
            payload.notification.title,
            payload.notification.body
          );
          //console.log(message);
          this.notify = true;
          this.$store.state.notificationUpdate = !this.$store.state
            .notificationUpdate;
          console.log(
            this.$store.state.notificationUpdate +
            "notificationUpdate  hiiiiiiiiiiii  from "
          );
          setTimeout(() => {
            this.notify = false;
          }, 10000);
        });
      } catch (e) {
        console.log(e);
      }
    },


  }

};


</script>
