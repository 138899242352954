<template>
    <div>
        <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>

     <!-- Start Hero -->
     <section class="
     relative 
     table
     w-full 
     items-center py-36 
     bg-[url('theme8/images/hero/pages.jpg')] 
     bg-top
      bg-no-repeat
       bg-cover
       " style="background-image:url('/theme8/images/page_banner2.webp')">
            <div class="absolute inset-0 bg-gradient-to-b from-slate-900/60 via-slate-900/80 to-slate-900"></div>
            <div class="container relative">
                <div class="grid grid-cols-1 pb-8 text-center mt-10">
                    <h3 class="mb-3 text-4xl leading-normal tracking-wider font-semibold text-white">{{$t('privacyPolicy')}}</h3>

                </div><!--end grid-->
            </div><!--end container-->
            
            <div class="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                <ul class="tracking-[0.5px] mb-0 inline-block">
                    <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white">
                      <router-link :to="{
                              name: 'home',
                             params: { storeName: this.$store.storeName,lang:$store.state.routeLang },
                                     }">{{ $t('home') }}
                                     </router-link>
                                     </li>
                    <li class="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i class="mdi mdi-chevron-right"></i></li>
                    <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">{{ $t('privacyPolicy') }}</li>
                </ul>
            </div>
        </section><!--end section-->

  <section class="relative md:py-24 py-16">
            <div class="container relative" v-html="DBitems">
               
            </div><!--end container-->
            </section>



        </div>
        <!-- End Hero -->
</template>
<script>
export default {
  components:{},
  data: function () {
    return {
      loading: false,
      DBitems: null,
    };
  },
  created() {

    this.$store.state.topbar='topbar_light';
    this.$store.state.navbar='navbar_light';

    this.loading = true;
    this.axios
    .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/pages/PrivacyPolice",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((response) => {
        this.DBitems = response.data.data.description;
      })

      .then(() => (this.loading = false));

      if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
  },
  methods: {
    updatedata() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/pages/PrivacyPolice",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data.description;
        })
        .then(() => (this.loading = false));
        this.$store.state.langchange = false;
    },
  },
};
</script>
