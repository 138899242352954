<template>
    <div 
    class="card post_card">
    
                       <img :src=image
                       :alt="name"
                       loading="lazy"
                       class="one_product_image post_image"
                       @click="
                     $router.push({
                   name: 'blog',
                    params: {
                      storeName: $store.storeName ,
                      lang:$store.state.routeLang,
                      id: postid ,
                      name: slug,
                      }
                  });"
                       >
                      
                       <div class="post_meta_content">
                        <div class="d-flex justify-content-between desc_card">  
                        <h6 class="post_category"
                        
                        @click="
                   $router.push({
                   name: 'category_blogs',
                    params: {
                      storeName: $store.storeName ,
                      lang:$store.state.routeLang,
                      id: category_id ,
                      name: category,
                      }
                  });"
                        
                        >{{ this.category }}</h6>
                        <p> {{ this.date }}   </p>

                       </div>
                              <div class="">
                       <h6 class="title_card" 
                       style="cursor:pointer"
                       @click="
                   $router.push({
                   name: 'blog',
                    params: {
                      storeName: $store.storeName ,
                      lang:$store.state.routeLang,
                      id: postid ,
                      name: slug,
                      }
                  });">{{name}}</h6>
                 
                 <router-link
                class="post_link"
               :to="{
              name: 'blog',
              params: {
                 storeName: this.$store.storeName,
                 lang:$store.state.routeLang ,
                id: postid ,
                name: slug,},
            }"
                  >{{ $t("read more") }}...</router-link>
                
                    </div>

                       </div>
                   </div>
   </template>
   <script> 
   export default {
     components: {},
     props: {
       postid:Number,
       name: String,
       description: String,
       image: String,
       count_view: Number,
       category:String,
       category_id:Number,
       date:String,
       slug:String
     },
     data: function () {
       return {
       
       };
     },
    
   };
   </script>
   