<template>
  <section id="Second">
    <div class="container">
      <form @submit.prevent="submitInfo" @keydown="form.onKeydown($event)">
        <div class="row">
          <div>
            <h3 style="display: inline">{{ $t("CreateAccount") }}</h3>
            <div
              v-if="errorMessage != ''"
              style="
                text-align: center;
                padding: 5px;
                background-color: #fff;
                display: inline;
                margin: 0 10px;
              "
            >
              <span style="margin: 0; color: red; display: inline">{{
                errorMessage
              }}</span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="input1">
              <h4>{{ $t("name") }} <i style="color: red"></i></h4>
              <input
                id="name"
                class="input-field"
                type="text"
                name="name"
                autocomplete="false"
                :placeholder="$t('EnterName')"
                v-model="form.name"
                maxlength="255"
                minlength="3"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="input1" v-if="this.$store.state.auth == 1" >
              <h4>{{ $t("Email") }} <i style="color: red"></i></h4>
              <input
                class="input-field"
                type="email"
                name="email"
                autocomplete="false"
                v-model="form.email"
                placeholder="example@gmail.com"
               
              />
            </div>
            <div class="input1" v-else id="userNumber" >
              <h4>{{ $t("Phone") }} <i style="color: red">*</i></h4>
               <input
                id="phone"
                class="input-field"
                type="text"
                name="phone"
                autocomplete=""
                v-model="form.phone_basic"
                :placeholder="$t('EnterPhone')"
                required
                :minlength=$store.state.country_length
                :maxlength=$store.state.country_length
              />
              <span class="flag">  <h2>{{ $store.state.country_code }}</h2>
              <img :src="this.$store.state.country_flag" >
</span>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="input1">
              <h4>{{ $t("password") }} <i style="color: red">*</i></h4>
              <input
                id="password"
                class="input-field"
                type="password"
                name="name"
                autocomplete="new-password"
                v-model="form.password"
                :placeholder="$t('EnterPass')"
                required
                minlength="8"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="input1">
              <h4>{{ $t("passwordConfirm") }} <i style="color: red">*</i></h4>
              <input
                id="passwordconfirm"
                class="input-field"
                type="password"
                autocomplete="new-password"
                name="name"
                :placeholder="$t('EnterPass')"
                required
                v-model="confirmPassword"
                minlength="8"
              />
            </div>
          </div>
          <!--editttttt check if match-->
          <div class="d-flex flex-row justify-content-end">
            <button type="submit" :disabled="form.busy" class="button">
              {{ $t("CreateAccount") }}
            </button>
          </div>
        </div>
      </form>
      <!-- <div style="position: fixed; width: 500px; bottom: 10px; left: 10px">
        <AlertSuccess :form="form" message="تم ارسال الرسالة بنجاح" />
        <AlertErrors :form="form" message="لم يتم ارسال  الرسالة !" />
      </div>-->
    </div>
  </section>
</template>
<script>
// import testImage from "../../../public/assets/image/icons/defult.svg";

import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import Form from "vform";
import { AlertErrors, AlertSuccess } from "vform/src/components/bootstrap5";

export default {
  components: {
    AlertErrors,
    AlertSuccess,
    VuePhoneNumberInput,
  },
  props: {
    phone: String,
    email: String,
  },
  data: function () {
    return {
      isValid: true,
      DBitems: null,
      confirmPassword: null,
      errorMessage: "",
      partphone: null,
      form: new Form({
        name: null,
        /*email: null,*/
        password: null,
        phone: null,
        phone_basic:null,
        email: null,
        fsm_token: this.$store.state.fsm_token,
      }),
    };
  },
  created() {
    if (this.phone != null) {
      this.form.phone = this.phone;
      this.partphone=true;
      this.isValid = true;
    } else if (this.email != null) {
      this.form.email = this.email;
    }
    //console.log("hiiiiiiiiii" +this.form.phone    +   "   "  + this.form.email)
  },
  methods: {
    async submitInfo() {
      if (this.isValid) {
        if (this.confirmPassword == this.form.password) {
          console.log(this.form.phone)
          if(this.form.phone==null)
          {
            this.form.phone=this.$store.state.country_code+this.form.phone_basic
          }
          console.log(this.form.phone)
          const response = await this.form
            .post(
              this.$store.state.base_api_url +
                this.$store.storeName +
                "/register",

              {
                form: this.form,
                headers: {
                  "Accept-Language": this.$i18n.locale,
                },
              }
            )
            .then((response) => {
              if (response.data.code == "1") {
                //this.DBitems = response.data.data.token; //22|JaYzuFIY4eyIXjIh7haHEGxGS8xowdbnkElztmHh//44444444
                //this.$localStorage.set("userToken", response.data.data.token);
                //this.$localStorage.start();
                this.$localStorage.set(
                  this.$store.storeName,
                  response.data.data.token
                );
                //this.$localStorage.set("userToken", response.data.data.token);
                console.log(
                  this.$localStorage.get(this.$store.storeName) +
                    " id : " +
                    //this.$localStorage.id() +
                    this.$store.storeName
                );

                console.log(
                  "user token : " +
                    this.$localStorage.get(this.$store.storeName)
                );
               /* this.$router.push({
                  name: "home1",
                  params: { storeName: this.$store.storeName },
                });
                window.location.reload();*/
                window.location.href=  '/' ;
              } else if (response.data.code == "-1") {
                this.errorMessage = response.data.message;
                setTimeout(() => {
                  this.errorMessage = "";
                }, 3000);
              }
              if (response.data.error) {
                this.errorMessage = "response.data.error";
                setTimeout(() => {
                  this.errorMessage = "";
                }, 3000);
              }
            });
        } else {
          if (this.$i18n.locale == "en") {
            this.errorMessage = "the password not matching";
            document.getElementById('passwordconfirm').style="border:2px solid red"
             document.getElementById('password').style="border:2px solid red"
            setTimeout(() => {
              this.errorMessage = "";
               document.getElementById('passwordconfirm').style="border:2px solid var(--primary-color)"
             document.getElementById('password').style="border:2px solid var(--primary-color)"
            }, 3000);
          } else {
            this.errorMessage = "كلمتا السر غير متطابقتين";
            setTimeout(() => {
               document.getElementById('passwordconfirm').style="border:2px solid var(--primary-color)"
             document.getElementById('password').style="border:2px solid var(--primary-color)"
              this.errorMessage = "";
               document.getElementById('passwordconfirm').style="border:2px solid var(--primary-color)"
             document.getElementById('password').style="border:2px solid var(--primary-color)"
            }, 3000);
          }
        }
      } else {
        if (this.$i18n.locale == "en") {
          this.errorMessage = "your number isn't valid";
          setTimeout(() => {
            this.errorMessage = "";
          }, 3000);
        } else {
          this.errorMessage = "هذا الرقم غير صالح";
          setTimeout(() => {
            this.errorMessage = "";
          }, 3000);
        }
      }
    },
    sendPhone1(event) {
      this.isValid = event.isValid;
      if (this.isValid == true) {
        // this.form.phone = event.e164; //+ (' +' +event.countryCallingCode));
        //console.log(this.form.phone)
      }
      //console.log(event.isValid);
      //console.log(event);
    },
  },
};
</script>
<style scoped>
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.input1 {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 30px;
}
#Second {
  margin-top: 10px;
}

input {
  border: 0;
  border: 2px solid var(--primary-color);
  background-color: transparent;
  width: 100%;
  height: 32px;
  /*margin-top: 1%;*/
  border-radius: 10px;
  text-align: center;
  color: var(--secondary-color);
  padding: 25px 0 30px 0;
}
h3 {
  font-size: 20px;
  font-family: "Araboto-Bold";
  color: var(--primary-color);
  text-decoration: underline;
  text-underline-offset: 10px;
}
h4 {
  font-size: 16px;
  font-family: "Araboto-Medium";
  color: #000;
  margin-top: 20px;
  margin-bottom: 20px;
}

.button {
  background-color: var(--primary-color);
  border-radius: 10px;
  width: 20%;
  /* margin-left: 40%;
  margin-right: 40%;*/
  padding: 10px 0 15px;
  text-align: center;
  border: 2px solid var(--primary-color);
  color: #fff;
  font-size: 16px;
  font-family: "Araboto-Medium";
  margin-top: 50px;
}

.button:hover {
  background-color: #fff;
  color: #000;
}

.icon {
  margin-top: -120px;
}


/*@media (min-width: 1400px){
.container {
    max-width: 1200px !important;
}
}*/
@media (max-width: 992px) {
  h4 {
    font-size: 15px;
  }
  .card-img-actions {
    margin-top: 40px;
  }
  .input1 {
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 20px;
  }
  .button {
    width: 50%;
    margin-left: 25%;
    margin-right: 25%;
  }
}
@media (max-width: 772px) {
  .input1 {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 20px;
  }
  .button {
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
  }
}
</style>
