<template>
  <section  ref="scrollContainer" style="background-color: #fefefe; min-height:600px !important">
    <div v-if="loading" class="d-flex flex-row justify-content-center loading_card">
      <!-- LOADER -->
      <div class="loading_card_spinner">
        <hollow-dots-spinner :animation-duration="1000" :dot-size="35" :dots-num="3" color="var(--primary-color)" />

      </div>
      <!-- END LOADER -->

    </div>
    <div v-else>
      <img :src=section_image class="img_shop ">
      <div class="container">
        <h2 class="section_title" style="text-align: center;">{{ section_name }} / {{ name }}</h2>
        <div class="row">
          

          <div class="col-lg-3 col-md-3 col-12 filter_option_card" id="filters" >
            <div class="d-flex justify-content-between">
              <p>{{ $t('filters') }} </p>

              <p @click="clear" style="cursor:pointer" class="clear_normal">{{ $t('Clear All') }}</p>
              <!-- clear_mobile -->
              <p @click="clear_mobile" style="cursor:pointer" class="clear_mobile">{{ $t('Clear All') }}</p>

            </div>
            <div style="width: 100%; border: 1px solid #d1d1d2;">
              <div class="tab-accordion ">
                <div class="tab-panel">
                  <div class="tab-header d-flex justify-content-between" @click="toggleTab('tab1')"
                    style="padding: 5%;">
                    
                    <span class=""all_categories_title>{{ $t('categories') }}</span>
                    <span id="toggleIcon1">+</span>
                  </div>
                  <div id="tab1" class="tab-content">

                    <input type="text" class="dropdown-search se filter_search" :placeholder="$t('search')"
                      @onchange="search_subcategory" id="search_subcategory">
                    <ul id="subcategories">

                      <li v-for="(item, index) in this.categories" :key="index" :id="'li_category' + index">
                        <label class="checkbox">
                          <router-link class="category_link" :to="{
                            name: 'category',
                            params: {
                              storeName: $store.storeName,
                              lang: $i18n.locale,
                              id: item.id,
                              name: item.label
                            },
                          }">
                            <i class="fa fa-leaf" style="color:var(--primary-color) !important"></i> {{ item.label }}</router-link>
                        </label>
                      </li>

                    </ul>


                  </div>
                </div>



              </div>
            </div>

            <div v-if="this.brands.length != 0" style="width: 100%; border: 1px solid rgba(29, 29, 33, 0.2);">
              <div class="tab-accordion">
                <div class="tab-panel">
                  <div class="tab-header d-flex justify-content-between" @click="toggleTab('tab2')"
                    style="padding: 5%;">
                    <span>{{ $t('brands') }}</span>
                    <span id="toggleIcon2">+</span>
                  </div>
                  <div id="tab2" class="tab-content">

                    <input type="text" class="dropdown-search se filter_search" :placeholder="$t('search')">
                    <ul>
                      <li><label class="checkbox">
                          <input type="checkbox"> {{ $t('all') }}</label></li>
                      <li v-for="(item, index) in this.brands" :key="index">
                        <label class="checkbox">
                          <input type="checkbox" :id=item.id :value=item.id v-model="brands_array"
                            @change="filter_product()" checked>
                          {{ item.label }} </label>
                      </li>

                    </ul>

                  </div>
                </div>



              </div>
            </div>

            <div v-for="(item, index) in this.options" style="width: 100%; border: 1px solid rgba(29, 29, 33, 0.2);">
              <div class="tab-accordion">
                <div class="tab-panel">
                  <div class="tab-header d-flex justify-content-between" @click="toggleTab('tab3')"
                    style="padding: 5%;">
                    <span>{{ item.name }}</span>
                    <span id="toggleIcon3">+</span>
                  </div>
                  <div id="tab3" class="tab-content">

                    <input type="text" class="dropdown-search se filter_search" :placeholder="$t('search')">
                    <ul>
                      <li><label class="checkbox"><input type="checkbox"> {{ $t('all') }}</label></li>
                      <li v-for="(item2, index) in item.option_values.select_values" :key="index">
                        <label :id="'value' + item2.id" class="checkbox" :for="item2.id">
                          <input type="checkbox" name="checkbox" :id=item2.id :value=item2.id v-model=options_array
                            @change="check_option(item2.id)">
                          {{ item2.name }}
                        </label>
                      </li>
                    </ul>







                  </div>
                </div>



              </div>
            </div>


            <br>
            <div style="width: 100%; border: 1px solid rgba(29, 29, 33, 0.2);">
              <div class="tab-accordion">
                <div class="tab-panel">
                  <div class="tab-header d-flex justify-content-between" @click="toggleTab('tab4')"
                    style="padding: 5%;">
                    <span>{{ $t('price') }}</span>
                    <span id="toggleIcon4"></span>
                  </div>
                  <fieldset dir="ltr" style="
                          max-width: 80%;
                          margin: auto;
                          text-align: left;
                          padding: 20px;
                        ">
                    <MultiRangeSlider baseClassName="multi-range-slider-bar-only"
                    :minValue="minPrice"
                      :maxValue="maxPrice" 
                      :max="10000"
                       :min="0" 
                      :step="50" 
                      :rangeMargin="0"
                       :label="true" 
                       :ruler="true"
                      @input="update_oBarValues" />
                      <div class="text-center center-text">
                      <button @click="filter_price" class="filter_price">{{$t('filter_price')}}</button>
                      </div>
                    </fieldset>
                </div>



              </div>
            </div>
          </div>
          <div class="col-lg-9 col-md-9 col-12">

            <div class="filters_button_show">
              <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none">
                <template #button-content>

                  <a style="display: inline-block;color: #1D1D2166 ; text-decoration: none;font-size: 19px;
                                ">{{ $t('sort by') }}
                    <i class="fa fa-sort-alpha-asc" style="color:var(--primarey-color)"></i></a>

                </template>
                <b-dropdown-item @click="changeSort('desc', 'id')"> {{ $t("sort1") }}</b-dropdown-item>
                <b-dropdown-item @click="changeSort('asc', 'id')">{{ $t("sort2") }}</b-dropdown-item>
                <b-dropdown-item @click="changeSort('desc', 'sale_price')">{{ $t("sort3") }}</b-dropdown-item>
                <b-dropdown-item @click="changeSort('asc', 'sale_price')">{{ $t("sort4") }}</b-dropdown-item>
                <b-dropdown-item @click="changeSort('desc', 'count_view')">{{ $t("sort5") }}</b-dropdown-item>
                <b-dropdown-item @click="changeSort('asc', 'count_view')">{{ $t("sort6") }}</b-dropdown-item>
                <b-dropdown-item @click="changeSort('desc', 'quantity')">{{ $t("sort7") }}</b-dropdown-item>
                <b-dropdown-item @click="changeSort('asc', 'quantity')">{{ $t("sort8") }}</b-dropdown-item>
                <b-dropdown-item @click="changeSort('desc', 'count_sold')">{{ $t("sort9") }}</b-dropdown-item>
                <b-dropdown-item @click="changeSort('asc', 'count_sold')">{{ $t("sort10") }}</b-dropdown-item>

              </b-dropdown>
              <div class="text-center">
          <button class="active_mobile_filter " @click="show_filter" id="filter_btn">
             <i class="fa fa-filter"></i>{{ $t('filters') }}</button>
             </div>
            </div>

            <div v-if="this.products.length != 0" class="row" style="margin-top: 3%;">
              <div v-for="(item, index) in this.products" :key="index" class="col-lg-4 col-md-4 col-6">
                <product :name="item.name" :description="item.description" :is_favorit="item.is_favorit"
                  :image="item.image" :old_price="item.old_price" :new_price="item.new_price" :productid="item.id"
                  :rate="item.rate" :count_view="item.count_views" :is_option="item.is_option" :is_offer="item.is_offer"
                  :unit="item.unit" :new="item.is_new" :fetuered="item.is_fetured" :discount="item.discount"
                  :qnt="item.qnt" :min_qnt="item.min_qnt" :max_qnt="item.max_qnt">
                </product>

              </div>
              <div v-if="this.categories.length != 0" class="d-flex flex-row justify-content-center mt-5">
                <!-- <b-pagination v-model="page" :total-rows="count" :per-page="pageSize" :prev-text="$t('prev')"
                  :next-text="$t('next')" pills @change="handlePageChange"></b-pagination> -->
              </div>

            </div>

            <div v-else-if="this.loading">
              <div class="empty text-center center-text ">

                <img src="/pet_shop/assets/images/ajax-loader.gif" />
                <br>

              </div>
            </div>
            <div v-else>
              <div class="empty text-center center-text">

                <img src="/pet_shop/assets/images/empty.png" />
                <br>
                {{ $t('empty') }}
              </div>
            </div>

            <div v-if="this.loading_product">
              <div class="empty text-center center-text ">

                <img src="/pet_shop/assets/images/ajax-loader.gif" />
                <br>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import product from "../components/one_product_list.vue";
import { HalfCircleSpinner, HollowDotsSpinner } from "epic-spinners";
import MultiRangeSlider from "multi-range-slider-vue";
import "../../../node_modules/multi-range-slider-vue/MultiRangeSliderBlack.css";
import "../../../node_modules/multi-range-slider-vue/MultiRangeSliderBarOnly.css";

export default {
  components: { product, HollowDotsSpinner, HalfCircleSpinner, MultiRangeSlider },
  data() {
    return {
      minPrice: 0,
      maxPrice: 100000,
      rangePrice: [-20, 70],
      DBitems: null,
      loading: true,
      element: null,
      page: 1,
      count: 0,
      pageSize: 12,
      langChange: "",
      products: null,
      name: null,
      section_name: null,
      categories: null,
      brands: null,
      options: null,
      category_array: [],
      options_array: [],
      brands_array: [],
      category_id: null,
      sort: 'id',
      order: 'desc',
      paginate: 12,
      section_id: null,
      price_from: 0,
      price_to: null,
      pagefrom: 1,
      pageto: 12,
      total: 0,
      section_image: null,
      category_image: null,
      loading_product:false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  created() {
    if (this.$route.params.lang && this.$route.params.lang != this.$i18n.locale) {
      this.$i18n.locale = this.$route.params.lang; this.$store.state.UnitPrice = this.$store.state.unitAR
      if (this.$i18n.locale == 'en') {
        document.documentElement.setAttribute("dir", "ltr");
        let h = document.querySelector('body');
      }
    }
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName +
        "/theme10/category/" + this.$route.params.id,
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
          },
        }
      )
      .then((response) => {
        this.DBitems = response.data.data
        this.name = response.data.data.category_name
        this.section_name = response.data.data.section_name;
        this.section_id = response.data.data.section_id;
        this.category_name = response.data.data.category_name;
        this.section_image = response.data.data.section_image;
        this.category_image = response.data.data.category_image;
        this.brands = this.DBitems.brands;
        this.categories = this.DBitems.categories;
        this.options = this.DBitems.options;



      })
      .then(() => {
        this.loading = false;
      });



    //post product


    this.axios
      .post(
        this.$store.state.base_api_url + this.$store.storeName +
        "/products/filter_product_theme_10/category",
        {
          category: this.$route.params.id,
          options: this.options_array,
          sort: this.sort,
          order: this.order,
          paginate: this.paginate,
          brands: this.brands_array,
          from: this.minPrice,
          to: this.maxPrice
        },
        {
          headers: {
            "Accept-Language": this.$i18n.locale

          }
        }
      )
      .then(response => {
        this.products = response.data.data.products;
        this.count = response.data.meta.total;
        this.total = response.data.meta.total;
        this.pagefrom = response.data.meta.from;
        this.pageto = response.data.meta.to;

      })
      ;

    /////////////////////////
    if (this.$i18n.locale == 'ar') {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content = this.$store.state.description_ar;
      document.getElementById("keywords_meta").content = this.$store.state.keyword_ar;
    }
    else {
      document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content = this.$store.state.description_en;
      document.getElementById("keywords_meta").content = this.$store.state.keyword_en;
    }

  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll); // تنظيف مستمع الحدث عند تدمير المكون
  },
  watch: {
    langChange() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName +
          "/theme10/category/" + this.$route.params.id,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data
          this.section_name = response.data.data.section_name;
          this.category_name = response.data.data.category_name;
          this.section_image = response.data.data.section_image;
          this.category_image = response.data.data.category_image;
          this.brands = this.DBitems.brands;
          this.categories = this.DBitems.categories;
          this.options = this.DBitems.options;



        })
        .then(() => {
          this.loading = false;
        });


      //post product


      this.axios
        .post(
          this.$store.state.base_api_url + this.$store.storeName +
          "/products/filter_product_theme_10/category",
          {
            category: this.$route.params.id,
            options: this.options_array,
            sort: this.sort,
            order: this.order,
            paginate: this.paginate,
            brands: this.brands_array
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale

            }
          }
        )
        .then(response => {
          this.products = response.data.data.products;
          this.count = response.data.meta.total;
          this.pagefrom = response.data.meta.from;
          this.pageto = response.data.meta.to;
        })
        ;

    },

    "$route.params": {
      handler(newValue) {
        this.updateall();
      },
      immediate: true
    }

  },
  updated() {
    this.langChange = this.$i18n.locale

  },
  methods: {
    show_filter(){
      var element=document.getElementById('filters');
      element.style="display:block !important";
      document.getElementById('filter_btn').style="display:none !important"
    },
    handleScroll() {
      const scrollPosition = window.scrollY + window.innerHeight;  // موضع التمرير الحالي + ارتفاع نافذة العرض
      const bottomPosition = document.documentElement.scrollHeight;  // إجمالي ارتفاع الصفحة
      const container = this.$refs.scrollContainer;

      // // إذا وصل المستخدم إلى أسفل الصفحة، قم بتحميل المزيد من العناصر
      // if (scrollPosition >= bottomPosition - 400 && !this.loading) {
      //   this.loadMore();
      // }

      if (container.scrollHeight - container.scrollTop === container.clientHeight && !this.loading&&this.total>=this.page) {
        this.loading_product=true;
        this.loadMore();
      }
    },
    async loadMore() {
      
      this.page++
    
      this.axios
        .post(
          this.$store.state.base_api_url + this.$store.storeName +
          "/products/filter_product_theme_10/category?page=" + this.page,
          {
            category: this.$route.params.id,
            options: this.options_array,
            sort: this.sort,
            order: this.order,
            paginate: this.paginate,
            brands: this.brands_array
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale

            }
          }
        )
        .then(response => {
          this.products .push(...response.data.data.products);
       //   this.loading = false;
       this.loading_product=false;
        })

    },
    change_route(id, name) {
      this.$router.push({
        name: "catehory",
        params: {
          storeName: this.$store.storeName,
          lang: this.$i18n.locale,
          id: 2,
          name: name
        }
      });
    },
    toggleTab(tabId) {
      var tabContent = document.getElementById(tabId);
      var toggleIcon = document.getElementById('toggleIcon' + tabId.slice(-1));

      if (tabContent.style.display === 'block') {
        tabContent.style.display = 'none';
        toggleIcon.textContent = '+';
      } else {
        tabContent.style.display = 'block';
        toggleIcon.textContent = '-';
      }
    },
    check_option(id) {
      this.filter_product();
      console.log('444444444')
      var check_box = document.getElementById(id);
      if (check_box.checked == true) {
        document.getElementById('value' + id).style =
        "background-color:var(--primary-color) !important;display:none;width: fit-content !important;margin:2px;padding:2px 5px; min-width:50px;color:white !important"
      }
      else { document.getElementById('value' + id).style = "background-color:white;width:fit-content !important;margin:2px;padding:2px 5px; min-width:50px;" }

    },

    retrieveTutorials() {
      const params = this.page;

      this.loading = true;

      this.axios
        .post(
          this.$store.state.base_api_url + this.$store.storeName +
          "/products/filter_product_theme_10/category?page=" + this.page,
          {
            category: this.$route.params.id,
            options: this.options_array,
            sort: this.sort,
            order: this.order,
            paginate: this.paginate,
            brands: this.brands_array
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale

            }
          }
        )
        .then(response => {
          this.products = response.data.data.products;
          // this.count = response.data.meta.total;
          this.loading = false;

        })


    },
    handlePageChange(value) {
      this.page = value;
      this.retrieveTutorials();
    },


    filter_product() {
      console.log('brands array  ', this.brands_array);


      this.axios
        .post(
          this.$store.state.base_api_url + this.$store.storeName +
          "/products/filter_product_theme_10/category",
          {
            category: this.$route.params.id,
            options: this.options_array,
            sort: this.sort,
            order: this.order,
            paginate: this.paginate,
            brands: this.brands_array,
            from: this.minPrice,
            to: this.maxPrice

          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale

            }
          }
        )
        .then(response => {
          this.products = response.data.data.products;
          // this.count = response.data.meta.total;
        })
    },
    filter_category($id, $index) {
      this.element = document.getElementById('li_category' + $index);
      document.getElementById('li_all_category').classList.remove("active");

      for (var i = 0; i < this.categories.length; i++) {
        var element2 = document.getElementById('li_category' + i);
        if (element2)
          element2.classList.remove("active");

      }
      if (this.element)
        this.element.classList.add("active");
      if ($id == 0) {
        document.getElementById('li_all_category').classList.add("active");

      }
      this.category_id = $id;
      this.filter_product();
    }
    ,
    changeSort(order1, sort1) {
      this.order = order1; //asc
      this.sort = sort1; //id

      this.filter_product();
    },

    updatepaginate(event) {
      this.paginate = event.target.value

      this.filter_product();
    },
    show_sort() {
      document.getElementById('filter').style = 'display:block !important';
    },
    update_oBarValues(e) {
      this.minPrice = e.minValue;
      this.maxPrice = e.maxValue;
      console.log('//////price///change////////')
     
    },

    filter_price(){
      this.filter_product()
    },

    check_all_category() {
      var element = document.getElementById('all_category');
      var elements = document.getElementsByClassName('categories');
      if (element.checked) {
        const arrayColumn = (arr, n) => arr.map(x => x[n]);
        this.category_array = arrayColumn(this.categories, 'id');
        for (var i = 0; i < elements.length; i++) {
          elements[i].checked = true

        }
      }
      else {
        this.category_array = [];
        for (var i = 0; i < elements.length; i++) {
          elements[i].checked = false

        }

      }
      console.log('categories ', this.category_array)
      this.filter_product();

    },
    search_subcategory() {
      var search = document.getElementById('search_subcategory');
      var value = search.value.toLowerCase();
      var filter = 0;
      var $ul = document.getElementById('subcategories');
      //get all lis but not the one having search input
      var $li = $ul.getElementsByTagName("li");
      if (value == null || value == "") {
        for (let i = 0; i < $li.length; i++) {
          $li[i].style.display = "block";
        }
      }
      else
        //hide all lis
        for (let i = 0; i < $li.length; i++) {
          var a = $li[i].getElementsByTagName("label")[0];
          var txtValue = a.innerText;
          console.log(a);
          if ((txtValue.toLowerCase().indexOf(value) > -1) || (value == '') || (value == null)) {
            $li[i].style.display = "block";
            console.log('true filter', i, '  ', txtValue.toUpperCase().indexOf(filter))
          } else {
            $li[i].style.display = "none";
            a.checked = false;
          }
        }




    },
    updateall() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName +
          "/theme10/category/" + this.$route.params.id,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data
          this.name = response.data.data.category_name
          this.section_name = response.data.data.section_name;
          this.section_id = response.data.data.section_id;
          this.category_name = response.data.data.category_name;
          this.section_image = response.data.data.section_image;
          this.category_image = response.data.data.category_image;
          this.brands = this.DBitems.brands;
          this.categories = this.DBitems.categories;
          this.options = this.DBitems.options;



        })
        .then(() => {
          this.loading = false;
        });



      //post product


      this.axios
        .post(
          this.$store.state.base_api_url + this.$store.storeName +
          "/products/filter_product_theme_10/category",
          {
            category: this.$route.params.id,
            options: this.options_array,
            sort: this.sort,
            order: this.order,
            paginate: this.paginate,
            brands: this.brands_array,
            from: this.minPrice,
            to: this.maxPrice
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale

            }
          }
        )
        .then(response => {
          this.products = response.data.data.products;
          this.count = response.data.meta.total;
          this.total = response.data.meta.total;
          this.pagefrom = response.data.meta.from;
          this.pageto = response.data.meta.to;

        })
        ;

      /////////////////////////
      if (this.$i18n.locale == 'ar') {
        document.title = this.$store.state.title_ar;
        document.getElementById("description_meta").content = this.$store.state.description_ar;
        document.getElementById("keywords_meta").content = this.$store.state.keyword_ar;
      }
      else {
        document.title = this.$store.state.title_en;
        document.getElementById("description_meta").content = this.$store.state.description_en;
        document.getElementById("keywords_meta").content = this.$store.state.keyword_en;
      }
    },
    clear() {
      this.category_array = []
      this.options_array = []
      this.brands_array = []
      this.filter_product();
      
    }

    ,clear_mobile() {
      this.category_array = []
      this.options_array = []
      this.brands_array = []
      this.filter_product();

      var element=document.getElementById('filters');
      element.style="display:none !important";
      document.getElementById('filter_btn').style="display:block !important"

      
    }

  },


};
</script>