<template>
  <section style="min-height:600px" >
    <div v-if="this.$store.state.langchange">{{ updatedata() }}</div>
    <div
        v-if="!DBitems||loading"
        class="d-flex flex-row justify-content-center loading_card"
       
      >
        <!-- LOADER -->
        <div  v-if="loading" 
        class="loading_card_spinner">
  <hollow-dots-spinner
          :animation-duration="1000"
          :dot-size="35"
          :dots-num="3"
          color="var(--primary-color)"
        />
     
</div>
<!-- END LOADER -->

      </div>
  <div v-else >

<!-- END LOADER -->

    
  <!-- Start Hero -->
  <section class="page_title relative table w-full py-20 lg:py-24 md:pt-28 bg-gray-50 dark:bg-slate-800">
          <div class="container relative">
              <div class="grid grid-cols-1 mt-14">
                  <h3 class="text-3xl leading-normal font-semibold">{{ DBitems.product.name }}</h3>
              </div><!--end grid-->

              <div class="relative mt-3">
                  <ul class="tracking-[0.5px] mb-0 inline-block">
                      <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-orange-500">
                          <router-link >
                              {{ $t('home') }}
                              
                          </router-link></li>
                      <li class="inline-block text-base text-slate-950 dark:text-white mx-0.5 ltr:rotate-0 rtl:rotate-180">
                        <i class="mdi mdi-chevron-right"></i></li>
                      <li class="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-orange-500"><router-link :to="{
                            name: 'products',
                           params: { storeName: this.$store.storeName,lang:$store.state.routeLang },
                                   }">
                                   {{ $t('product') }}
                                   </router-link>
                                   </li>
                      <li class="inline-block text-base text-slate-950 dark:text-white mx-0.5 ltr:rotate-0 rtl:rotate-180">
                          <i class="mdi mdi-chevron-right"></i></li>
                      <li class="inline-block uppercase text-[13px] font-bold text-orange-500" 
                      aria-current="page">{{ DBitems.product.category }}</li>
                  </ul>
              </div>
          </div><!--end container-->
      </section><!--end section-->
      <!-- End Hero -->
      
       <!-- Start -->
       <section  class="relative md:py-24 py-16">
          <div class="container relative">
              <div class="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-6">
                <div class="lg:col-span-5">
            <div v-if="this.DBitems" class="tiny-single-item" dir="ltr">

              <carousel :per-page="1" :mouse-drag="true" autoplay loop :autoplayTimeout=5000
                :autoplayDirection="forward" :paginationEnabled="true">
                <slide v-for="(item, index) in this.DBitems.images.images">
                  <div class="tiny-slide tns-item">
                    <div class="m-0.5">
                      <img :src="item.src"
                      class="main_product_img shadow dark:shadow-gray-700"
                      >
                    </div>
                  </div><!--end content-->

                </slide>
              </carousel>

            </div><!--end tiny slider-->
          </div><!--end col-->

                  <div class="lg:col-span-7">
                      <h5 class="text-2xl font-semibold">{{ this.DBitems.product.name }}</h5>
                      <div class="mt-2">
                        <!-- if offer -->

                        <div class="price_one_product" v-if="selectOption&&this.DBitems.product.range_price">
                          <span class="text-slate-400 font-semibold me-1">
                            {{this.DBitems.product.range_price.min_price*$store.state.rate}}{{$store.state.UnitPrice}} _ {{ this.DBitems.product.range_price.max_price *$store.state.rate}}{{$store.state.UnitPrice}}
                              </span>
                            </div>

                        <div class="price_one_product" v-else-if="selectOption">
                          <span class="text-slate-400 font-semibold me-1">
                            {{ $t('selectOption') }}
                              </span>
                            </div>
                              <!-- end if offer -->
                              <!-- if not offer -->
                              <div class="price_one_product"  v-else-if="offer_price==0">
                          <span class="text-slate-400 font-semibold me-1 product_price">
                            {{ (this.price*this.$store.state.rate).toFixed(2) }} {{ $store.state.UnitPrice }}
                              </span>
                               </div>
                              <!-- end if not offer -->


                              <!-- if not offer not new price -->
                              <div class="price_one_product"  v-else>
                          <span class="text-slate-400 font-semibold me-1 product_price">
                            {{ (this.offer_price*this.$store.state.rate).toFixed(2) }} {{ this.$store.state.UnitPrice }}
                            <del class="text-red-600">
                              {{ (this.price*this.$store.state.rate).toFixed(2) }} {{ this.$store.state.UnitPrice }}</del>
                              </span>
                              <div class="on_sale">
                            <span v-if="this.DBitems.product.is_offer == true">
                              {{ $t('discount') }}:
                              {{DBitems.product.discount}}% </span>
                              <div
          class="orderInfo d-flex flex-row justify-content-between mt-1"
          v-if="this.DBitems.product.is_offer == true"
        >
        <h4 style="color: var(--primary-color)">{{ $t("offerEnd") }} :</h4>
          <h5 style="color: var(--primary-color)">{{ this.DBitems.product.offer_end }}</h5>
        </div>
                            
                        </div>
                             </div>
                              <!-- end if not offer not new price -->

                         

                          <ul v-if="DBitems.rate!=0" class="list-none inline-block text-orange-400">
                                  <li v-for="n in DBitems.rate" :key="n" class="inline">
                                      <i class="mdi mdi-star"></i></li>
                                      <li v-for="n in 5 - DBitems.rate" :key="n" class="inline">
                                      <i class="mdi mdi-star-outline"></i></li>
                                    <li class="inline text-slate-400 font-semibold">{{ DBitems.rate }} ({{ DBitems.count_rate }})</li>
                                 
                              </ul>
                      </div>

                      <div class="mt-4 " >
                          <h5 class="text-lg font-semibold">{{ $t('Overview') }} :</h5>
                          <div class="text-slate-400 mt-2 product_description" v-html="DBitems.product.description">
                            
                          </div>
                          
                      </div>

                      <div class="grid lg:grid-cols-1 grid-cols-1 gap-6 mt-4">
                          <div v-for="(item, index) in DBitems.options.options" :key="index" 
                          class="flex items-center">
                              <h5 class="text-lg font-semibold me-2">{{ item.name }}:</h5>
                              <div class="space-x-1">
                                
                                  <label
                                  :title="$t('select option')+' '+item1.name"
                                  :id="'select_value'+item1.id"
                                  v-for="(item1, index) in item.option_values.select_values"
                                  :class="'size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md hover:bg-orange-500 hover:text-white select_value_product option'+item.id"
                                   :for="item1.id+'select'+index"
                                  >
                                  {{ item1.name }}
                                  
                                  <input type="radio" 
                              :id="item1.id+'select'+index" 
                              :name="'select'+item.id" 
                              :value="item1.id"
                              :title="$t('SelectOne')"
                              @change="upadateprice($event, item.id,item1.id)"
                              style="display:none"
                              :class="'option_value_radio '+item.id+'select_value'+index"
                              />
                                  </label>
                                 
                              </div>
                          </div><!--end content-->
                           <!-- check box -->
                    <h4 v-if="DBitems.options_check.options.length!=0" 
                    style="color:var(--primary-color)">{{ $t('optional') }}:</h4>
                       <div v-for="(item, index) in DBitems.options_check.options" :key="index">
  
                      <div>
                        <div v-for="(item1, index1) in checked" :key="index1">
                          
              <div
                class="orderInfo d-flex flex-row justify-content-between"
                style="margin-bottom: 15px"
                v-if="checked[index1].id == item.id"
              >
                <h6>{{ item.name }} :</h6>
                <div class="d-flex flex-row justify-content-between" style="width: 120px">
                  
                  <label  for="checkbox">{{ item.price }} {{ DBitems.product.unit }}</label>
                  <input
                    type="checkbox"
                    id="checkbox"
                    v-model="checked[index1].value"
                    style="width: 30px; height: 25px"
                    @change="changeCheckbox(item, checked[index1].value)"
                  />
                </div>
              </div>
            </div>
          </div>
                      </div>

                    <!-- end check box -->

                        
</div>


<br>

                <div v-if="loading_price" class="empty text-center center-text">
                 
                 <img src="/pet_shop/assets/images/ajax-loader.gif"/>
                           <br>

                       </div>


                          <div  v-if="((quanity == 0 ||(this.max_quanity==0))&& !selectOption&&!loading_price) " 
                          class="flex items-center">
                              <h5 class="text-lg font-semibold me-2">{{ $t("NoQuantity") }}:</h5>
                              
                          </div><!--end content-->

                          <div 
                          v-else-if="selectOption&&!loading_price"
                          class="flex items-center">
                              <h5 class="text-lg font-semibold me-2">
                               
                                </h5>
                             
                          </div><!--end content-->

                              
                          <div v-else-if="!loading_price" class="flex items-center price_card_cart" >
                              <h5 class="text-lg font-semibold me-2">{{ $t('Quantity') }}:</h5>
                              <div class="qty-icons ms-3 space-x-0.5">
                                  <button @click="decrementClick()"
                                  class="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white minus">-</button>
                                  <input min="1"  name="quantity" :value="counterVal" title="Qty"  type="number"
                                   class="h-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white pointer-events-none w-16 ps-4 quantity">
                                  <button @click="incrementClick()"
                                   class="size-9 inline-flex items-center justify-center tracking-wide align-middle text-base text-center rounded-md bg-orange-500/5 hover:bg-orange-500 text-orange-500 hover:text-white plus">+</button>
                              </div>

                              <div class="mb-1 space-x-1" style="margin:0px 10px">
                          
                          <a id="#addToCart"
                        v-if="quanity != 0|| max_quanity!=0"
                       @click="addToCart" 
                          class="py-2 px-5 inline-block font-semibold
                           tracking-wide align-middle text-base text-center 
                           rounded-md bg-orange-500/5 hover:bg-orange-500 
                           text-orange-500 hover:text-white mt-2">
                           {{ $t("Addtocart") }}
                           </a>
                      </div>

                          </div><!--end content-->
                         
                      <!--end grid-->

                    

                  </div><!--end content-->
              </div><!--end grid-->
              <div v-if="loading_checkout" class="empty text-center center-text">
                 
                 <img src="/pet_shop/assets/images/ajax-loader.gif"/>
                           <br>

                       </div>
              
              <div class="grid md:grid-cols-12 grid-cols-1 mt-6 gap-6">
                  <div class="lg:col-span-3 md:col-span-5">
                      <div class="sticky top-20">
                          <ul class="flex-column 
                          p-6 bg-white dark:bg-slate-900
                           shadow dark:shadow-gray-800 
                           rounded-md" 
                           id="myTab" 
                           data-tabs-toggle="#myTabContent"
                            role="tablist">
                             
                             
                              <li role="presentation">
                                  <button class="px-4 py-2 text-start 
                                  text-base font-semibold rounded-md 
                                  w-full mt-3 duration-500" 
                                  id="review-tab"
                                  @click="show_section('review')"
                                   data-tabs-target="#review" 
                                   type="button" role="tab" 
                                   aria-controls="review" 
                                    style="background-color:var(--primary-color);color:white"
                                   aria-selected="false">{{ $t('Reviews') }}</button>
                              </li>
                          </ul>
                      </div>
                  </div>

                  <div class="lg:col-span-9 md:col-span-7">
                      <div id="myTabContent" class="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                         
                         

                          <div class=""
                           id="review" 
                           role="tabpanel"
                            aria-labelledby="review-tab">
  
                              <div class="mt-8"
                             
                               v-for="(item, index) in DBitems.reviews" 
                                  :key="index"
                              >
                                  <div class="flex items-center justify-between">
                                      <div class="flex items-center">
                                          <img :src="item.image"
                                           class="h-11 w-11 rounded-full shadow"
                                            alt="">
      
                                          <div class="ms-3 flex-1">
                                              <a href="" class="text-lg font-semibold
                                               hover:text-orange-500 duration-500">
                                               {{item.name}}</a>
                                              <p class="text-sm text-slate-400">
                                                {{ item.date }}</p>
                                          </div>
                                      </div>
  
                                     
                                  </div>
                                  <div class="p-4 bg-gray-50 dark:bg-slate-800 rounded-md shadow dark:shadow-gray-800 mt-6">
                                      <ul class="list-none inline-block text-orange-400">
                                        <li v-for="n in DBitems.rate" :key="n" class="inline">
                                      <i class="mdi mdi-star"></i></li>
                                      <li v-for="n in 5 - DBitems.rate" :key="n" class="inline">
                                      <i class="mdi mdi-star-outline"></i></li>
                                          <li class="inline text-slate-400 font-semibold">{{ item.rate }}</li>
                                      </ul>

                                      <p class="text-slate-400 italic">
                                        {{ item.msg }}
                                      </p>
                                  </div>
                              </div>
                              <div  v-if="DBitems.reviews.length==0">
                                <h3>{{ $t('empty') }}</h3>
                                </div>
  
                              <div  v-if="DBitems.product.is_rate"
                               class="p-6 rounded-md shadow dark:shadow-gray-800 mt-8">
                                  <h5 class="text-lg font-semibold">{{ $t('Leave A Comment:') }}</h5>
      
                                  <add-evaluation></add-evaluation>
                              </div>
                          </div>
                      </div>
                  </div>
              </div><!--end grid-->
          </div><!--end container-->

          <div  v-if="this.DBitems.similar_products.length != 0" 
          class="container lg:mt-24 mt-16">
              <div class="grid grid-cols-1 mb-6 text-center">
                  <h3 class="font-semibold text-3xl leading-normal">{{ $t('New Arrival Items') }}</h3>
              </div><!--end grid-->

              <div class="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-2 gap-6 pt-6">
                <product 
                v-for="(item, index) in this.DBitems.similar_products"
                 :key="index"
            :name="item.name"
            :description="item.description"
            :is_favorit="item.is_favorit"
            :image="item.thump"
            :old_price="item.old_price"
            :new_price="item.new_price"
            :productid="item.id"
            :rate="item.rate"
            :count_view="item.count_views"
            :is_option="item.is_option"
            :is_offer="item.is_offer"
             :unit="item.unit"
             :new="item.is_new"
             :fetuered="item.is_fetured"
             :discount="item.discount"
             :qnt="item.qnt"
             :min_qnt="item.min_qnt"
             :max_qnt="item.max_qnt"
             :note="item.note"
             :range_price="item.range_price"

      >

        </product>

              </div>
          </div>
      </section><!--end section-->
      <!-- End -->

      <div
    v-if="validateMessage != ''"
    style="
      position: fixed;
      z-index: 100;
      width: 300px;
      bottom: 50px;
      left: 50px;
      border-radius: 5px;
      background-color: var(--primary-color);
      border: 2px solid var(--primary-color);
      text-align: center;

      padding-top: 5px;
      color:white;
    "
  >
    <p>{{ validateMessage }}</p>

   
   
</div>
      </div>
      <!-- End -->
      </section>
</template>


<script>
import product from "../components/one_product_list.vue";
import AddEvaluation from "../components/AddEvaluation.vue";
import { Carousel, Slide } from "vue-carousel";
import Vodal from "vodal";
import "vodal/common.css";
import "vodal/slide-down.css";
import "vodal/flip.css";
import { HalfCircleSpinner, HollowDotsSpinner,CirclesToRhombusesSpinner } from "epic-spinners";


export default {
  components: { Vodal, Carousel,Slide,product, AddEvaluation,HalfCircleSpinner,HollowDotsSpinner,CirclesToRhombusesSpinner },

  data: function() {
    return {
      loading_price: false,
      counterVal: 1,
      loginVodal: false,
      loginMassage: "",
       old_qnt:0,
      currentId: null,
      width: 600,
      height: 350,

      DBitems: null,
      loading: false,
      loading_checkout:false,

      options: [],
      selectedOptionArray: [],
      price: 0,
      quanity: 0,
      max_quanity: 0,
      min_quanity: 0,
      Firstquanity: 0,
      finalOptions: 0,
      checked: [],
      validateMessage: "",
      selectOption: false,
      images:null,
      count_images:null,
      fav: false,
      offer_price: 0,
      //is_offer: false,
      //discount: null,
      note: "",
      addeval: false
    };
  },
  created() {

    this.$store.state.topbar='topbar_light';
    this.$store.state.navbar='navbar_dark';

    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/product/theme5/" +
          this.$route.params.id,
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        this.checked = [];
        this.DBitems = response.data.data;
        if(this.DBitems.product.seo_title)
      document.title = this.DBitems.product.seo_title;
    else
    document.title = this.DBitems.product.name;
      if(this.DBitems.product.seo_description)
      document.getElementById("description_meta").content=this.DBitems.product.seo_description;
      if(this.DBitems.product.seo_keyword)
      document.getElementById("keywords_meta").content=this.DBitems.product.seo_keyword;
        this.fav = this.DBitems.product.is_favorit;
        this.images=response.data.data.images.images;
       // this.old_images=response.data.data.images.images;
        this.count_images=response.data.data.images.images.length
        var option1 = this.DBitems.options.options;
      var option2 = this.DBitems.options_check.options;
      for (var i = 0; i < option1.length; i++) {
        //if option  not empty
        if (option1[i].option_values.select_values != null) {
          // if select
          var element = {};
          element.value_id = null;//option1[i].option_values.select_values[0].id;
          element.option_id = option1[i].id;
          this.options.push(element);
          ///console.log(this.options.length +"iam theeeeeeeeeeeeeeer")
        } 
        }
        
        for (var i = 0; i < option2.length; i++) 
        {
          // if checkbox
          var checked = {};
          checked.id = option2[i].id;
          checked.value = false;
          this.checked.push(checked);
          //console.log(this.checked)
        }

        //console.log(this.options)
      })
      .then(() => {
        this.loading = false;
        if (this.options.length != 0) {
          this.selectOption = true;
         
        } 
        else if (this.options.length == 0) {
          // if we not found select options
          //console.log("hiiiiiiiiii created" + this.DBitems.product.quanity);
          this.price = this.DBitems.product.sale_price;
          this.offer_price = this.DBitems.product.offer_price;
          this.quanity = this.DBitems.product.quantity;
          this.max_quanity = this.DBitems.product.quantity;
          this.min_quanity=1;
          if(this.DBitems.product.min_qnt)
          this.min_quanity = this.DBitems.product.min_qnt;
          if(this.DBitems.product.max_qnt>0)
          this.max_quanity = this.DBitems.product.max_qnt;
          this.Firstquanity = this.DBitems.product.quantity;

          /////////////nottttttttttttttttttteeeeeeeeeeeeeeeeeeee////////////
          var checked_true = [];
          for (var i = 0; i < this.checked.length; i++) {
            if (this.checked[i].value == true) {
              checked_true.push(this.checked[i].id);
            }
          }
          let S_name_full =
            this.$store.storeName +
            "/" +
            this.$route.params.id +
            this.finalOptions +
            checked_true;

          let S_name2 =
            this.$store.storeName +
            "/" +
            this.$route.params.id +
            this.finalOptions;
          console.log("S_name " + S_name2);

          let qun = this.quanity;
          let min_qun = this.min_quanity;
          let max_qun = this.max_quanity;
          let AllCookies = this.$cookies.keys();
          for (let i = 0; i < AllCookies.length; i++) {
            if (AllCookies[i].includes(S_name2)) {
              // if (AllCookies[i] == S_name_full) {
              //  console.log("hii i am there ");
              //} else {
                this.old_qnt=this.$cookies.get(AllCookies[i]).quantity;
              qun = qun - this.$cookies.get(AllCookies[i]).quantity;
             min_qun = min_qun- this.$cookies.get(AllCookies[i]).quantity;
             if(min_qun<0)
             {
              min_qun=1;
             }
             if( max_qun!=-1)
           max_qun = max_qun- this.$cookies.get(AllCookies[i]).quantity;
           if(max_qun==-1)
           {
            max_qun=-1;
           } 
           else if(max_qun<=0&& max_qun!=-1)
           {
            max_qun=0;
           } 
              console.log(
                this.$cookies.get(AllCookies[i]).quantity + " quuuuunb" + qun
              );
              // }
            }
          }
          this.quanity = qun;
          this.min_quanity = min_qun;
          this.max_quanity = max_qun;
        
        }
        if(this.min_quanity>0)
         {
          this.counterVal=this.min_quanity;
          this.price *= this.counterVal;
          this.offer_price *= this.counterVal;
         }
      });


      //counter value 

         
      //end counter value
  },
  methods: {
    change_image(index,src){
console.log('src ',src)
    for(var i=0;i<this.images.length;i++)
    {
       document.getElementById('image'+i).style="border:1px solid transperent !important";
    }
    document.getElementById('image'+index).style="border:1px solid var(--primary-color) !important";
   //document.getElementById('product_img').src=src;
    //  this.first_image=src;
    document.getElementById("main_image_product").src=src

  },
    incrementClick() {
    

      if (this.quanity > 0) {
        console.log("*************** max qnt   "+this.max_quanity+" ");
        if (this.counterVal < this.quanity&&this.counterVal < this.max_quanity) {
          ++this.counterVal;
          this.price = this.price / (this.counterVal - 1) + this.price;
          if (this.offer_price>0) {
            this.offer_price =
              this.offer_price / (this.counterVal - 1) + this.offer_price;
            /*   this.offer_price =
              this.price - (this.DBitems.product.discount / 100) * this.price;*/
          }
        }
      } else {
        ++this.counterVal;
        this.price = this.price / (this.counterVal - 1) + this.price;
        if (this.offer_price>0) {
          this.offer_price =
            this.offer_price / (this.counterVal - 1) + this.offer_price;
          /* this.offer_price =
            this.price - (this.DBitems.product.discount / 100) * this.price;*/
        }
      }
      //}
    },
    decrementClick() {
      if (this.counterVal > 1&&this.counterVal >this.min_quanity) {
        --this.counterVal;
        //this.price = this.price/2
        this.price = this.price - this.price / (this.counterVal + 1);
        if (this.offer_price>0) {
          this.offer_price =
            this.offer_price - this.offer_price / (this.counterVal + 1);
          /*    this.offer_price =
            this.price - (this.DBitems.product.discount / 100) * this.price;*/
        }
      }
    },
    updatedata() {
    
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/product/theme5/" +
            this.$route.params.id,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data;
          if(this.DBitems.product.seo_title)
      document.title = this.DBitems.product.seo_title;
    else
    document.title = this.DBitems.product.name;
      if(this.DBitems.product.seo_description)
      document.getElementById("description_meta").content=this.DBitems.product.seo_description;
      if(this.DBitems.product.seo_keyword)
      document.getElementById("keywords_meta").content=this.DBitems.product.seo_keyword;
      this.images=response.data.data.images.images;
          
          this.fav = this.DBitems.product.is_favorit;
        })
        .then(() => (this.loading = false));

      //this.$refs.childref.update(this.categoryID)
      this.$store.state.langchange = false;
    },
    upadateprice(event, option_id,select_id) {
      this.loading_price=true;
      var element=document.getElementById('select_value'+select_id)
     
         console.log(element,'???????/')
          var collections = document.getElementsByClassName("option"+option_id);
          for (var i = 0; i < collections.length; i++) {
            if(collections[i])
            collections[i].classList.remove('active')
          }
          if(element)
          {
            element.classList.add('active');
          }

      for (var i = 0; i < this.options.length; i++) {
        if (this.options[i].option_id == option_id) {
          this.options[i].value_id = event.target.value;
          if (this.selectedOptionArray.length < this.options.length) {
            //if (!this.selectedOptionArray.includes(this.options[i].option_id)) {
            this.selectedOptionArray.push(this.options[i].option_id);
            //}
          }
        }
      }
      //console.log(this.options.length  +" hii " +this.selectedOptionArray.length)
      if (this.options.length == this.selectedOptionArray.length) {
        this.selectOption = false;
        
        var options = this.options;
        this.axios
          .post(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/product_price/" +
              this.$route.params.id,
            {
              options
            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale
              }
            }
          )
          .then(response => {
            this.price = response.data.data.price;
            this.offer_price = response.data.data.new_price;
            this.quanity = response.data.data.quanity;
            this.max_quanity = response.data.data.quanity;
            this.min_quanity=1;
            if(response.data.data.min_qnt>1)
            this.min_quanity = response.data.data.min_qnt;
            if(response.data.data.max_qnt>0)
            this.max_quanity = response.data.data.max_qnt;
            this.Firstquanity = response.data.data.quanity;
            this.finalOptions = response.data.data.final_option;
            for (var i = 0; i < this.checked.length; i++) {
              this.checked[i].value = false;
            }
            if(response.data.data.image)
            {
                 if(this.images.length>this.count_images)
                 this.images.shift();
              this.images.unshift(
               
                {
                  id:'1',
                 src: response.data.data.image,
                  thumbnail: response.data.data.image
                }
               
              )
            }
            
            this.note = "";
            var checked_true = [];
            for (var i = 0; i < this.checked.length; i++) {
              if (this.checked[i].value == true) {
                checked_true.push(this.checked[i].id);
              }
            }
            let S_name_full =
              this.$store.storeName +
              "/" +
              this.$route.params.id +
              this.finalOptions +
              checked_true;

            let S_name2 =
              this.$store.storeName +
              "/" +
              this.$route.params.id +
              this.finalOptions;
            console.log("S_name " + S_name2);

            let qun = this.quanity;
            let min_qun = this.min_quanity;
            let max_qun = this.max_quanity;

            let AllCookies = this.$cookies.keys();
            for (let i = 0; i < AllCookies.length; i++) {
              if (AllCookies[i].includes(S_name2)) {
                // if (AllCookies[i] == S_name_full) {
                //  console.log("hii i am there ");
                //} else {
                  this.old_qnt=this.$cookies.get(AllCookies[i]).quantity;
                qun = qun - this.$cookies.get(AllCookies[i]).quantity;
                min_qun = min_qun- this.$cookies.get(AllCookies[i]).quantity;
             if(min_qun<0)
             {
              min_qun=1;
             }
             if( max_qun!=-1)
           max_qun = max_qun- this.$cookies.get(AllCookies[i]).quantity;
           if(max_qun==-1)
           {
            max_qun=-1;
           } 
           else if(max_qun<=0&& max_qun!=-1)
           {
            max_qun=0;
           } 

                console.log(
                  this.$cookies.get(AllCookies[i]).quantity + " quuuuunx " + qun
                );
                // }
              }
            }
            this.quanity = qun;
            this.max_quanity = max_qun;
            this.min_quanity = min_qun;
            

//test
if(this.min_quanity>0)
         {
          this.counterVal=this.min_quanity;
          this.price *= this.counterVal;
          this.offer_price *= this.counterVal;
         }
         else
              {
          this.counterVal=1;
          this.price *= this.counterVal;
          this.offer_price *= this.counterVal;
              } 

//
this.loading_price=false;
          });
      }
    },
    updateall() {
      this.loading = true;
      this.selectOption = false;
      this.counterVal = 1;
      this.options = [];
      this.selectedOptionArray = [];
      this.price = 0;
      this.offer_price = 0;
      this.quanity = 0;
      this.min_quanity = 0;
      this.max_quanity = 0;
      this.Firstquanity = 0;
      this.finalOptions = 0;
      this.checked = [];
      //this.validateMessage = "";

      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/product/theme5/" +
            this.$route.params.id,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          this.checked = [];
          this.DBitems = response.data.data;
          if(this.DBitems.product.seo_title)
      document.title = this.DBitems.product.seo_title;
    else
    document.title = this.DBitems.product.name;
      if(this.DBitems.product.seo_description)
      document.getElementById("description_meta").content=this.DBitems.product.seo_description;
      if(this.DBitems.product.seo_keyword)
      document.getElementById("keywords_meta").content=this.DBitems.product.seo_keyword;
            this.images=response.data.data.images.images;

          this.fav = this.DBitems.product.is_favorit;
          var option1 = this.DBitems.options.options;
          for (var i = 0; i < option1.length; i++) {
            //if option  not empty
            if (option1[i].option_values.select_values != null) {
              // if select
              var element = {};
              element.value_id = option1[i].option_values.select_values[0].id;
              element.option_id = option1[i].id;
              this.options.push(element);
            } else {
              // if checkbox
              var checked = {};
              checked.id = option1[i].id;
              checked.value = false;
              this.checked.push(checked);
              //console.log(this.checked)
            }
          }
          //console.log(this.options)
        })
        .then(() => {
          this.loading = false;
          if (this.options.length != 0) {
            this.selectOption = true;
         
          } else if (this.options.length == 0) {
            // if we not found select options
            console.log(
              "hiiiiiiiiii updateall" + this.DBitems.product.sale_price
            );
            this.price = this.DBitems.product.sale_price;
            this.offer_price = this.DBitems.product.offer_price;
            this.min_quanity=1;
            if(this.DBitems.product.min_qnt)
            this.min_quanity = this.DBitems.product.min_qnt;

            this.max_quanity = this.DBitems.product.quantity;
            if(this.DBitems.product.max_qnt>0)
            this.max_quanity = this.DBitems.product.max_qnt;

            this.quanity = this.DBitems.product.quantity;
            this.Firstquanity = this.DBitems.product.quantity;

            /////////////nottttttttttttttttttteeeeeeeeeeeeeeeeeeee////////////
            var checked_true = [];
            for (var i = 0; i < this.checked.length; i++) {
              if (this.checked[i].value == true) {
                checked_true.push(this.checked[i].id);
              }
            }
            let S_name_full =
              this.$store.storeName +
              "/" +
              this.$route.params.id +
              this.finalOptions +
              checked_true;

            let S_name2 =
              this.$store.storeName +
              "/" +
              this.$route.params.id +
              this.finalOptions;
            console.log("S_name " + S_name2);

            let qun = this.quanity;
            let min_qun = this.min_quanity;
            let max_qun = this.max_quanity;

            let AllCookies = this.$cookies.keys();
            for (let i = 0; i < AllCookies.length; i++) {
            if (AllCookies[i].includes(S_name2)) {
                
            console.log(
                   " qnt " + qun
                  +'<br> qnt_cookies '+this.$cookies.get(AllCookies[i]).quantity
                  +"<br> max_qnt "+max_qun
                   +"<br> max_qnt cookies "+max_qun
                );

                  this.old_qnt=this.$cookies.get(AllCookies[i]).quantity;
                qun = qun - this.$cookies.get(AllCookies[i]).quantity;
                min_qun = min_qun- this.$cookies.get(AllCookies[i]).quantity;
             if(min_qun<0)
             {
              min_qun=1;
             }
             if( max_qun!=-1)
           max_qun = max_qun- this.$cookies.get(AllCookies[i]).quantity;
           if(max_qun==-1)
           {
            max_qun=-1;
           } 
           else if(max_qun<=0&& max_qun!=-1)
           {
            max_qun=0;
           } 
                //this.$cookies.get(AllCookies[i]).quantity;
                console.log(
                  this.$cookies.get(AllCookies[i]).quantity + " quuuuuny  " + qun
                  +"max_qnt "+max_qun
                );
                // }
              }
              
            }
            this.quanity = qun;
            this.min_quanity = min_qun;
            this.max_quanity = max_qun;

            if(this.min_quanity>0)
         {
          this.counterVal=this.min_quanity;
          this.price *= this.counterVal;
          this.offer_price *= this.counterVal;
         }
          }
        });
        var collections = document.getElementsByClassName("select_value_product");
        //option_value_radio
        for (var i = 0; i < collections.length; i++) {
          console.log("iii "+i)
            if(collections[i])
            collections[i].classList.remove('active')
          }
          var collections2 = document.getElementsByClassName("option_value_radio");
        //option_value_radio
        for (var i = 0; i < collections2.length; i++) {
          console.log("iii2 "+i)
            if(collections2[i])
            collections2[i].checked=false;
          }
    },
    addToCart() {
    
      this.loading_checkout=true;
      document.getElementById("#addToCart").style.display = "none";
      var checked_true = [];
      for (var i = 0; i < this.checked.length; i++) {
        if (this.checked[i].value == true) {
          checked_true.push(this.checked[i].id);
        }
      }
      this.axios
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/check_cart_item",
          {
            product_id: this.$route.params.id,
            total_price: this.price,
            offer_price: this.offer_price,
            quantity: Number(this.counterVal),
            old_qnt:this.old_qnt,
            final_option: this.finalOptions,
            option_check: checked_true
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          let product = null;
          this.loading_checkout=false;
          if (response.data.code == "1") {
            // let products = new Map;
            let S_name1 =
              this.$store.storeName +
              "/" +
              this.$route.params.id +
              this.finalOptions +
              checked_true;
            if (this.$cookies.isKey(S_name1)) {
console.log("$$$$$$$$$$",this.price,"$$$$$$$$$$$$$$$");
              product = {
                product_id: this.$route.params.id,
                total_price:
                  this.price + this.$cookies.get(S_name1).total_price,
                offer_price:
                  this.offer_price + this.$cookies.get(S_name1).offer_price,
                quantity: Number(this.counterVal) + Number(this.$cookies.get(S_name1).quantity),
                quantityAll: this.$cookies.get(S_name1).quantityAll,
                max_quantity: this.$cookies.get(S_name1).max_quantity,
                min_quantity: this.$cookies.get(S_name1).min_quantity,
                final_option: this.finalOptions,
                option_check: checked_true,
                discount: this.DBitems.product.discount,
                note: this.note + " " + this.$cookies.get(S_name1).note
              };
            } else {
              console.log("hlooooooooooo ::::::::; " + this.Firstquanity);
              product = {
                product_id: this.$route.params.id,
                total_price: this.price,
                offer_price: this.offer_price,
                quantity: this.counterVal,
                quantityAll: this.Firstquanity,
                min_quantity: this.min_quanity,
                max_quantity: this.max_quanity,
                final_option: this.finalOptions,
                option_check: checked_true,
                discount: this.DBitems.product.discount,
                note: this.note
              };
            }
            //check if item is exist on cart or not
            //if (this.$cookies.isKey(this.$store.storeName+this.$route.params.id)) {
            // this.$cookies.remove('storeName')
            // products = this.$cookies.get(this.$store.storeName);
            //}
            //products.set(this.$route.params.id , product);
            let S_name = this.$store.storeName + "/";

            if (
              !this.$cookies.isKey(
                S_name +
                  product.product_id +
                  product.final_option +
                  product.option_check
              )
            ) {
              this.$store.state.cartCounter = this.$store.state.cartCounter + 1;
            }

            this.$cookies.set(
              S_name +
                product.product_id +
                product.final_option +
                product.option_check,
              product
            );
            console.log(this.$cookies.keys());
          }
          this.validateMessage = response.data.message;
          /*this.$router.push({
            name: "shoppingCart",
            params: {
              storeName: this.$store.storeName,
            },
          });*/
          this.updateall();
          document.getElementById("#addToCart").style.display = "block";
          setTimeout(() => {
            // this.updateall();
            this.validateMessage = "";
          }, 3000);
        });

    },
    addEvalu() {
      if (
        this.$localStorage.get(this.$store.storeName) != null &&
        this.DBitems.product.is_rate == true
      ) {
        this.addeval = true;
      } else if (
        this.$localStorage.get(this.$store.storeName) != null &&
        this.DBitems.product.is_rate == false
      ) {
        if (this.$i18n.locale == "en") {
          document
            .getElementsByClassName("hovertext")[0]
            .setAttribute(
              "data-hover",
              "You must purchase the product to add a review"
            );
        } else {
          document
            .getElementsByClassName("hovertext")[0]
            .setAttribute("data-hover", "يجب أن تشتري المنتج لإضافة تقييم");
        }
      } else {
        if (this.$i18n.locale == "en") {
          document
            .getElementsByClassName("hovertext")[0]
            .setAttribute("data-hover", "login to add evaluation");
        } else {
          document
            .getElementsByClassName("hovertext")[0]
            .setAttribute("data-hover", "سجل دخول لإضافة تقييم");
        }
      }
    },
    changeFav() {
      if (this.$localStorage.get(this.$store.storeName) != null) {
        this.axios
          .get(
            this.$store.state.base_api_url +
              this.$store.storeName +
              "/favourite_product_change/" +
              this.$route.params.id,
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
                Authorization:
                  "Bearer " + this.$localStorage.get(this.$store.storeName)
              }
            }
          )
          .then(response => {
            if (response.data.code == "1") {
              this.fav = !this.fav;
            }
          });
      } else {
        if (this.$i18n.locale == "en") {
          this.loginMassage = "please Login";
          setTimeout(() => {
            this.loginMassage = "";
          }, 3000);
        } else {
          this.loginMassage = "يجب تسجيل الدخول";
          setTimeout(() => {
            this.loginMassage = "";
          }, 3000);
        }
      }
    },
    changeCheckbox(item, val) {
      if (val) {
        this.price = this.price + item.price * this.counterVal;
        if (this.offer_price != 0) {
          this.offer_price = this.offer_price + item.price * this.counterVal;
        }
      } else {
        /*(this.offer_price =
            this.price - (this.DBitems.product.discount / 100) * this.price))*/
        this.price = this.price - item.price * this.counterVal;
        if (this.offer_price != 0) {
          this.offer_price = this.offer_price - item.price * this.counterVal;
        }
        /*(this.offer_price =
            this.price - (this.DBitems.product.discount / 100) * this.price));*/
      }
    },
    show_section(id)
  {
  
      document.getElementById('description').style="display:none";
      document.getElementById('addinfo').style="display:none";
      document.getElementById('review').style="display:none";

      document.getElementById('description-tab').style="color:black;background-color:transparent";
      document.getElementById('addinfo-tab').style="color:black;background-color:transparent";
      document.getElementById('review-tab').style="color:black;background-color:transparent";

    document.getElementById(id).style="display:block !important";
    document.getElementById(id+'-tab').style="color:white;background-color: var(--primary-color);";

  }
  },
  watch: {
    "$route.params": {
      handler(newValue) {
        this.Dbitems=null;
        this.updateall();
      },
      immediate: true
    }
  }
};
</script>
<style>

</style>