<template>
    <section>
        <div style="min-height: 220px; background-color: white;"></div>
        <div style="min-height: 220px; background-color: var(--primary-color);"></div>
        <div class="card_download text-center">
            <h2>{{ $t('download now') }}</h2>
            <p>
                {{ $t('download terms') }}
            </p>
            <br><a class="btn_download" href=""><i class="bi bi-download"></i> {{ $t('download') }}</a><br>
        </div>
    </section>
</template>

<script>
export default {
    name: "Dowloand Product",
    data: function () {
        return {
            loading: false,
            DBitems: null,
        };
    },
    created() {
        this.loading = true;

            this.axios.get(this.$store.state.base_api_url + "dowenload_product/order/"
                + this.$route.params.order_id + "/" + this.$route.params.id,
                { headers: { "Accept-Language": this.$i18n.locale } })
                .then((t => { this.DBitems = t.data.data })).then((() => this.loading = false));
                if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }

    },
    methods: {
        updatedata() {
            this.loading = true;

this.axios.get(this.$store.state.base_api_url + "dowenload_product/order/"
    + this.$route.params.order_id + "/" + this.$route.params.id,
    { headers: { "Accept-Language": this.$i18n.locale } })
    .then((t => { this.DBitems = t.data.data })).then((() => this.loading = false));
    if(this.$i18n.locale=='ar')
{
document.title = this.$store.state.title_ar;
document.getElementById("description_meta").content=this.$store.state.description_ar;
document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
}
else{
document.title = this.$store.state.title_en;
document.getElementById("description_meta").content=this.$store.state.description_en;
document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
}
        },
    },
};
</script>

<style>
.card_download {
    padding: 4% 10px;
    position: absolute;
    top: 25%;
    left: 25%;
    background-color: #f7f8fb;
    opacity: 1;
    width: 800px;
    height: 200px;
}

.btn_download, .btn_download:hover {
    background-color: var(--primary-color);
    color: #fff !important;
    padding: 10px 20px;
    border-radius: 15px;
}
</style>