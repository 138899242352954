<template>
    <div class="container contactus_page" style="margin-top: 10%;min-height:600px">
      <div
        v-if="loading"
        class="d-flex flex-row justify-content-center"
        style="margin-top: 100px; margin-bottom: 200px"
      >
        <hollow-dots-spinner
          :animation-duration="1000"
          :dot-size="35"
          :dots-num="3"
          color="var(--primary-color)"
        />
      </div>
        <div  v-else class="row">
            <div class="col-lg-7 col-md-7 col-12 contact_form" style="padding-left: 6%;">
                <span style="font-size: 35px; font-weight: bold;"> {{$t('Get in')}} </span>
                <span style="color: var(--primary-color); font-weight: bold; font-size: 35px;">{{$t('Touch')}}</span>
                <p style=" padding-top: 3% ; font-size: 22px; font-weight: 500;">
                    {{$t('Any question or remarks? Just write us a message!')}}</p>


                    <form @submit.prevent="submitmessage" @keydown="form.onKeydown($event)">

                             
                            <input class="input_all" type="text"  
                            :placeholder="$t('name')+' *'"
                             v-model="form.name"
                                required  >
                
                            <br>
                            <input class="input_all" type="Email" 
                            :placeholder="$t('Email')+' *'"
                             v-model="form.email"
                                     required
                            >
                            <br>
                           
                            <input class="input_all" type="text" 
                            :placeholder="$t('Phone')+'*'"
                             v-model="form.phone"
                              required>
                            <br>
                            <input class="input_all" type="text" 
                            :placeholder="$t('message')+'*'"
                             v-model="form.message"
                              required
                              style="line-height: 5;" >
                            <br>
                            
                             <button class="center_align butt_green" 
                             style="margin-top: 7%;
                              padding: 2% 8%;"
                              :disabled="form.busy"
                              >{{ $t("SendMessage") }}</button>
                               </form>
                               <div style="position: fixed; width: 500px; top: 40%; left: 10px">
            <AlertSuccess :form="form" :message="$t('Message1')" />
            <AlertErrors :form="form" :message="$t('Message0')" />
          </div>


            </div>
            <div class="col-lg-5 col-md-5 col-12" >

                <iframe
                v-if="this.$store.state.apperance_map==1"
                :src="
            'https://maps.google.com/maps?q=' +
            DBitems.social.lat +
            ',' +
            DBitems.social.long +
            '&t=&z=15&ie=UTF8&iwloc=&output=embed'
          "
                class="frame_map" style="border:0;" allowfullscreen="" loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
                <div v-else class="frame_map">
                  <img :src=" DBitems.social.image" style="height: 450px; width:500px ;object-fit:cover"/>
                </div>
       <div style="padding-bottom: 2%; padding-top: 5%;" class="contact_us_div">
                    <img src="/theme10/images/Group (2).png" style="width: 4%;">
                    <span class="contact_us_span">{{this.DBitems.social.email  }}</span>
                </div>
                <div v-if="DBitems.social.phone" style="padding-bottom: 2%;" class="contact_us_div">
                    <img src="/theme10/images/Vector (1).png" style="width: 3%;">
                    <span class="contact_us_span" dir="ltr">{{ this.DBitems.social.phone }}</span>
                </div>
                <div v-if="DBitems.social.other_phone" style="padding-bottom: 2%;" class="contact_us_div">
                    <img src="/theme10/images/Vector (1).png" style="width: 3%;">
                    <span class="contact_us_span">{{ this.DBitems.social.other_phone }}</span>
                </div>

                <div v-if="DBitems.social.address" style="padding-bottom: 5%;" class="contact_us_div">
                    <img src="/theme10/images/Vector (2).png" style="width: 3%;">
                    <span class="contact_us_span">{{this.DBitems.social.address}}</span>
                </div> 
            </div>
        </div>
      

    </div>
    </template>

<script>
import Form from "vform";
import { AlertErrors, AlertSuccess } from "vform/src/components/bootstrap5";
import { HalfCircleSpinner, HollowDotsSpinner } from "epic-spinners";


export default {

  components: {
    AlertErrors,
    AlertSuccess,
    HollowDotsSpinner,
     HalfCircleSpinner
  },
  data: function() {
    return {
      loading: false,
      DBitems: null,

      form: new Form({
        name: null,
        email: null,
        message: null,
        phone:null
      }),

      marker: { position: {  
        lat: 51.677878,
          lng: 30.67676 } },
      center: { lat: 51.677878,
          lng: 30.67676 },
      mapOptions: {
        disableDefaultUI: true
      }
    };
  },
  created() {
    if(this.$route.params.lang&&this.$route.params.lang!=this.$i18n.locale){
      this.$i18n.locale=this.$route.params.lang;this.$store.state.UnitPrice=this.$store.state.unitAR
      if(this.$i18n.locale=='en')
        {document.documentElement.setAttribute("dir", "ltr");
        let h = document.querySelector('body');
      }
    }
    this.loading = true;
    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/contact_us",
        {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        }
      )
      .then(response => {
        this.DBitems = response.data.data;
        this.marker.position = {
          lat: this.DBitems.social.lat,
          lng: this.DBitems.social.long
        };
        this.center = {
          lat: this.DBitems.social.lat,
          lng: this.DBitems.social.long
        };
      })
      .then(() => (this.loading = false));
      if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
  },
  methods: {
    updatedata() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/contact_us",
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          this.DBitems = response.data.data;
        })
        .then(() => (this.loading = false));
      this.$store.state.langchange = false;
    },
    async submitmessage() {
      const response = await this.form
        .post(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/send_message",
          {
            message: this.form.message,
            name: this.form.name,
            email: this.form.email
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale
            }
          }
        )
        .then(response => {
          if (response.data.code == "1") {
            this.form.message = "";
            this.form.name = "";
            this.form.email = "";
            console.log(response.data.message);
          }
          if (response.data.error) {
            this.errorMessage = response.data.message;
          }
        });
    },

    //sets the position of marker when dragged
    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },
    //Moves the map view port to marker
    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position);
      this.$refs.mapRef.setZoom(18);
    },
    //Moves the marker to click position on the map
    handleMapClick(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      console.log(e);
    }
  }
};
</script>