<template>
      <!-- Start Hero -->
      <section class="relative lg:py-24 py-16 bg-slate-50 dark:bg-slate-800">
            <div class="container relative">
                <div class="md:flex justify-center mt-24">
                    <div class="lg:w-4/5 w-full">
                        <div class="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                            <div class="border-b border-gray-100 dark:border-gray-700 pb-6">
                                <div class="md:flex justify-between">
                                    <div>
                                        <img 
                                        :src="this.$store.state.logo"  
                                        :alt="this.$store.storeName"
                                        class="block dark:hidden">
                                        <img 
                                        :src="this.$store.state.logo"  
                                        :alt="this.$store.storeName"
                                         class="hidden dark:block" >
                                        <div class="flex mt-4">
                                            <i data-feather="link" class="h-4 w-4 me-3 mt-1"></i>
                                            <a href="https://www.urbandesigns.qa.com" 
                                            class=" dark:text-white font-medium"
                                            style="color:var(--primary-color)"
                                            >www.urbandesigns.qa.com</a>
                                        </div>
                                    </div>

                                    <div class="mt-6 md:mt-0 md:w-56">
                                        <h5 class="text-lg font-semibold">{{ $t('Address') }}:</h5>

                                        <ul class="list-none">
                                            <li v-if="social.address" class="flex mt-3">
                                                <i v-html="map_pin" 
                                                class="h-4 w-4 me-3 mt-1"></i>
                                                <a :href="'https://maps.google.com/maps?q='+social.lat+','+social.long+'&hl=es&z=14&amp;output=embed'"
                                                data-type="iframe" class="lightbox text-slate-400">
                                               {{ social.address }}
                                               </a>
                                            </li>
                                           
                                            <li class="flex mt-3">
                                                <i v-html="mail" class="h-4 w-4 me-3 mt-1"></i>
                                                <a :href="'mailto:'+social.email"
                                                 class="text-slate-400">{{ social.email }}</a>
                                            </li>
                                            
                                            <li class="flex mt-3">
                                                <i v-html="phone" 
                                                class="h-4 w-4 me-3 mt-1"></i>
                                                <a dir="ltr" :href="'tel:'+social.phone" 
                                                class="text-slate-400">{{ social.phone }}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="md:flex justify-between">
                                <div class="mt-6">
                                    <h5 class="text-lg font-semibold">{{ $t('Invoice Details') }} :</h5>

                                    <ul class="list-none">
                                        <li class="flex mt-3">
                                            <span class="w-24">{{ $t('Invoice No') }} :</span>
                                            <span class="text-slate-400">#{{ DBitems.invoice_id }}</span>
                                        </li>
                                        <li class="flex mt-3">
                                            <span class="w-24">{{ $t('status') }} :</span>
                                            <span class="text-slate-400">{{ DBitems.status }}</span>
                                        </li>
                                        <li  class="flex mt-3">
                                                    <span class="w-24">{{ $t('cancel') }} :</span>
                                                    <span class="text-slate-400">
                <button
                  type="button"
                  class="btn btn-primary text-capitalize border-0"
                  style="background-color:#bb0505;color:white;padding:2px 8px; border-raduis:10%"
                  @click="show = true"
                >{{ $t("cancelOrder") }}</button>
            </span>
              
                                                </li>
                                        
                                        <li class="flex mt-3">
                                            <span class="w-24">{{ $t('name') }} :</span>
                                            <span class="text-slate-400">{{ DBitems.user.name }}|{{ DBitems.user.email }}</span>
                                        </li>
                                        
                                        <li v-if="DBitems.address.region" class="flex mt-3">
                                            <span class="w-24">{{ $t('Address') }} :</span>
                                            <span class="text-slate-400">
                                              {{ DBitems.address.country}} 
                                                {{ DBitems.address.city }} 
                                                 {{  DBitems.address.region}}
                                                 {{  DBitems.address.street}}
                                                 
                                                 <br> {{ DBitems.address.other_details }}</span>
                                        </li>
                                        
                                        <li class="flex mt-3">
                                            <span class="w-24">{{ $t('Phone') }} :</span>
                                            <span class="text-slate-400">{{ DBitems.user.phone }}</span>
                                        </li>

                                        <li class="flex mt-3">
                                            <span class="w-24">{{ $t("paymentType") }} :</span>
                                            <span class="text-slate-400">{{ DBitems.payment_type }}</span>
                                        </li>
                                        <li class="flex mt-3">
                                            <span class="w-24">{{ $t("paymentStatus") }} :</span>
                                            <span class="text-slate-400">{{ DBitems.payment }}</span>
                                        </li>
                                        <li v-if="DBitems.bank_account" class="flex mt-3">
                                            <span>{{ $t("account bank deatiles") }} :</span>
                                               </li>
                                            <li v-if="DBitems.bank_account" class="flex mt-3">
                                            <p class="text-slate-400"> {{$t('account_name')}}:  {{ DBitems.bank_account.account_name }}</p>
                                            </li>
                                            <li v-if="DBitems.bank_account" class="flex mt-3">
                                            <p class="text-slate-400">  {{$t('account_number')}}:  {{ DBitems.bank_account.account_number }}</p>
                                           </li>
                                           <li v-if="DBitems.bank_account" class="flex mt-3">
                                            <p class="text-slate-400">  {{$t('iban')}}: {{ DBitems.bank_account.iban }}</p>
                                            </li>
                                            <li v-if="DBitems.bank_account" class="flex mt-3">
                                            <p class="text-slate-400">  {{$t('address')}}: {{ DBitems.bank_account.address }}</p>

                                          </li>
                                        <li v-if="DBitems.count_month!=null" class="flex mt-3">
                                            <span class="w-24">{{ $t("count_month") }} :</span>
                                            <span class="text-slate-400"> {{ DBitems.count_month }}   {{ $t('months') }}</span>
                                        </li>

                                        <li 
                                        v-if="DBitems.note!=null"
                                        class="flex mt-3">
                                            <span class="w-24">{{ $t('notes') }} :</span>
                                            <span class="text-slate-400">{{ DBitems.note }}</span>
                                        </li>

                                    </ul>
                                </div>

                                <div class="mt-3 md:w-56">
                                    <ul class="list-none">
                                        <li class="flex mt-3">
                                            <span class="w-24">{{ $t('Date') }} :</span>
                                            <span class="text-slate-400">{{ DBitems.date }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                            <div class="relative overflow-x-auto shadow dark:shadow-gray-800 rounded-md mt-6">
                                <table class="w-full text-start text-slate-500 dark:text-slate-400">
                                    <thead class="text-sm uppercase bg-slate-50 dark:bg-slate-800">
                                        <tr>
                                            <th scope="col" class="text-center px-6 py-3 w-16">
                                                {{ $t('.No') }}
                                            </th>
                                            <th scope="col" class="text-start px-6 py-3">
                                                {{ $t('items') }}
                                            </th>
                                            <th scope="col" class="text-center px-6 py-3 w-20">
                                                {{ $t('Qty') }}
                                            </th>
                                            <th scope="col" class="text-center px-6 py-3 w-28">
                                                {{ $t('Rate') }}
                                            </th>
                                            <th scope="col" class="text-end px-6 py-3 w-20">
                                                {{ $t('total') }}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr 
                                        v-for="(item, index) in DBitems.order_items.items" :key="index"
                                        class="bg-white dark:bg-slate-900">
                                            <td class="text-center px-6 py-4">
                                                {{ index+1 }}
                                            </td>
                                            <th scope="row" class="text-start px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                                                {{ item.product }}
                                                <br>
                                                <p
                          v-for="(item1, i) in item.options"
                          :key="i"
                        >{{ item1.option }} : {{ item1.value }}</p>
                        <br>
                        <p v-for="j in item.order_details" :key="j">{{ j }}</p>
                                            </th>
                                            <td class="text-center px-6 py-4">
                                                {{ item.quantity }} 
                                            </td>
                                            <td class="text-center px-6 py-4">
                                                {{ item.total_price.toFixed(2)/item.quantity}} {{ unit }}
                                            </td>
                                            <td class="text-end px-6 py-4">
                                                {{ item.total_price.toFixed(2)}} {{ unit }}
                                            </td>
                                        </tr>
                                       
                                    </tbody>
                                </table>
                            </div>

                            <div class="w-56 ms-auto p-5">
                                <ul class="list-none">
                                    <li class="text-slate-400 flex justify-between">
                                        <span>{{ $t('subTotal') }} :</span>
                                        <span>{{ DBitems.total_price.toFixed(2) }} {{unit}}</span>
                                    </li>
                                    <li v-if="DBitems.discount!=0" class="text-slate-400 flex justify-between">
                                        <span>{{ $t("discount") }} ({{ DBitems.discount }}%) :</span>
                                        <span>{{ DBitems.discount_price.toFixed(2) }} {{unit}}</span>
                                    </li>

                                    <li v-if="DBitems.delivery_cost!=0"
                                     class="text-slate-400 flex justify-between">
                                        <span>{{ $t("deliveryPrice") }} :</span>
                                        <span>{{ DBitems.delivery_cost }}{{unit}}</span>
                                    </li>

                                   
                                    
                                    <li v-if="DBitems.tax!=0" 
                                    class="text-slate-400 flex justify-between mt-2">
                                        <span>{{ $t('tax') }}  ({{ DBitems.tax }}% ):</span>
                                        <span>{{ DBitems.tax_price.toFixed(2)}} {{ unit }}</span>
                                    </li>
                                    <li class="flex justify-between font-semibold mt-2">
                                        <span>{{ $t('total') }} :</span>
                                        <span>{{DBitems.final_price}} {{unit }}</span>
                                    </li>
                                </ul>
                            </div>

                            <div class="invoice-footer border-t border-gray-100 dark:border-gray-700 pt-6">
                                <div class="md:flex justify-between">
                                    <div>
                                        <div class="text-slate-400 text-center md:text-start">
                                            <h6 class="mb-0">{{ $t('Customer Services') }} : 
                                                <a :href="'tel:'+social.phone" 
                                                class="text-amber-500">{{ social.phone }}</a></h6>
                                        </div>
                                    </div>

                                    <div class="mt-4 md:mt-0">
                                        <div class="text-slate-400 text-center md:text-end">
                                            <h6 class="mb-0">
                                                <router-link
                                                 :to="{
                                              name: 'privacy',
                                           params: { storeName: this.$store.storeName,lang:$store.state.routeLang },
                                                 }"
                                                  class="title">
                                                  {{ $t('Terms And Condition') }}
                                                  </router-link></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!--end grid-->
            </div><!--end container-->


            <Transition name="modal">
      <div v-if="show" class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header">
              <slot name="header">{{$t('confirmCancel')}}</slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button"
                 @click="cancelOrder">{{$t('confirm')}}</button>
                <button
                  class="modal-default-button"
                  @click="(show = false), $emit('close')"
                >{{$t('cancel')}}</button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <div
      v-if="Message != ''"
      style="
        position: fixed;
        width: 200px;
        bottom: 50px;
        left: 50px;
        border-radius: 5px;
        border: 2px solid var(--primary-color);
        text-align: center;
        padding: 5px;
        color:white;
        background-color: var(--primary-color);
      "
    >
      <p style="margin: 0">{{ Message }}</p>
    </div>
        </section><!--end section-->
        <!-- End Hero -->

</template>


<script>

export default {
  data: function() {
    return {
      DBitems: null,
      loading: false,
      show: false,
      social: null,
      unit:'QAR',
      Message:""
    };
  },
  computed: {
    mail: function () {
      return feather.toSvg('mail')
    },
    phone: function () {
      return feather.toSvg('phone')
    },
    map_pin: function () {
      return feather.toSvg('map-pin')
    },
  },
  created() {
    this.$store.state.topbar='topbar_light';
    this.$store.state.navbar='navbar_dark ';
    this.loading = true;
    this.unit=this.$store.state.unit;
    if(this.$i18n.locale == 'ar')
    {
        this.unit=this.$store.state.unitAR;
    }
    else
      {
        this.unit=this.$store.state.unit;
    }

    this.axios
      .get(
        this.$store.state.base_api_url + this.$store.storeName + "/contact_us",
        {
          headers: {
            "Accept-Language": this.$i18n.locale
          }
        }
      )
      .then(response => {
        this.social = response.data.data.social;
       
    
      })


    this.axios
      .get(
        this.$store.state.base_api_url +
          this.$store.storeName +
          "/orders/" +
          this.$route.params.id,
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName)
          }
        }
      )
      .then(response => {
        if (response.data.code == "1") {
          this.DBitems = response.data.data.order;
        } else {
          console.log("errorrr");
        }
      })
      .then(() => (this.loading = false))
      .catch(error => {
        if (error.response.status == "401") {
          this.$localStorage.remove(this.$store.storeName);
          window.location.href = "/";
        }
      });
      if(this.$i18n.locale=='ar')
      {
      document.title = this.$store.state.title_ar;
      document.getElementById("description_meta").content=this.$store.state.description_ar;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_ar;
      }
      else{
        document.title = this.$store.state.title_en;
      document.getElementById("description_meta").content=this.$store.state.description_en;
      document.getElementById("keywords_meta").content=this.$store.state.keyword_en;
      }
  },
  methods: {
    updatedata() {
      this.unit=this.$store.state.unit;
    if(this.$i18n.locale == 'ar')
    {
        this.unit=this.$store.state.unitAR;
    }
    else
      {
        this.unit=this.$store.state.unit;
    }
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/orders/" +
            this.$route.params.id,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.code == "1") {
            this.DBitems = response.data.data.order;
          } else {
            console.log("errorrr");
          }
        })
        .then(() => (this.loading = false))
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href="/";
          }
        });

      //this.$refs.childref.update(this.categoryID)
      this.$store.state.langchange = false;
    },

    cancelOrder() {
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url +
            this.$store.storeName +
            "/order/cancel/" +
            this.$route.params.id,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName)
            }
          }
        )
        .then(response => {
          if (response.data.code == "1") {
            this.Message=response.data.message;
          } else {
            this.Message=response.data.message;
          }
        setTimeout(() => {
    this.Message = 'false';
    },300)
        })
        .then(
          () => (this.updatedata(), (this.show = false), (this.loading = false))
        )
        .catch(error => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href="/";
          }
        });
    },
    
    printInvoice() {
      let head = `
      <head>
<link href="https://etrolley.net/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
    <link href="https://etrolley.net/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
    <link href="https://etrolley.net/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
    <link href="https://etrolley.net/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
    <link href="https://etrolley.net/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
    <link href="https://etrolley.net/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
    <link href="https://etrolley.net/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">
    <link href="https://etrolley.net/assets/css/style.css" rel="stylesheet">
    <title>${this.$store.storeName}</title>
    <style>
    .btn {
    display: none !important;
  }
  .col-md-6 {
    width: 50% !important;
  } 
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
}
.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
    
    padding-right:20px
    padding-left: 20px
   
}
  .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
   
    }
    .list-unstyled {
    padding-left: 0;
    list-style: none;
}
/*.table-wrapper-scroll-y{
    display: block;
}
.my-custom-scrollbar{
    position: relative;
    max-height: 700px;
    overflow-x: auto;
    overflow-y: auto;
}*/
.table {
    --bs-table-color: var(--bs-body-color);
    --bs-table-bg: transparent;
    --bs-table-border-color: var(--bs-border-color);
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: var(--bs-body-color);
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: var(--bs-body-color);
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: var(--bs-body-color);
    --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    width: 100%;
    margin-bottom: 1rem;
    color: var(--bs-table-color);
    vertical-align: top;
    border-color: var(--bs-table-border-color);
    caption-side: bottom;
    border-collapse: collapse;
}
.table > thead {
    vertical-align: bottom;
}
thead, tbody, tfoot, tr, td, th {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}
thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
}
.table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
  .text-center {
    text-align: center !important;
}
.printLogo{
position: absolute;
    top: 0;
    left: 10px;
}
  </style>
  </head>
      `

      let body=``
      let invoice = document.getElementById("invoice").outerHTML
      if (this.$i18n.locale == "ar") {
        body=`<body dir='rtl'>
        ${ invoice }</body></html>`;
      } else {
         body=`<body dir='rtl'>
        ${ invoice }</body></html>`;
      }



      var mywindow = window.open("", "PRINT");

      mywindow.document.write("<html>");

      mywindow.document.
      //getElementsByTagName('head').
      write(head) ;
      mywindow.document.write(body);
      
      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();
      mywindow.close();
    },

    Payment() {
      window.location.href = this.DBitems.link;
    }
  }
};
</script>