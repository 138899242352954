<template>
  <section
    id="Second"
  >
    <div class="container" v-if="items.length != 0">
      <div
        class="d-flex flex-row justify-content-between align-items-center mb-5"
      >
        <div class="section-title" style="padding-bottom: 0">
          <h2>{{ $t("confirmOrder") }}</h2>
        </div>
        <div class="card2 text-center" style="width: auto; margin: 0">
          <h4>
            {{ $t("CartProducts") }}
            <span style="color: #9c0008">{{ items.length }}</span>
          </h4>
        </div>
      </div>
      <div class="row">
        <div
          class="collg4 col-lg-4 col-md-6 mb-4"
          v-for="item in this.items"
          :key="item"
        >
          <card5 :item-id="item"></card5>
        </div>
      </div>




      <div class="row mt-4  check_out_page">
        


        <div class="col-lg-4 mb-4 information">
          <div v-if="showMonthes" class="mt-2">
            <h4 style="margin-bottom: 30px">{{ $t("select_count_month") }}</h4>
            <select
              id="monthsSelect"
              class="select"
              :title="$t('months')"
              v-if="DBitems.months.length != 0"
              @change="onChangeMonths($event)"
            >
              <option hidden selected value="SelectOne">
                {{ $t("SelectOne") }}
              </option>
              <option
                v-for="(item, index) in DBitems.months"
                :key="index"
                :value="item"
              >
                {{ item }} {{ $t("months") }}
              </option>
            </select>
          </div>

          <div class="mb-4 mt-2">
            <h5>{{ $t("Coupon") }}</h5>
            <div class="row">
              <div class="col-sm-6 mb-4">
                <input
                  type="text"
                  class="form-control code-input"
                  aria-label="Small"
                  aria-describedby="inputGroup-sizing-sm"
                  v-model="disCode"
                  :placeholder="$t('Couponcode')"
                />
                <span>
                  <i
                    style="color: #24b300; font-size: 15px; margin-left: -75%"
                    class="bi bi-check-circle-fill"
                  ></i>
                </span>
              </div>
              <div class="col-sm-6 mb-4">
                <button class="activate" @click="Couponcode()">
                  <h4>{{ $t("Activate") }}</h4>
                </button>
              </div>
            </div>
            <span>{{ couponMsg }}</span>
          </div>
          <div class="d-flex flex-row justify-content-between">
            <h5>{{ $t("subTotal") }} :</h5>
            <h5 v-if="$i18n.locale == 'ar'" style="color: #9c0008">
              {{ (PartialPrice*$store.state.rate).toFixed(2) }} {{ this.$store.state.UnitPrice }}
            </h5>
            <h5 v-else style="color: #9c0008">
              {{ (PartialPrice*$store.state.rate).toFixed(2) }} {{ this.$store.state.UnitPrice }}
            </h5>
          </div>
          <div
            v-if="this.discountPrice != 0"
            class="d-flex flex-row justify-content-between"
          >
            <h5>{{ $t("discount") }} ({{ discountPrice }} %) :</h5>
            <h5 v-if="$i18n.locale == 'ar'" style="color: #004e1f">
              {{ ((this.discountPrice * this.PartialPrice *$store.state.rate) / 100).toFixed(2) }}
              {{ this.$store.state.UnitPrice }}
            </h5>
            <h5 v-else style="color: #004e1f">
              {{ ((this.discountPrice * this.PartialPrice*$store.state.rate) / 100).toFixed(2) }}
              {{ this.$store.state.UnitPrice }}
            </h5>
         

          </div>

          <div
            v-if="this.selectDeliveryType == 5"
            class="d-flex flex-row justify-content-between"
          >
            <h5>{{ $t("deliveryPrice") }} :</h5>
            <h5  style="color: #9c0008">
              {{ (deliveryPrice*$store.state.rate).toFixed(2) }} {{ this.$store.state.UnitPrice }}
            </h5>
           
           
          </div>
          <div
            v-if="$store.state.tax != null"
            class="d-flex flex-row justify-content-between"
          >
            <h5>{{ $t("tax") }} ( {{ $store.state.tax }}% ):</h5>
            <h5  style="color: #004e1f">
              {{
                (
                  ((this.$store.state.tax *
                    (this.PartialPrice -
                      (this.discountPrice * this.PartialPrice) / 100 +
                      this.deliveryPrice)) /
                  100)*$store.state.rate
                ).toFixed(2)
              }}
              {{ this.$store.state.UnitPrice }}
            </h5>
          
          </div>
          <div class="d-flex flex-row justify-content-between">
            <h5 style="font-size: 18px">{{ $t("totalPrice") }} :</h5>
            <h5
              v-if="$i18n.locale == 'ar'"
              style="color: #000; font-size: 18px"
            >
              {{ (totalPrice*$store.state.rate).toFixed(2) }} {{ this.$store.state.UnitPrice }}
            </h5>
            <h5 v-else style="color: #000; font-size: 18px">
              {{ (totalPrice*$store.state.rate).toFixed(2) }} {{ this.$store.state.UnitPrice }}
            </h5>
          </div>
          <div>
            <h5>{{ $t("note") }} :</h5>
            <textarea
              rows="3"
              style="width: 100%"
              v-model="allOrderNote"
            ></textarea>
          </div>
        </div>
        <div class="col-lg-8 mb-4">
          <h4 style="margin-bottom:4%">{{ $t('Billing Details') }}</h4>
<div class="text-center">
  <button
    class="activate login_guest" @click="$router.push({
                                 name: 'login',
                                 params: {
                                 storeName: $store.storeName,
                                   lang: $i18n.locale
                                            },
                                   });">
      {{ $t('Already have an account? order guest') }}
  </button>
</div>
<br>

<div>
  <label>

    <input type="checkbox" @change="funshow_guest" />
    {{ $t('continue as guest') }}
  </label>
</div>
<br>

<div v-if="show_guest">
  
<div class="row">
<div >

</div>
<div class="col-lg-6">
  
    <h4>{{ $t("name") }} <i style="color: red">*</i></h4>
    <input
      id="name"
      class="form-input mt-2 
          w-full py-2 px-3 h-10 bg-transparent 
          dark:bg-slate-900 dark:text-slate-200 
          rounded outline-none border border-gray-100 
          dark:border-gray-800 focus:ring-0 address_input"
      type="text"
      name="name"
      autocomplete="false"
      v-model="user_name"
      required
      maxlength="255"
      minlength="3"
    />
  
</div>
<div class="col-lg-6">
    <h4>{{ $t("Email") }} <i style="color: red"></i></h4>
    <input
      class="form-input mt-2 
          w-full py-2 px-3 h-10 bg-transparent 
          dark:bg-slate-900 dark:text-slate-200 
          rounded outline-none border border-gray-100 
          dark:border-gray-800 focus:ring-0 address_input"
      type="email"
      name="email"
      autocomplete="false"
      v-model="email"
      required
      id="email"
    />
              <label v-if="this.$store.state.auth==0"label class="accept_account_email">

                <input type="checkbox" class="account_email_check" @change="funcreate_account_email" id="account_email"/>
                <span>{{ $t('create account') }}</span>
              </label>

  

</div>
<div class="col-lg-6">
  
    <h4>{{ $t("Phone") }} <i style="color: red">*</i></h4>
     <input
      id="phone"
      class="form-input mt-2 
          w-full py-2 px-3 h-10 bg-transparent 
          dark:bg-slate-900 dark:text-slate-200 
          rounded outline-none border border-gray-100 
          dark:border-gray-800 focus:ring-0 address_input phone_input"
      type="text"
      name="phone"
      autocomplete=""
      v-model="phone"
      required
      :minlength=$store.state.country_length
      :maxlength=$store.state.country_length
    />
    <span class="flag_account">+974</span>
    <label v-if="this.$store.state.auth!=0"label class="accept_account_email">

<input type="checkbox" class="account_email_check" @change="funcreate_account_email" id="account_email"/>
<span>{{ $t('create account') }}</span>
</label>
</div>
<div  v-if="show_password" class="col-lg-6">

    <h4>{{ $t("password") }} <i style="color: red">*</i></h4>
    <input
      id="password"
      class="form-input mt-2 
          w-full py-2 px-3 h-10 bg-transparent 
          dark:bg-slate-900 dark:text-slate-200 
          rounded outline-none border border-gray-100 
          dark:border-gray-800 focus:ring-0 address_input"
      type="password"
      name="name"
      autocomplete="new-password"
      v-model="password"
      required
      minlength="8"
    />
 
</div>

<!--editttttt check if match-->
<div  v-if="show_password" class="d-flex flex-row justify-content-start account_button">
  <button  @click="create_account" type="submit" class="button">
    {{ $t("CreateAccount") }}
  </button>   <div
    v-if="errorMessage != ''"
    style="
      text-align: center;
      padding: 5px;
      background-color: #fff;
      display: inline;
      margin: 0 10px;
    "
  >
    <span style="margin: 0; color: red; display: inline">{{
      errorMessage
    }}</span>
  </div>
</div>

</div>

<div class="mt-1">

  <h4 id="delivery" style="margin-bottom: 30px" >{{ $t("DeliveryMethod") }}</h4>
  <div class="row">
    <div v-if="DBitems"
      class="col-md-6 mb-4"
      v-for="(item, index) in this.DBitems.delivery_type.types"
      :key="index"
    >
      <button
        :class="item.isActive ? 'card1 active' : ' card1'"
        @click="selectDeliveryTypeId(item.id)"
      >
        <div class="row">
          <div class="col-3">
            <img
              style="max-width: 100%; object-fit: contain"
              :src="item.icon"
            />
          </div>
          <div class="col-9 mt-3">
            <h6>{{ item.type }}</h6>
            <p>{{ item.description }}</p>
          </div>
        </div>
      </button>
    </div>
  </div>


  <div v-if="showAddress" class="mt-1">

<h4 style="margin-bottom: 30px">{{ $t('selectAddress') }}:</h4>
<!--  -->

<div class="row">
<div class="col-lg-6">


<div class="mb-4">
<label class="font-semibold" for="city">
{{ $t('city') }} *:</label>

<v-select v-model="city" :reduce="(option) => option.id" :options="cities" label="city"
:placeholder="$t('city')" class="form-select form-input mt-2 
          w-full py-2 px-3 h-10 bg-transparent 
          dark:bg-slate-900 dark:text-slate-200 
          rounded outline-none border border-gray-100 
          dark:border-gray-800 focus:ring-0 address_input " id="city">
</v-select>

</div>
</div>

<div class="col-lg-6">

<div class="mb-4">
<label class="font-semibold" for="region">
{{ $t('region') }} *:</label>

<v-select v-model="region" :reduce="(option) => option.id" :options="regions" label="city"
:placeholder="$t('region')" class="form-select form-input mt-2 
          w-full py-2 px-3 h-10 bg-transparent 
          dark:bg-slate-900 dark:text-slate-200 
          rounded outline-none border border-gray-100 
          dark:border-gray-800 focus:ring-0 address_input" id="region">
</v-select>

</div>
</div>
</div>

<div class="row">
<div class="col-lg-6">
<div class="mb-4">
<label class="font-semibold" for="street">
{{ $t('street') }} :</label>
<input class="mt-3 w-full py-2
               px-3 h-10 bg-transparent 
               dark:bg-slate-900 dark:text-slate-200 
               rounded outline-none border border-gray-100 
               dark:border-gray-800 focus:ring-0 address_input" autocomplete="false" required
type="text" :placeholder="$t('street')" maxlength="255" id="street" v-model="street">
</div>
</div>
<div class="col-lg-6">
<div class="mb-4">
<label class="font-semibold" for="building">
{{ $t('building') }} :</label>
<input class="mt-3 w-full py-2
               px-3 h-10 bg-transparent 
               dark:bg-slate-900 dark:text-slate-200 
               rounded outline-none border border-gray-100 
               dark:border-gray-800 focus:ring-0 address_input" name="name" autocomplete="false"
type="text" :placeholder="$t('building')" maxlength="255" v-model="building" id="building">
</div>
</div>
</div>
<div class="mb-4">
<label class="font-semibold" for="detailes">
{{ $t('OtherDetails') }} :</label>
<textarea v-model="other_details" class="mt-3 w-full py-2
               px-3  bg-transparent 
               dark:bg-slate-900 dark:text-slate-200 
               rounded outline-none border border-gray-100 
               dark:border-gray-800 focus:ring-0 address_input" id="detailes">
      </textarea>
</div>
<!--  -->


</div>
</div>

<div class="mt-3">
  <h4 id="payment" style="margin-bottom: 30px">{{ $t("PaymentMethod") }}</h4>
  <div class="row">
    <div
      class="col-md-6 mb-4"
      v-for="(item, index) in this.DBitems.payment_types.types"
      :key="index"
    >
      <button
        :class="item.isActive ? 'card1 active' : ' card1'"
        @click="selectPaymentTypeId(item.id)"
      >
        <div class="row">
          <div class="col-3">
            <img
              style="max-width: 100%; object-fit: contain"
              :src="item.icon"
            />
          </div>
          <div class="col-9 mt-3">
            <h6>{{ item.type }}</h6>
            <p>{{ item.description }}</p>
          </div>
        </div>
      </button>
    </div>
  
    
  </div>
</div>

</div>

</div> 
      </div>
      <div v-if="!loadingMethods && this.DBitems != null&&show_guest">
        <div class="d-flex flex-row justify-content-end mt-4">
          <button class="button" id="confirm_btn" @click="confirmOrder()">
            {{ $t("confirmOrder") }}
          </button>
        </div>


      </div>
    </div>
  </section>
</template>

<script>
import card5 from "../components/CardStyle5.vue";
import AddAddress from "../components/AddAddress.vue";
import Vodal from "vodal";

import "vodal/common.css";
import "vodal/slide-down.css";
import "vodal/flip.css";

export default {
  components: {
    Vodal,
    card5,
    AddAddress,
  },
  data: function () {
    return {
      errorMessage:null,
      loading: true,
      user_name: null,
      phone: null,
      email: null,
      items: [],
      password:null,
      products: [],
      products_cost: [],
      loadingMethods: false,
      addresses: [],
      addAddress: false,
      showAddress: false,
      showMonthes: false,
      DBitems: null,
      discount_delivery_free: 0,
      selectPaymentType: null,
      selectDeliveryType: null,
      selectAddress: null,
      count_month: null,
      disCode: null,
      discount_id: null,
      PartialPrice: 0,
      discountPrice: 0,
      deliveryPrice: 0,
      totalPrice: 0,
      couponMsg: "",
      unit: this.$store.state.unit,
      allOrderNote: "",
      langChange: "",
      cities: [],
      regions: [],
      city: null,
      region: null,
      street: null,
      building: null,
      street: null,
      other_details: null,
      show_guest: false,
      show_password:false,
    };
  },
  created() {
    this.$store.state.topbar = 'topbar_light';
    this.$store.state.navbar = 'navbar_dark';
    if (this.$i18n.locale == 'ar') {
      this.unit = this.$store.state.unitAR;
    }
    else {
      this.unit = this.$store.state.unit;
    }

    //
    let AllCookies = this.$cookies.keys();
    this.items = [];
    this.products = [];
    this.products_cost = [];
    for (let i = 0; i < AllCookies.length; i++) {
      if (AllCookies[i].split("/")[0] == this.$store.storeName) {
        console.log('$$$$$$ ')

        this.items.push(AllCookies[i]);
        this.products.push(this.$cookies.get(AllCookies[i]));
        ////PartialPrice
        if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
          this.PartialPrice += this.$cookies.get(AllCookies[i]).offer_price;

          console.log('$$$$$$ offer price ', i, '  ', this.PartialPrice)
        } else {

          this.PartialPrice += this.$cookies.get(AllCookies[i]).total_price;
          console.log('$$$$$$  total_price ', i, ' ', this.PartialPrice)
        }
        // console.log('products',pluck(this.products,"product_id"));
      }
    }
    for (let i = 0; i < this.products.length; i++) {
      this.products_cost.push(this.products[i]['product_id']);
    }
    console.log('product_cost: ', this.products_cost);


    this.loadingMethods = true;
    if(this.products_cost.length!=0){ 
      this.axios
      .post(
        this.$store.state.base_api_url +
        this.$store.storeName +
        "/order/create/v2",
        {
          products: this.products_cost,
        },
        {

          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization:
              "Bearer " + this.$localStorage.get(this.$store.storeName),
          },
        }
      )
      .then((response) => {
        this.DBitems = response.data.data;
      })
      .then((this.loadingMethods = false))
      .catch((error) => {
        if (error.response.status == "401") {

        }
      });

    }

    this.axios
      .get(
        this.$store.state.base_api_url +
        this.$store.storeName +
        "/guest/addresses/create",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          },
        }
      )
      .then((response) => {
        this.cities = response.data.data.cities;
      }).then();

  },
  watch: {
    langChange() {
      //  this.loading = false;
      if (this.$i18n.locale == 'ar') {
        this.unit = this.$store.state.unitAR;
      }
      else {
        this.unit = this.$store.state.unit;
      }
      //get profile

      //
      let AllCookies = this.$cookies.keys();
      this.items = [];
      this.products = [];
      this.products_cost = [];
      this.PartialPrice = 0;
      for (let i = 0; i < AllCookies.length; i++) {
        if (AllCookies[i].split("/")[0] == this.$store.storeName) {
          this.items.push(AllCookies[i]);
          this.products.push(this.$cookies.get(AllCookies[i]));
          ////PartialPrice
          if (this.$cookies.get(AllCookies[i]).offer_price != 0) {
            this.PartialPrice += this.$cookies.get(AllCookies[i]).offer_price;
          } else {
            this.PartialPrice += this.$cookies.get(AllCookies[i]).total_price;
          }
          // console.log('products',pluck(this.products,"product_id"));
        }
      }

      for (let i = 0; i < this.products.length; i++) {
        this.products_cost.push(this.products[i]['product_id']);
      }
      console.log('product_cost: ', this.products_cost);


      ///////////methods
      this.loadingMethods = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/order/create/v2",
          { products: this.product_cost },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data;
          // console.log(this.addresses);
        })
        .then((this.loadingMethods = false))
        .catch((error) => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href = "/";
          }
        });


      this.axios
        .get(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/guest/addresses/create",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.cities = response.data.data.cities;
        }).then(this.loading = false);
    }
    ,
    PartialPrice() {
      //$store.state.tax
      //console.log("this.$store.state.tax "+ this.$store.state.tax + "  " +this.$store.state.tax+  this.PartialPrice)
      let x =
        this.PartialPrice -
        (this.discountPrice * this.PartialPrice) / 100 +
        this.deliveryPrice;
      this.totalPrice = x + (this.$store.state.tax * x) / 100;
      /*  let x= this.PartialPrice -( (this.discountPrice *  this.PartialPrice) / 100 );
            this.totalPrice = x + ( (this.$store.state.tax *  x) / 100 ) +this.deliveryPrice;*/
      /* this.totalPrice =
               this.PartialPrice -( (this.discountPrice *  this.PartialPrice) / 100 )+ this.deliveryPrice;
             console.log("PartialPrice Change" + this.totalPrice);*/
    },
    discountPrice() {
      let x =
        this.PartialPrice -
        (this.discountPrice * this.PartialPrice) / 100 +
        this.deliveryPrice;
      this.totalPrice = x + (this.$store.state.tax * x) / 100;
      /* this.totalPrice =
             this.PartialPrice -( (this.discountPrice *  this.PartialPrice) / 100 )+ this.deliveryPrice;
             console.log("discountPrice Change" + this.totalPrice);*/
    },
    deliveryPrice() {
      let x =
        this.PartialPrice -
        (this.discountPrice * this.PartialPrice) / 100 +
        this.deliveryPrice;
      this.totalPrice = x + (this.$store.state.tax * x) / 100;
      /*  this.totalPrice =
              this.PartialPrice -( (this.discountPrice *  this.PartialPrice) / 100 )+ this.deliveryPrice;
              console.log("deliveryPrice Change" + this.totalPrice);*/
    },
    selectDeliveryType() {
      if (this.selectDeliveryType == 5) {
        this.showAddress = true;
        //console.log("this.showAddress" + this.showAddress);
      } else {
        this.showAddress = false;
      }
    },
    selectPaymentType() {
      if (this.selectPaymentType == 4) {
        this.showMonthes = true;
      } else {
        this.showMonthes = false;
        this.count_month = null;
      }
      //console.log( this.count_month)
    },
    city(newVal) {
      this.axios
        .get(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/guest/addresses/regions/" +
          this.city,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.regions = response.data.data.regions;
          if (this.regions.find((o) => o.id == this.region) == null) {
            this.region = null;
          }
        });
    },

    region(newVal) {
      this.axios
        .post(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/guest/cost_delivery",
          {
            region_id: newVal,
            delivery_id: 5,
            cost: this.PartialPrice,
            products: this.products_cost,
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          if (response.data.code == "1") {
            if (this.discount_delivery_free == 0) {
              // console.log(this.discount_delivery_free);
              this.deliveryPrice = response.data.data.cost;
            }
            this.selectDeliveryType = 5// id;
            //console.log(this.selectDeliveryType);
            for (
              let el = 0;
              el < this.DBitems.delivery_type.types.length;
              el++
            ) {
              this.DBitems.delivery_type.types[el].isActive = false;
              if (this.DBitems.delivery_type.types[el].id == 5) {
                this.DBitems.delivery_type.types[el].isActive = true;
              }
              //console.log(this.DBitems.delivery_type.types[el].isActive);
            }
            // this.discountPrice = response.data.data.discount;
          } else {
            alert(response.data.message);
            this.selectAddress = null;

            // const $select = document.querySelector("#addressSelect");
            //$select.value = "SelectOne";
          }
          //this.addresses = response.data.data.addresses.addresses;
          // console.log(this.addresses);
        })
        .then(() => {
          this.loadingMethods = false;
        })
        .catch((error) => {
          if (error.response.status == "401") {
            //  this.$localStorage.remove(this.$store.storeName);
            // window.location.href =
            //   "/";
          }
        });
    },

  },
  methods: {
    funshow_guest() {
      if (this.show_guest == false)
        this.show_guest = true;
      else
        this.show_guest = false;

    },
    close_address() {
      this.$store.state.addAddress = false;
    },
    open_address() {
      this.$store.state.addAddress = true;
    },
    updatAddress() {
      ///////////addresses
      this.loading = true;
      this.axios
        .get(
          this.$store.state.base_api_url + this.$store.storeName + "/addresses",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.addresses = response.data.data.addresses.addresses;
          /*if (this.addresses.length != 0) {
            this.selectAddress = this.addresses[0].id;
          }*/
          // console.log(this.addresses);
        })
        .then((this.loading = false))
        .catch((error) => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href =
              "/";
          }
        });
    },
    updatedata2() {
      console.log('jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj')
      this.langChange_check = false;
      this.langChange = false;
      ///////////methods
      this.loadingMethods = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/order/create/v2",
          { products: this.product_cost },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.DBitems = response.data.data;
          // console.log(this.addresses);
        })
        .then((this.loadingMethods = false))
        .catch((error) => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href = "/";
          }
        });


      this.axios
        .get(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/guest/addresses/create",
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.cities = response.data.data.cities;
        }).then(this.loading = false);

    },
    Couponcode() {
      this.loading = true;
      this.axios
        .post(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/guest/use_coupon",
          {
            code: this.disCode,
            total_price: this.PartialPrice,
          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization:
                "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          if (response.data.code == "1") {
            this.couponMsg = "";
            this.discountPrice = response.data.data.discount;
            this.discount_id = response.data.data.discount_id;
            this.discount_delivery_free = response.data.data.isdelivery_free;
            if (this.discount_delivery_free == 1)
              this.deliveryPrice = 0;
            /////////for select
            // this.selectAddress = null;
            // this.deliveryPrice = 0;
            ////////////
          } else {
            this.couponMsg = response.data.message;
            /////////for select
            // this.selectAddress = null;
            // this.deliveryPrice = 0;
            ////////////
          }
          //this.addresses = response.data.data.addresses.addresses;
          // console.log(this.addresses);
        })
        .then((this.loading = false))
        .catch((error) => {
          if (error.response.status == "401") {
            this.$localStorage.remove(this.$store.storeName);
            window.location.href =
              "/";
          }
        });
    },
    selectPaymentTypeId(id) {
      document.getElementById('payment').style = "color:black";
      this.loadingMethods = true;
      this.selectPaymentType = id;
      //console.log(this.selectPaymentType);

      for (let el = 0; el < this.DBitems.payment_types.types.length; el++) {
        this.DBitems.payment_types.types[el].isActive = false;
        if (this.DBitems.payment_types.types[el].id == id) {
          this.DBitems.payment_types.types[el].isActive = true;
        }
        //console.log(this.DBitems.payment_types.types[el].isActive);
      }

      //setTimeout(() => {
      this.loadingMethods = false;
      //  }, 3000);
    },
    selectDeliveryTypeId(id) {
      //  this.loadingMethods = true;
      console.log("bbbbbbbbbbbbbbbbbbb");
      this.selectDeliveryType = id;
      document.getElementById('delivery').style = "color:black";
      for (
              let el = 0;
              el < this.DBitems.delivery_type.types.length;
              el++
            ) {
              this.DBitems.delivery_type.types[el].isActive = false;
              if (this.DBitems.delivery_type.types[el].id == id) {
                this.DBitems.delivery_type.types[el].isActive = true;
              }
              //console.log(this.DBitems.delivery_type.types[el].isActive);
            }
    },

    onChange: function (event) {
      this.selectAddress = event.target.value;
      this.selectDeliveryTypeId(5);
    },
    onChangeMonths: function (event) {
      //console.log( event.target.value)
      this.count_month = event.target.value;
    },
    confirmOrder() {
      this.change_state();
      document.getElementById("confirm_btn").disabled = true;
      var count_error = 0;
      var error_msg = "";

      if (this.selectPaymentType == null) {
        count_error++;
        document.getElementById('payment').style = 'color:red'

        if (this.$i18n.locale == "ar") {
          error_msg += (" يجب تحديد طريقة الدفع");
        } else {
          error_msg += ("Please select a payment type ");
        }
      }

      if (this.user_name == null) {
        count_error++;
        document.getElementById('name').style = 'border:2px solid red !important'

        if (this.$i18n.locale == "ar") {
          error_msg += (" يجب ادخال الاسم");
        } else {
          error_msg += (" Please Enter Name");
        }
      }

      if (this.selectDeliveryType == null) {
        document.getElementById('delivery').style = 'color:red'

        count_error++;
        if (this.$i18n.locale == "ar") {
          error_msg += (" يجب تحديد طريقة التوصيل");
        } else {
          error_msg += (" Please select a delivery type");
        }
      }

      if (this.selectDeliveryType == 5 && this.region == null) {
        document.getElementById('region').style = 'border:2px solid red !important'

        count_error++;
        if (this.$i18n.locale == "ar") {
          error_msg += ("يجب اختيار المنطقة ");
        } else {
          error_msg += (" Please Select A Region");
        }
      }

   

      if (this.phone == null) {
        count_error++;
        if (this.$i18n.locale == "ar") {
          error_msg += (" يجب ادخال الهاتف");
        } else {
          error_msg += (" Please Enter Phone");
        }
        document.getElementById('phone').style = 'border:2px solid red !important'
      }
      if (count_error == 0) {
        this.loading = true;
        this.axios
          .post(
            this.$store.state.base_api_url +
            this.$store.storeName +
            "/order/store/v2",
            {

              delivery_type_id: this.selectDeliveryType,
              payment_type_id: this.selectPaymentType,
              total_price: this.totalPrice,
              discount_id: this.discount_id,
              products: this.products,
              part_price: this.PartialPrice,
              note: this.allOrderNote,
              count_month: this.count_month,
              device: 'web',
              user_name: this.user_name,
              phone: this.phone,
              email: this.email,
              region_id: this.region,
              street: this.street,
              building: this.building,
              other_details: this.other_details

            },
            {
              headers: {
                "Accept-Language": this.$i18n.locale,
                Authorization:
                  "Bearer " + this.$localStorage.get(this.$store.storeName),
              },
            }
          )
          .then((response) => {
            if (response.data.code == "1") {
              if (response.data.data.clear_cart == null || response.data.data.link_pay == null) {
                for (let i = 0; i < this.items.length; i++) {
                  this.$cookies.remove(this.items[i]);
                }
                this.items = [];

                console.log("hiiiiiiii" + this.$cookies.keys());
                this.$store.state.cartCounter = 0;
              }

              //console.log("hiiiiiiii" + this.$cookies.keys());

              if (response.data.data.link_pay == null) {
                this.$store.state.cartCounter = 0;
                this.$router.push({
                  name: "order_done",
                  params: {
                    storeName: this.$store.storeName,
                    lang: this.$i18n.locale,
                    id: response.data.data.order_id
                  },
                });
              }

              if (response.data.data.link_pay != null) {
                window.location.href = response.data.data.link_pay;
              }

              //this.deliveryPrice = response.data.data.cost;
              // this.discountPrice = response.data.data.discount;
            } else {
              alert(response.data.message);
              document.getElementById("confirm_btn").disabled = false;
              var count_error = 0;
              var error_msg = "";
              // this.couponMsg = response.data.message;
            }
            //this.addresses = response.data.data.addresses.addresses;
            // console.log(this.addresses);
          })
          .then((this.loading = false))
          .catch((error) => {
            if (error.response.status == "401") {
              this.$localStorage.remove(this.$store.storeName);
              // window.location.href =
              //   "/"+this.$i18n.locale ;
            }
          });
      }
      else {
        alert(error_msg);
        document.getElementById("confirm_btn").disabled = false;

      }


      //////////

      //////////

    },
    change_state() {
      document.getElementById('payment').style = "color:black";
      document.getElementById('delivery').style = "color:black";
      if (document.getElementById('region'))
        document.getElementById('region').style = "border: 0px solid var(--primary-color) !important;";
      document.getElementById('phone').style = "border: 0px solid var(--primary-color) !important;";
      document.getElementById('name').style = "border: 0px solid var(--primary-color) !important;";

    },
    funcreate_account_email(){
console.log('11111111111')

      if(document.getElementById('account_email').checked==true)
      this.show_password=true;
    else
    this.show_password=false;
    },

    create_account(){

      var count_error = 0;
      var error_msg = "";
      document.getElementById('phone').style = "border: 0px solid #dde2e6 !important;";
      document.getElementById('name').style = "border: 0px solid #dde2e6 !important;";
      document.getElementById('email').style = "border: 0px solid #dde2e6 !important;";
      document.getElementById('password').style = "border: 0px solid #dde2e6 !important;";

      if (this.phone == null) {
        count_error++;
        document.getElementById('phone').style = 'border:2px solid red !important'


        if (this.$i18n.locale == "ar") {
          error_msg += (" يجب إدخال الرقم");
        } else {
          error_msg += ("Please Add Phone ");
        }
      }


      if (this.user_name == null) {
        count_error++;
        document.getElementById('name').style = 'border:2px solid red !important'


        if (this.$i18n.locale == "ar") {
          error_msg += (" يجب إدخال الاسم");
        } else {
          error_msg += ("Please Add User Name ");
        }
      }

      if (this.password == null) {
        count_error++;
        document.getElementById('password').style = 'border:2px solid red !important'


        if (this.$i18n.locale == "ar") {
          error_msg += (" يجب إدخال كلمة السر");
        } else {
          error_msg += ("Please Enter Password ");
        }
      }

      if (this.email == null) {
        count_error++;
        document.getElementById('email').style = 'border:2px solid red !important'


        if (this.$i18n.locale == "ar") {
          error_msg += (" يجب إدخال الايميل");
        } else {
          error_msg += ("Please Add Email");
        }
      }
      if (count_error == 0) {
         this.loading=true;        
          console.log(this.phone)
         
          this.axios
            .post(
              this.$store.state.base_api_url +
                this.$store.storeName +
                "/register",
                {
                phone:this.$store.state.country_code+this.phone,
                name:this.user_name,
                email:this.email,
                password:this.password
              
              },

              {
                
                headers: {
                  "Accept-Language": this.$i18n.locale,
                },
              }
            )
            .then((response) => {
              this.loading=false;
             
              if (response.data.code == "1") {
                
                this.$localStorage.set(
                  this.$store.storeName,
                  response.data.data.token
                );
                this.$localStorage.set(
              this.$store.user_name,
              response.data.data.name
            );
            if(!response.data.data.name||response.data.data.name=='')
             {
              this.$localStorage.set(
              this.$store.user_name,
            );
             }         
                console.log(
                  this.$localStorage.get(this.$store.storeName) +
                    " id : " +
                    this.$store.storeName
                );

                console.log(
                  "user token : " +
                    this.$localStorage.get(this.$store.storeName)
                );
               
                window.location.href=  '/checkout' ;
                this.loading=false;
              } else if (response.data.code == "-1") {
                this.loading=false;
                this.errorMessage = response.data.message;
                setTimeout(() => {
                  this.errorMessage = "";
                  this.count_error=0;
                }, 3000);
              }
              if (response.data.error) {
                this.errorMessage = "response.data.error";
                setTimeout(() => {
                  this.errorMessage = "";
                }, 3000);
              }
             
            });
        } 
        else{ 
          alert(error_msg);
            setTimeout(() => {

var count_error = 0;
var error_msg = "";
document.getElementById('phone').style = "border: 0px solid #dde2e6 !important;";
document.getElementById('name').style = "border: 0px solid #dde2e6 !important;";
document.getElementById('email').style = "border: 0px solid #dde2e6 !important;";
document.getElementById('password').style = "border: 0px solid #dde2e6 !important;";
}, 8000);}
        

     
    
    }
  },
};

</script>

<style scoped>
/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/

.flag {
    position: relative;
    left: 5%;
    left: auto;
    float: right;
}

[dir="rtl"] .flag {
    position: relative;
    right: 5%;
    left: auto;
    float: left;
} 

#Second {
  margin-top: 120px;
  min-height: 600px;
}

.addAddress {
  /*margin-top: 10px;*/
  border: 2px solid #000;
  /*border: 2px solid transparent;*/
  background-color: #fff;
  border-radius: 10px;
  font-size: 16px;
  font-family: "Araboto-Bold";
  padding: 10px;
  height: 50px;
  width: 100%;
  /* box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);*/
}

.addAddress {
  border: 2px solid var(--primary-color);
  background-color: var(--primary-color);
  color: #fff;
}

.addAddress:hover {
  transform: scale(1.05);
}

h4 {
  font-size: 16px;
  font-family: "Araboto-Bold";
  color: #000;
}

h5 {
  font-size: 16px;
  font-family: "Araboto-Bold";
  color: #000;
  margin-bottom: 10%;
}

.button {
  background-color: var(--primary-color);
  border-radius: 10px;
  width: 20%;
  text-align: center;
  padding: 10px 0 15px;
  border: 2px solid var(--primary-color);
  color: #fff;
  font-size: 16px;
  font-family: "Araboto-Medium";
}

.button:hover {
  background-color: #fff;
  color: #000;
}

.code-input {
  text-align: center;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: 2px solid #000;
  display: inline;
}

[dir="rtl"] .bi-check-circle-fill {
  position: relative;
  left: 18%;
}

[dir="ltr"] .bi-check-circle-fill {
  position: relative;
  right: 18%;
}

.activate {
  text-align: center;
  height: 50px;
  width: 100%;
  border-radius: 10px;
  border: 2px solid #000;
}

.activate:hover {
  border: 2px solid #24b300;
  background-color: #24b300;
}

#Second select {
  border: 2px solid #000;
  /*border: 0;*/
  background-color: #fff;
  /*box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);*/
  border-radius: 10px;
  font-size: 16px;
  font-family: "Araboto-Bold";
  color: var(--secondary-color);
  text-align: center;
  width: 100%;
  height: 50px;
}

.card1 {
  border: 2px solid transparent;
  background-color: #fff;
  box-shadow: 0px 0px 15px rgba(127, 137, 161, 0.25);
  border-radius: 10px;
  padding: 10px;

  width: 100%;
  height: 100px;
}

.card1:hover,
.card1.active {
  border: 2px solid var(--primary-color);
  background: linear-gradient(#fff, var(--primary-color) 200%);
  /*box-shadow: none;*/
}

.card1 h6 {
  font-size: 13px;
  font-family: "Araboto-Bold";
  color: #000;
  text-align: start;
}

.card1 p {
  font-size: 11px;
  font-family: "Araboto-Medium";
  color: var(--secondary-color);
  text-align: start;
}

.card2 {
  border: 2px solid transparent;
  background-color: #fff;
  box-shadow: 0px 0px 15px rgba(127, 137, 161, 0.25);
  border-radius: 10px;
  padding: 10px;
}

/*@media (min-width: 1500px) {
  .collg4 {
    width: 25%;
  }
}
@media (min-width: 2000px) {
  .collg4 {
    width: 20%;
  }
}*/
@media (min-width: 1200px) {
  .col-lg-8 {
    width: 60%;
    margin: 0 3%;
  }
}

@media (max-width: 572px) {
  h2 {
    font-size: 20px;
  }

  #Second select,
  h4,
  h5 {
    font-size: 13px;
  }

  .button {
    width: 40%;
  }

  .card1 {
    width: 94%;
    margin-left: 2%;
    margin-right: 2%;
  }

  .information {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .card2 h4 {
    font-size: 10px;
  }
}

@media (max-width: 305px) {
  h2 {
    font-size: 15px;
  }

  #Second select,
  h4,
  h5 {
    font-size: 11px;
  }

  .card1 h6 {
    font-size: 10px;
  }

  .card1 img {
    width: 50px;
  }
}
</style>
