<template>
  <div >
  
    <div  class="card add_address">
      <div style="
          margin-top: 30px;
          margin-bottom: 20px;
          font-size: 18px;
        ">
        <h4 v-if="this.$store.state.apperance_map_address == 1" style="color: var(--primary-color)">
          <img src="assets/image/icons/location.svg" />
          {{ $t("determineLocation") }}
        </h4>
      </div class="div_address">
      <div v-if="this.$store.state.apperance_map_address == 1" class="map">
        <GmapMap :center="center" :zoom="18" map-style-id="roadmap" :options="mapOptions" class="mapStyle"
          style="width: 80%; height: 50vmin" ref="mapRef" @click="handleMapClick">
          <GmapMarker :position="marker.position" :clickable="true" :draggable="true" @drag="handleMarkerDrag"
            @click="panToMarker" />
        </GmapMap>
      </div>
      <div>
        <label class="lebal address_input">{{ $t('placeHolderTitle') }} *</label>
        <input v-model="title" class="form-control address_input" type="text" :placeholder="$t('placeHolderTitle')" required
          maxlength="255" minlength="3" id="title" />
        <div class="row">
          <div class="col-lg-6">
            <label class="lebal address_input">{{ $t('city') }} *</label>
            <v-select class="form-control address_input" v-model="city" :reduce="(option) => option.id" :options="cities" label="city"
              :placeholder="$t('city')" required></v-select>
          </div>
          <div class="col-lg-6">
            <label class="lebal address_input">{{ $t('region') }} *</label>
            <v-select class="form-control address_input" v-model="region" :reduce="(option) => option.id" :options="regions"
              label="city" :placeholder="$t('region')" required></v-select>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <label class="lebal address_input">{{ $t('street') }}</label>

            <input v-model="street" class="form-control address_input" type="text"  maxlength="255" />
          </div>
          <div class="col-lg-6">
            <label class="lebal address_input">{{ $t('building') }}</label>
            <input class="form-control address_input" type="text"  maxlength="255" v-model="building" />
          </div>
        </div>

        <label class="lebal address_input">{{ $t('OtherDetails') }}</label>
        <textarea v-model="other_details" rows="2" cols="3" class="form-control address_input" 
          style="margin-bottom: 10px"></textarea>

        <button class="button" @click="addAddress">{{ $t("AddAdderss") }}</button>

        
        <p v-if="Message" style="
          border-radius: 5px;
          border: 2px solid var(--primary-color);
          text-align: center;
          padding: 5px;
          background: linear-gradient(#fff, var(--primary-color) 200%);
        ">{{ Message }}</p>
      

      </div>
      
    </div>

    <div v-if="loading" class="d-flex flex-row justify-content-center loading_address" style="margin-top: 100px; margin-bottom: 200px">
      <HalfCircleSpinner :animation-duration="1000" :dot-size="35" :dots-num="3" color="var(--primary-color)" />
    </div>

  </div>
</template>


<script>
import { HalfCircleSpinner, HollowDotsSpinner } from "epic-spinners";

export default {
  components: { HollowDotsSpinner, HalfCircleSpinner },
  data: function () {
    return {
      loading: false,
      title: null,
      street: null,
      building: null,
      other_details: null,
      city: null,
      cities: [],
      region: null,
      regions: [],
      lat_map: 0,
      long_map: 0,
      Message: "",

      marker: { position: { lat: 300, lng: 300 } },
      center: { lat: 10, lng: 10 },
      mapOptions: {
        disableDefaultUI: true,
      },
    };
  },

  created() {
    // this.loading=true;
    console.log("hiiiiiiiiiiiiiii");
    if (this.$store.state.apperance_map_address == 1) {
      this.geolocate();
    }
    // this.city = this.address.address.city;
    // this.regions = this.address.regions;
    // this.region = this.address.address.region;
    this.title = null
    this.street = null
    this.building = null
    this.other_details = null

    this.axios
      .get(
        this.$store.state.base_api_url +
        this.$store.storeName +
        "/addresses/create",
        {
          headers: {
            "Accept-Language": this.$i18n.locale,
            Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
          },
        }
      )
      .then((response) => {
        this.cities = response.data.data.cities;
      }).then(this.loading = false);
  },
  methods: {
    addAddress() {
      this.loading = true;
      if (this.$store.state.apperance_map_address == 1) {
        this.lat_map = this.marker.position.lat;
        this.long_map = this.marker.position.lng;
      }
      if(!this.title||!this.region||!this.city)
     {
       this.Message = "الرجاء إدخال ;كافة المعلومات الإجبارية"
      this.loading=false;
      if(this.title.length<3){
        document.getElementById('title').style="border:2px solid red"
       
            
      }
      setTimeout(() => {
              this.Message = "";
              //this.$router.go(this.$router.currentRoute)
              //document.getElementById('title').style="border:2px solid #cccccc"
            }, 3000);
    }
      else{
      this.axios
        .post(
          this.$store.state.base_api_url + this.$store.storeName + "/addresses",
          {
            title: this.title,
            city_id: this.city,
            region_id: this.region,
            street: this.street,
            building: this.building,
            other_details: this.other_details,
            lat: this.lat_map,
            long: this.long_map,

          },
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          if (response.data.code == "1") {
            this.title = null
            this.street = null
            this.building = null
            this.other_details = null
            console.log(response.data.message);

            this.Message = response.data.message;
            try {
              this.$parent.$parent.updatAddress();
            } catch (Ex) {
              this.$parent.updatAddress();
            }
            this.loading = false
            setTimeout(() => {
              this.Message = "";
              //this.$router.go(this.$router.currentRoute)
              this.$store.state.addAddress = false
            }, 1000);
          } else {
            console.log(response.data.message);
            this.Message = response.data.message;
            this.loading = false
            setTimeout(() => {
              this.Message = "";
            }, 3000);
          }
        });
        }
    },
    //detects location from browser
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.marker.position = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.panToMarker();
      });
    },
    //sets the position of marker when dragged
    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },
    //Moves the map view port to marker
    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position);
      this.$refs.mapRef.setZoom(18);
    },
    //Moves the marker to click position on the map
    handleMapClick(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      console.log(e);
    },
  },
  mounted() {
  },
  watch: {
    city(newVal) {
      this.axios
        .get(
          this.$store.state.base_api_url +
          this.$store.storeName +
          "/addresses/regions/" +
          this.city,
          {
            headers: {
              "Accept-Language": this.$i18n.locale,
              Authorization: "Bearer " + this.$localStorage.get(this.$store.storeName),
            },
          }
        )
        .then((response) => {
          this.regions = response.data.data.regions;
          if (this.regions.find((o) => o.id == this.region) == null) {
            this.region = null;
          }
        });
    },
  },
};
</script>

<style scoped>
/*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/

#Second {
  margin-top: 120px;
}

.card {
  background-color: white;
  width: 100%;
  border-radius: 15px;
  color: var(--secondary-color);
  font-family: "Araboto-Bold";
  font-size: 16px;
  padding: 16px 10px;
  border: 0;
}

.card h4 {
  font-size: 15px;
  font-family: "Araboto-Medium";
  display: inline;
  color: var(--secondary-color);
}

.card h4:hover {
  color: var(--primary-color);
}

button {
  border: 0;
  background: transparent;
  padding: 0;
  margin: 0;
  color: var(--secondary-color);
}

hr {
  height: 2px;
  margin: 12px;
  color: #cccccc;
}

[dir="rtl"] button img {
  left: 15px;
  margin-top: 10px;
  position: absolute;
}

[dir="ltr"] button img {
  right: 15px;
  margin-top: 10px;
  position: absolute;
}

a {
  display: block;
  background-color: #f8f8f8;
  padding: 13px 0 17px 0;
  width: 80%;
  border-radius: 15px;
  color: var(--secondary-color);
  font-family: "Araboto-Bold";
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
}

.router-link-exact-active,
.router-link-exact-active:focus {
  background-color: #fff;
  border: 2px solid var(--primary-color);
}

.mapStyle {
  margin-bottom: 20px;
  border-radius: 15px;
  margin-left: 10%;
  margin-right: 10%;
  height: 315px;
  width: 80%;
}

.input-icons {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.input-icons input,
textarea.form-control {
  width: 100%;
  border-radius: 9px;
  background-color: #fff;
  margin-bottom: 10px;
  border: 2px solid #cccccc;
  font-size: 13px;
  font-family: "Araboto-Medium";
  color: var(--secondary-color);
  padding: 10px 20px 15px 20px;
}

.button {
  background-color: var(--primary-color);
  border-radius: 10px;
  width: 60%;

  margin: 20px 20%;
  padding: 10px 15px;
  border: 2px solid var(--primary-color);
  color: #fff;
  font-size: 15px;
  font-family: "Araboto-Medium";
}

.button:hover {
  background-color: #fff;
  color: #000;
}

@media (max-width: 992px) {
  a {
    width: 100%;
  }
}

@media (max-width: 772px) {
  a {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }
}
</style>